@import url(~react-day-picker/lib/style.css);
:export {
  primary: #244c5a;
  secondary: #b8c9e1;
  black: #343741;
  light: #e1e1e1;
  gray200: #ededed;
  gray300: #e1e1e1;
  gray400: #ced4da;
  gray900: #43434c;
  warmGrey: #a2a2a2;
  orange: #ff6900;
  green: #55c443;
  red: #d60808;
  white: #fff;
  slateGrey: #616167;
  slateBlue: #244c5a;
  softBlue: #b8c9e1;
  toastAnimationDuration: 350; }

:root {
  --primary: #244c5a;
  --secondary: #b8c9e1;
  --black: #343741;
  --slate-grey: #616167;
  --gray-200: #ededed;
  --gray-300: #e1e1e1;
  --gray-400: #ced4da;
  --gray-600: #616167;
  --gray-900: #43434c;
  --warm-grey: #a2a2a2;
  --warmer-grey: #969696;
  --orange: #ff6900;
  --green: #55c443;
  --red: #d60808;
  --white: #fff;
  --light: #e1e1e1;
  --slate-blue: #244c5a;
  --soft-blue: #b8c9e1; }

/**********************************************************
*****         Bootstrap variables override            *****
**********************************************************/
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d60808;
  --orange: #ff6900;
  --yellow: #ffc107;
  --green: #55c443;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #616167;
  --gray-dark: #343a40;
  --primary: #244c5a;
  --secondary: #b8c9e1;
  --success: #55c443;
  --info: #17a2b8;
  --warning: #ff6900;
  --danger: #d60808;
  --light: #e1e1e1;
  --dark: #43434c;
  --black: #343741;
  --light-grey: #ededed;
  --slate-grey: #616167;
  --slate-blue: #244c5a;
  --soft-blue: #b8c9e1;
  --warm-grey: #a2a2a2;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(52, 55, 65, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343741;
  text-align: left;
  background-color: #ededed; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.28125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #244c5a;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #244c5a;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #616167;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.28125rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.28125rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2.25rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 0.5625rem;
  margin-bottom: 0.5625rem;
  border: 0;
  border-top: 2px solid rgba(52, 55, 65, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 0.5625rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #616167; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ededed;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.28125rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #616167; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #43434c;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #43434c; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5625rem;
  margin-left: -0.5625rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 0.5625rem;
  color: #343741; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e1e1e1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e1e1e1; }
  .table tbody + tbody {
    border-top: 2px solid #e1e1e1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e1e1e1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(52, 55, 65, 0.05); }

.table-hover tbody tr:hover {
  color: #343741;
  background-color: rgba(52, 55, 65, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c2cdd1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8da2a9; }

.table-hover .table-primary:hover {
  background-color: #b3c1c6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b3c1c6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ebf0f7; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #dae3ef; }

.table-hover .table-secondary:hover {
  background-color: #d9e2f0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d9e2f0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cfeeca; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a7e09d; }

.table-hover .table-success:hover {
  background-color: #bee8b7; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bee8b7; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffd5b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffb17a; }

.table-hover .table-warning:hover {
  background-color: #ffc69f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffc69f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4baba; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ea7f7f; }

.table-hover .table-danger:hover {
  background-color: #f0a4a4; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f0a4a4; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f7f7f7; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #efefef; }

.table-hover .table-light:hover {
  background-color: #eaeaea; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaeaea; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cacacd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9d9da2; }

.table-hover .table-dark:hover {
  background-color: #bdbdc1; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbdc1; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #c6c7ca; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #95979c; }

.table-hover .table-black:hover {
  background-color: #b9babe; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #b9babe; }

.table-light-grey,
.table-light-grey > th,
.table-light-grey > td {
  background-color: #fafafa; }

.table-light-grey th,
.table-light-grey td,
.table-light-grey thead th,
.table-light-grey tbody + tbody {
  border-color: #f6f6f6; }

.table-hover .table-light-grey:hover {
  background-color: #ededed; }
  .table-hover .table-light-grey:hover > td,
  .table-hover .table-light-grey:hover > th {
    background-color: #ededed; }

.table-slate-grey,
.table-slate-grey > th,
.table-slate-grey > td {
  background-color: #d3d3d4; }

.table-slate-grey th,
.table-slate-grey td,
.table-slate-grey thead th,
.table-slate-grey tbody + tbody {
  border-color: #adadb0; }

.table-hover .table-slate-grey:hover {
  background-color: #c6c6c7; }
  .table-hover .table-slate-grey:hover > td,
  .table-hover .table-slate-grey:hover > th {
    background-color: #c6c6c7; }

.table-slate-blue,
.table-slate-blue > th,
.table-slate-blue > td {
  background-color: #c2cdd1; }

.table-slate-blue th,
.table-slate-blue td,
.table-slate-blue thead th,
.table-slate-blue tbody + tbody {
  border-color: #8da2a9; }

.table-hover .table-slate-blue:hover {
  background-color: #b3c1c6; }
  .table-hover .table-slate-blue:hover > td,
  .table-hover .table-slate-blue:hover > th {
    background-color: #b3c1c6; }

.table-soft-blue,
.table-soft-blue > th,
.table-soft-blue > td {
  background-color: #ebf0f7; }

.table-soft-blue th,
.table-soft-blue td,
.table-soft-blue thead th,
.table-soft-blue tbody + tbody {
  border-color: #dae3ef; }

.table-hover .table-soft-blue:hover {
  background-color: #d9e2f0; }
  .table-hover .table-soft-blue:hover > td,
  .table-hover .table-soft-blue:hover > th {
    background-color: #d9e2f0; }

.table-warm-grey,
.table-warm-grey > th,
.table-warm-grey > td {
  background-color: #e5e5e5; }

.table-warm-grey th,
.table-warm-grey td,
.table-warm-grey thead th,
.table-warm-grey tbody + tbody {
  border-color: #cfcfcf; }

.table-hover .table-warm-grey:hover {
  background-color: #d8d8d8; }
  .table-hover .table-warm-grey:hover > td,
  .table-hover .table-warm-grey:hover > th {
    background-color: #d8d8d8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(52, 55, 65, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(41, 43, 51, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(41, 43, 51, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #ededed;
  border-color: #e1e1e1; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(3.5em + 0.75rem + 2px);
  padding: 0.375rem 1.125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 3.5;
  color: #495057;
  background-color: #ededed;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ededed;
    border-color: #4899b5;
    outline: 0;
    box-shadow: inset 0 0 0 1px #244c5a; }
  .form-control::placeholder {
    color: #616167;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #ced4da;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ededed; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 3.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 3.5;
  color: #343741;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 2.25rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #616167; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #55c443; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(85, 196, 67, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #55c443;
  padding-right: calc(3.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2355c443' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.875em + 0.1875rem) center;
  background-size: calc(1.75em + 0.375rem) calc(1.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #55c443;
    box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(3.5em + 0.75rem);
  background-position: top calc(0.875em + 0.1875rem) right calc(0.875em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #55c443;
  padding-right: calc(0.75em + 2.6875rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.125rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2355c443' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ededed no-repeat center right 2.125rem/calc(1.75em + 0.375rem) calc(1.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #55c443;
    box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #55c443; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #55c443; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #55c443; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #78d06a;
  background-color: #78d06a; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #55c443; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #55c443; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #55c443;
  box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d60808; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(214, 8, 8, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d60808;
  padding-right: calc(3.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d60808' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d60808' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.875em + 0.1875rem) center;
  background-size: calc(1.75em + 0.375rem) calc(1.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d60808;
    box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(3.5em + 0.75rem);
  background-position: top calc(0.875em + 0.1875rem) right calc(0.875em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d60808;
  padding-right: calc(0.75em + 2.6875rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.125rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d60808' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d60808' stroke='none'/%3e%3c/svg%3e") #ededed no-repeat center right 2.125rem/calc(1.75em + 0.375rem) calc(1.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d60808;
    box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d60808; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d60808; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d60808; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f61b1b;
  background-color: #f61b1b; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d60808; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d60808; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d60808;
  box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #343741;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.125rem;
  font-size: 1rem;
  line-height: 3.25;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #343741;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #244c5a;
  border-color: #244c5a; }
  .btn-primary:hover {
    color: #fff;
    background-color: #19353f;
    border-color: #152d36; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #19353f;
    border-color: #152d36;
    box-shadow: 0 0 0 1px rgba(69, 103, 115, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #152d36;
    border-color: #12262c; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(69, 103, 115, 0.5); }

.btn-secondary {
  color: #43434c;
  background-color: #b8c9e1;
  border-color: #b8c9e1; }
  .btn-secondary:hover {
    color: #43434c;
    background-color: #9db5d6;
    border-color: #94aed2; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #43434c;
    background-color: #9db5d6;
    border-color: #94aed2;
    box-shadow: 0 0 0 1px rgba(166, 181, 203, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #43434c;
    background-color: #b8c9e1;
    border-color: #b8c9e1; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #43434c;
    background-color: #94aed2;
    border-color: #8ba7ce; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(166, 181, 203, 0.5); }

.btn-success {
  color: #fff;
  background-color: #55c443;
  border-color: #55c443; }
  .btn-success:hover {
    color: #fff;
    background-color: #46ab36;
    border-color: #42a133; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #46ab36;
    border-color: #42a133;
    box-shadow: 0 0 0 1px rgba(111, 205, 95, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #55c443;
    border-color: #55c443; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #42a133;
    border-color: #3e9830; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(111, 205, 95, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 1px rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #ff6900;
  border-color: #ff6900; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d95900;
    border-color: #cc5400; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #d95900;
    border-color: #cc5400;
    box-shadow: 0 0 0 1px rgba(255, 128, 38, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #ff6900;
    border-color: #ff6900; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc5400;
    border-color: #bf4f00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(255, 128, 38, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d60808;
  border-color: #d60808; }
  .btn-danger:hover {
    color: #fff;
    background-color: #b10707;
    border-color: #a50606; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #b10707;
    border-color: #a50606;
    box-shadow: 0 0 0 1px rgba(220, 45, 45, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d60808;
    border-color: #d60808; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #a50606;
    border-color: #990606; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(220, 45, 45, 0.5); }

.btn-light {
  color: #43434c;
  background-color: #e1e1e1;
  border-color: #e1e1e1; }
  .btn-light:hover {
    color: #43434c;
    background-color: #cecece;
    border-color: #c8c8c8; }
  .btn-light:focus, .btn-light.focus {
    color: #43434c;
    background-color: #cecece;
    border-color: #c8c8c8;
    box-shadow: 0 0 0 1px rgba(201, 201, 203, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #43434c;
    background-color: #e1e1e1;
    border-color: #e1e1e1; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #43434c;
    background-color: #c8c8c8;
    border-color: #c1c1c1; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(201, 201, 203, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #43434c;
  border-color: #43434c; }
  .btn-dark:hover {
    color: #fff;
    background-color: #313138;
    border-color: #2b2b31; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #313138;
    border-color: #2b2b31;
    box-shadow: 0 0 0 1px rgba(95, 95, 103, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #43434c;
    border-color: #43434c; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2b2b31;
    border-color: #25252a; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(95, 95, 103, 0.5); }

.btn-black {
  color: #fff;
  background-color: #343741;
  border-color: #343741; }
  .btn-black:hover {
    color: #fff;
    background-color: #23252c;
    border-color: #1d1f25; }
  .btn-black:focus, .btn-black.focus {
    color: #fff;
    background-color: #23252c;
    border-color: #1d1f25;
    box-shadow: 0 0 0 1px rgba(82, 85, 94, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #343741;
    border-color: #343741; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: #1d1f25;
    border-color: #18191e; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(82, 85, 94, 0.5); }

.btn-light-grey {
  color: #43434c;
  background-color: #ededed;
  border-color: #ededed; }
  .btn-light-grey:hover {
    color: #43434c;
    background-color: #dadada;
    border-color: #d4d4d4; }
  .btn-light-grey:focus, .btn-light-grey.focus {
    color: #43434c;
    background-color: #dadada;
    border-color: #d4d4d4;
    box-shadow: 0 0 0 1px rgba(212, 212, 213, 0.5); }
  .btn-light-grey.disabled, .btn-light-grey:disabled {
    color: #43434c;
    background-color: #ededed;
    border-color: #ededed; }
  .btn-light-grey:not(:disabled):not(.disabled):active, .btn-light-grey:not(:disabled):not(.disabled).active,
  .show > .btn-light-grey.dropdown-toggle {
    color: #43434c;
    background-color: #d4d4d4;
    border-color: #cdcdcd; }
    .btn-light-grey:not(:disabled):not(.disabled):active:focus, .btn-light-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(212, 212, 213, 0.5); }

.btn-slate-grey {
  color: #fff;
  background-color: #616167;
  border-color: #616167; }
  .btn-slate-grey:hover {
    color: #fff;
    background-color: #4e4e53;
    border-color: #48484d; }
  .btn-slate-grey:focus, .btn-slate-grey.focus {
    color: #fff;
    background-color: #4e4e53;
    border-color: #48484d;
    box-shadow: 0 0 0 1px rgba(121, 121, 126, 0.5); }
  .btn-slate-grey.disabled, .btn-slate-grey:disabled {
    color: #fff;
    background-color: #616167;
    border-color: #616167; }
  .btn-slate-grey:not(:disabled):not(.disabled):active, .btn-slate-grey:not(:disabled):not(.disabled).active,
  .show > .btn-slate-grey.dropdown-toggle {
    color: #fff;
    background-color: #48484d;
    border-color: #424246; }
    .btn-slate-grey:not(:disabled):not(.disabled):active:focus, .btn-slate-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slate-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(121, 121, 126, 0.5); }

.btn-slate-blue {
  color: #fff;
  background-color: #244c5a;
  border-color: #244c5a; }
  .btn-slate-blue:hover {
    color: #fff;
    background-color: #19353f;
    border-color: #152d36; }
  .btn-slate-blue:focus, .btn-slate-blue.focus {
    color: #fff;
    background-color: #19353f;
    border-color: #152d36;
    box-shadow: 0 0 0 1px rgba(69, 103, 115, 0.5); }
  .btn-slate-blue.disabled, .btn-slate-blue:disabled {
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
  .btn-slate-blue:not(:disabled):not(.disabled):active, .btn-slate-blue:not(:disabled):not(.disabled).active,
  .show > .btn-slate-blue.dropdown-toggle {
    color: #fff;
    background-color: #152d36;
    border-color: #12262c; }
    .btn-slate-blue:not(:disabled):not(.disabled):active:focus, .btn-slate-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slate-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(69, 103, 115, 0.5); }

.btn-soft-blue {
  color: #43434c;
  background-color: #b8c9e1;
  border-color: #b8c9e1; }
  .btn-soft-blue:hover {
    color: #43434c;
    background-color: #9db5d6;
    border-color: #94aed2; }
  .btn-soft-blue:focus, .btn-soft-blue.focus {
    color: #43434c;
    background-color: #9db5d6;
    border-color: #94aed2;
    box-shadow: 0 0 0 1px rgba(166, 181, 203, 0.5); }
  .btn-soft-blue.disabled, .btn-soft-blue:disabled {
    color: #43434c;
    background-color: #b8c9e1;
    border-color: #b8c9e1; }
  .btn-soft-blue:not(:disabled):not(.disabled):active, .btn-soft-blue:not(:disabled):not(.disabled).active,
  .show > .btn-soft-blue.dropdown-toggle {
    color: #43434c;
    background-color: #94aed2;
    border-color: #8ba7ce; }
    .btn-soft-blue:not(:disabled):not(.disabled):active:focus, .btn-soft-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soft-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(166, 181, 203, 0.5); }

.btn-warm-grey {
  color: #43434c;
  background-color: #a2a2a2;
  border-color: #a2a2a2; }
  .btn-warm-grey:hover {
    color: #fff;
    background-color: #8f8f8f;
    border-color: #898989; }
  .btn-warm-grey:focus, .btn-warm-grey.focus {
    color: #fff;
    background-color: #8f8f8f;
    border-color: #898989;
    box-shadow: 0 0 0 1px rgba(148, 148, 149, 0.5); }
  .btn-warm-grey.disabled, .btn-warm-grey:disabled {
    color: #43434c;
    background-color: #a2a2a2;
    border-color: #a2a2a2; }
  .btn-warm-grey:not(:disabled):not(.disabled):active, .btn-warm-grey:not(:disabled):not(.disabled).active,
  .show > .btn-warm-grey.dropdown-toggle {
    color: #fff;
    background-color: #898989;
    border-color: #828282; }
    .btn-warm-grey:not(:disabled):not(.disabled):active:focus, .btn-warm-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warm-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(148, 148, 149, 0.5); }

.btn-outline-primary {
  color: #244c5a;
  border-color: #244c5a; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #244c5a;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.5); }

.btn-outline-secondary {
  color: #b8c9e1;
  border-color: #b8c9e1; }
  .btn-outline-secondary:hover {
    color: #43434c;
    background-color: #b8c9e1;
    border-color: #b8c9e1; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 1px rgba(184, 201, 225, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #b8c9e1;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #43434c;
    background-color: #b8c9e1;
    border-color: #b8c9e1; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(184, 201, 225, 0.5); }

.btn-outline-success {
  color: #55c443;
  border-color: #55c443; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #55c443;
    border-color: #55c443; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #55c443;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #55c443;
    border-color: #55c443; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ff6900;
  border-color: #ff6900; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff6900;
    border-color: #ff6900; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 1px rgba(255, 105, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff6900;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ff6900;
    border-color: #ff6900; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(255, 105, 0, 0.5); }

.btn-outline-danger {
  color: #d60808;
  border-color: #d60808; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d60808;
    border-color: #d60808; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d60808;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d60808;
    border-color: #d60808; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.5); }

.btn-outline-light {
  color: #e1e1e1;
  border-color: #e1e1e1; }
  .btn-outline-light:hover {
    color: #43434c;
    background-color: #e1e1e1;
    border-color: #e1e1e1; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 1px rgba(225, 225, 225, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e1e1e1;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #43434c;
    background-color: #e1e1e1;
    border-color: #e1e1e1; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(225, 225, 225, 0.5); }

.btn-outline-dark {
  color: #43434c;
  border-color: #43434c; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #43434c;
    border-color: #43434c; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 1px rgba(67, 67, 76, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #43434c;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #43434c;
    border-color: #43434c; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(67, 67, 76, 0.5); }

.btn-outline-black {
  color: #343741;
  border-color: #343741; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #343741;
    border-color: #343741; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 1px rgba(52, 55, 65, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #343741;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #343741;
    border-color: #343741; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(52, 55, 65, 0.5); }

.btn-outline-light-grey {
  color: #ededed;
  border-color: #ededed; }
  .btn-outline-light-grey:hover {
    color: #43434c;
    background-color: #ededed;
    border-color: #ededed; }
  .btn-outline-light-grey:focus, .btn-outline-light-grey.focus {
    box-shadow: 0 0 0 1px rgba(237, 237, 237, 0.5); }
  .btn-outline-light-grey.disabled, .btn-outline-light-grey:disabled {
    color: #ededed;
    background-color: transparent; }
  .btn-outline-light-grey:not(:disabled):not(.disabled):active, .btn-outline-light-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-grey.dropdown-toggle {
    color: #43434c;
    background-color: #ededed;
    border-color: #ededed; }
    .btn-outline-light-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-light-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(237, 237, 237, 0.5); }

.btn-outline-slate-grey {
  color: #616167;
  border-color: #616167; }
  .btn-outline-slate-grey:hover {
    color: #fff;
    background-color: #616167;
    border-color: #616167; }
  .btn-outline-slate-grey:focus, .btn-outline-slate-grey.focus {
    box-shadow: 0 0 0 1px rgba(97, 97, 103, 0.5); }
  .btn-outline-slate-grey.disabled, .btn-outline-slate-grey:disabled {
    color: #616167;
    background-color: transparent; }
  .btn-outline-slate-grey:not(:disabled):not(.disabled):active, .btn-outline-slate-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-slate-grey.dropdown-toggle {
    color: #fff;
    background-color: #616167;
    border-color: #616167; }
    .btn-outline-slate-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-slate-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-slate-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(97, 97, 103, 0.5); }

.btn-outline-slate-blue {
  color: #244c5a;
  border-color: #244c5a; }
  .btn-outline-slate-blue:hover {
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
  .btn-outline-slate-blue:focus, .btn-outline-slate-blue.focus {
    box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.5); }
  .btn-outline-slate-blue.disabled, .btn-outline-slate-blue:disabled {
    color: #244c5a;
    background-color: transparent; }
  .btn-outline-slate-blue:not(:disabled):not(.disabled):active, .btn-outline-slate-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-slate-blue.dropdown-toggle {
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
    .btn-outline-slate-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-slate-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-slate-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.5); }

.btn-outline-soft-blue {
  color: #b8c9e1;
  border-color: #b8c9e1; }
  .btn-outline-soft-blue:hover {
    color: #43434c;
    background-color: #b8c9e1;
    border-color: #b8c9e1; }
  .btn-outline-soft-blue:focus, .btn-outline-soft-blue.focus {
    box-shadow: 0 0 0 1px rgba(184, 201, 225, 0.5); }
  .btn-outline-soft-blue.disabled, .btn-outline-soft-blue:disabled {
    color: #b8c9e1;
    background-color: transparent; }
  .btn-outline-soft-blue:not(:disabled):not(.disabled):active, .btn-outline-soft-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-soft-blue.dropdown-toggle {
    color: #43434c;
    background-color: #b8c9e1;
    border-color: #b8c9e1; }
    .btn-outline-soft-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-soft-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-soft-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(184, 201, 225, 0.5); }

.btn-outline-warm-grey {
  color: #a2a2a2;
  border-color: #a2a2a2; }
  .btn-outline-warm-grey:hover {
    color: #43434c;
    background-color: #a2a2a2;
    border-color: #a2a2a2; }
  .btn-outline-warm-grey:focus, .btn-outline-warm-grey.focus {
    box-shadow: 0 0 0 1px rgba(162, 162, 162, 0.5); }
  .btn-outline-warm-grey.disabled, .btn-outline-warm-grey:disabled {
    color: #a2a2a2;
    background-color: transparent; }
  .btn-outline-warm-grey:not(:disabled):not(.disabled):active, .btn-outline-warm-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warm-grey.dropdown-toggle {
    color: #43434c;
    background-color: #a2a2a2;
    border-color: #a2a2a2; }
    .btn-outline-warm-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-warm-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warm-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 1px rgba(162, 162, 162, 0.5); }

.btn-link {
  font-weight: 400;
  color: #244c5a;
  text-decoration: none; }
  .btn-link:hover {
    color: #244c5a;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #616167;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.25rem;
  line-height: 3.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  line-height: 2.25;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #343741;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(52, 55, 65, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.28125rem 0;
  overflow: hidden;
  border-top: 1px solid #ededed; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #43434c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #37373e;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #244c5a; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #616167;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #616167;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #43434c; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1.125rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 3.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #ededed;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.125rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #244c5a;
    background-color: #244c5a; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 0 0 1px #244c5a; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #4899b5; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #6cadc4;
    border-color: #6cadc4; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #616167; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #ced4da; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ededed;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #244c5a;
  background-color: #244c5a; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 76, 90, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(36, 76, 90, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 76, 90, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ededed;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(36, 76, 90, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(3.5em + 0.75rem + 2px);
  padding: 0.375rem 2.125rem 0.375rem 1.125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 3.5;
  color: #495057;
  vertical-align: middle;
  background: #ededed url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.125rem center/8px 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #4899b5;
    outline: 0;
    box-shadow: inset 0 0 0 1px #244c5a; }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ededed; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.125rem;
    background-image: none; }
  .custom-select:disabled {
    color: #616167;
    background-color: #ededed; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(3.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(3.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #4899b5;
    box-shadow: inset 0 0 0 1px #244c5a; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #ced4da; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(3.5em + 0.75rem + 2px);
  padding: 0.375rem 1.125rem;
  font-weight: 400;
  line-height: 3.5;
  color: #495057;
  background-color: #ededed;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(3.5em + 0.75rem);
    padding: 0.375rem 1.125rem;
    line-height: 3.5;
    color: #495057;
    content: "Browse";
    background-color: #ededed;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 2px);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ededed, inset 0 0 0 1px #244c5a; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ededed, inset 0 0 0 1px #244c5a; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ededed, inset 0 0 0 1px #244c5a; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #244c5a;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #6cadc4; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e1e1e1;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #244c5a;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #6cadc4; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e1e1e1;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 1px;
    margin-left: 1px;
    background-color: #244c5a;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #6cadc4; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #e1e1e1;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e1e1e1;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 1rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #616167;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #e1e1e1; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #ededed #ededed #e1e1e1; }
    .nav-tabs .nav-link.disabled {
      color: #616167;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ededed;
    border-color: #e1e1e1 #e1e1e1 #ededed; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #244c5a; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.28125rem 0.5625rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  margin-right: 0.5625rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(52, 55, 65, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(52, 55, 65, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(52, 55, 65, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(52, 55, 65, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(52, 55, 65, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(52, 55, 65, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(52, 55, 65, 0.5);
  border-color: rgba(52, 55, 65, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(52, 55, 65, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(52, 55, 65, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(52, 55, 65, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(52, 55, 65, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(52, 55, 65, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(52, 55, 65, 0.03);
  border-bottom: 1px solid rgba(52, 55, 65, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(52, 55, 65, 0.03);
  border-top: 1px solid rgba(52, 55, 65, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 0.5625rem; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.5625rem;
    margin-left: -0.5625rem; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 0.5625rem;
      margin-bottom: 0;
      margin-left: 0.5625rem; } }

.card-group > .card {
  margin-bottom: 0.5625rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ededed;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #616167;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #616167; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #244c5a;
  background-color: #fff;
  border: 1px solid #e1e1e1; }
  .page-link:hover {
    z-index: 2;
    color: #244c5a;
    text-decoration: none;
    background-color: #ededed;
    border-color: #e1e1e1; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #244c5a;
  border-color: #244c5a; }

.page-item.disabled .page-link {
  color: #616167;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e1e1e1; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.5625rem;
  font-size: 75%;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #244c5a; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #152d36; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.5); }

.badge-secondary {
  color: #43434c;
  background-color: #b8c9e1; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #43434c;
    background-color: #94aed2; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(184, 201, 225, 0.5); }

.badge-success {
  color: #fff;
  background-color: #55c443; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #42a133; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(85, 196, 67, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #ff6900; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #cc5400; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(255, 105, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #d60808; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #a50606; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(214, 8, 8, 0.5); }

.badge-light {
  color: #43434c;
  background-color: #e1e1e1; }
  a.badge-light:hover, a.badge-light:focus {
    color: #43434c;
    background-color: #c8c8c8; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(225, 225, 225, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #43434c; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #2b2b31; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(67, 67, 76, 0.5); }

.badge-black {
  color: #fff;
  background-color: #343741; }
  a.badge-black:hover, a.badge-black:focus {
    color: #fff;
    background-color: #1d1f25; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(52, 55, 65, 0.5); }

.badge-light-grey {
  color: #43434c;
  background-color: #ededed; }
  a.badge-light-grey:hover, a.badge-light-grey:focus {
    color: #43434c;
    background-color: #d4d4d4; }
  a.badge-light-grey:focus, a.badge-light-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(237, 237, 237, 0.5); }

.badge-slate-grey {
  color: #fff;
  background-color: #616167; }
  a.badge-slate-grey:hover, a.badge-slate-grey:focus {
    color: #fff;
    background-color: #48484d; }
  a.badge-slate-grey:focus, a.badge-slate-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(97, 97, 103, 0.5); }

.badge-slate-blue {
  color: #fff;
  background-color: #244c5a; }
  a.badge-slate-blue:hover, a.badge-slate-blue:focus {
    color: #fff;
    background-color: #152d36; }
  a.badge-slate-blue:focus, a.badge-slate-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(36, 76, 90, 0.5); }

.badge-soft-blue {
  color: #43434c;
  background-color: #b8c9e1; }
  a.badge-soft-blue:hover, a.badge-soft-blue:focus {
    color: #43434c;
    background-color: #94aed2; }
  a.badge-soft-blue:focus, a.badge-soft-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(184, 201, 225, 0.5); }

.badge-warm-grey {
  color: #43434c;
  background-color: #a2a2a2; }
  a.badge-warm-grey:hover, a.badge-warm-grey:focus {
    color: #43434c;
    background-color: #898989; }
  a.badge-warm-grey:focus, a.badge-warm-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(162, 162, 162, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #ededed;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2c424e;
  background-color: #d3dbde;
  border-color: #c2cdd1; }
  .alert-primary hr {
    border-top-color: #b3c1c6; }
  .alert-primary .alert-link {
    color: #1a262d; }

.alert-secondary {
  color: #798394;
  background-color: #f1f4f9;
  border-color: #ebf0f7; }
  .alert-secondary hr {
    border-top-color: #d9e2f0; }
  .alert-secondary .alert-link {
    color: #616a79; }

.alert-success {
  color: #458042;
  background-color: #ddf3d9;
  border-color: #cfeeca; }
  .alert-success hr {
    border-top-color: #bee8b7; }
  .alert-success .alert-link {
    color: #335e31; }

.alert-info {
  color: #256f7f;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #194c58; }

.alert-warning {
  color: #9e511f;
  background-color: #ffe1cc;
  border-color: #ffd5b8; }
  .alert-warning hr {
    border-top-color: #ffc69f; }
  .alert-warning .alert-link {
    color: #733b17; }

.alert-danger {
  color: #881f23;
  background-color: #f7cece;
  border-color: #f4baba; }
  .alert-danger hr {
    border-top-color: #f0a4a4; }
  .alert-danger .alert-link {
    color: #5e1618; }

.alert-light {
  color: #8e8f94;
  background-color: #f9f9f9;
  border-color: #f7f7f7; }
  .alert-light hr {
    border-top-color: #eaeaea; }
  .alert-light .alert-link {
    color: #74757b; }

.alert-dark {
  color: #3c3d47;
  background-color: #d9d9db;
  border-color: #cacacd; }
  .alert-dark hr {
    border-top-color: #bdbdc1; }
  .alert-dark .alert-link {
    color: #25252b; }

.alert-black {
  color: #343741;
  background-color: #d6d7d9;
  border-color: #c6c7ca; }
  .alert-black hr {
    border-top-color: #b9babe; }
  .alert-black .alert-link {
    color: #1d1f25; }

.alert-light-grey {
  color: #94969a;
  background-color: #fbfbfb;
  border-color: #fafafa; }
  .alert-light-grey hr {
    border-top-color: #ededed; }
  .alert-light-grey .alert-link {
    color: #7a7c81; }

.alert-slate-grey {
  color: #4b4d55;
  background-color: #dfdfe1;
  border-color: #d3d3d4; }
  .alert-slate-grey hr {
    border-top-color: #c6c6c7; }
  .alert-slate-grey .alert-link {
    color: #33343a; }

.alert-slate-blue {
  color: #2c424e;
  background-color: #d3dbde;
  border-color: #c2cdd1; }
  .alert-slate-blue hr {
    border-top-color: #b3c1c6; }
  .alert-slate-blue .alert-link {
    color: #1a262d; }

.alert-soft-blue {
  color: #798394;
  background-color: #f1f4f9;
  border-color: #ebf0f7; }
  .alert-soft-blue hr {
    border-top-color: #d9e2f0; }
  .alert-soft-blue .alert-link {
    color: #616a79; }

.alert-warm-grey {
  color: #6d6f73;
  background-color: #ececec;
  border-color: #e5e5e5; }
  .alert-warm-grey hr {
    border-top-color: #d8d8d8; }
  .alert-warm-grey .alert-link {
    color: #545659; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #ededed;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #244c5a;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #343741;
    background-color: whitesmoke; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 0 solid rgba(52, 55, 65, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #616167;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #244c5a;
    border-color: #244c5a; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: 0;
      border-top-width: 0; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 0;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: 0;
      border-left-width: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 0;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: 0;
        border-left-width: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 0;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: 0;
        border-left-width: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 0;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: 0;
        border-left-width: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 0;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: 0;
        border-left-width: 0; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #2c424e;
  background-color: #c2cdd1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2c424e;
    background-color: #b3c1c6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2c424e;
    border-color: #2c424e; }

.list-group-item-secondary {
  color: #798394;
  background-color: #ebf0f7; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #798394;
    background-color: #d9e2f0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #798394;
    border-color: #798394; }

.list-group-item-success {
  color: #458042;
  background-color: #cfeeca; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #458042;
    background-color: #bee8b7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #458042;
    border-color: #458042; }

.list-group-item-info {
  color: #256f7f;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #256f7f;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #256f7f;
    border-color: #256f7f; }

.list-group-item-warning {
  color: #9e511f;
  background-color: #ffd5b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #9e511f;
    background-color: #ffc69f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #9e511f;
    border-color: #9e511f; }

.list-group-item-danger {
  color: #881f23;
  background-color: #f4baba; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #881f23;
    background-color: #f0a4a4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #881f23;
    border-color: #881f23; }

.list-group-item-light {
  color: #8e8f94;
  background-color: #f7f7f7; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #8e8f94;
    background-color: #eaeaea; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8e8f94;
    border-color: #8e8f94; }

.list-group-item-dark {
  color: #3c3d47;
  background-color: #cacacd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #3c3d47;
    background-color: #bdbdc1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #3c3d47;
    border-color: #3c3d47; }

.list-group-item-black {
  color: #343741;
  background-color: #c6c7ca; }
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: #343741;
    background-color: #b9babe; }
  .list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: #343741;
    border-color: #343741; }

.list-group-item-light-grey {
  color: #94969a;
  background-color: #fafafa; }
  .list-group-item-light-grey.list-group-item-action:hover, .list-group-item-light-grey.list-group-item-action:focus {
    color: #94969a;
    background-color: #ededed; }
  .list-group-item-light-grey.list-group-item-action.active {
    color: #fff;
    background-color: #94969a;
    border-color: #94969a; }

.list-group-item-slate-grey {
  color: #4b4d55;
  background-color: #d3d3d4; }
  .list-group-item-slate-grey.list-group-item-action:hover, .list-group-item-slate-grey.list-group-item-action:focus {
    color: #4b4d55;
    background-color: #c6c6c7; }
  .list-group-item-slate-grey.list-group-item-action.active {
    color: #fff;
    background-color: #4b4d55;
    border-color: #4b4d55; }

.list-group-item-slate-blue {
  color: #2c424e;
  background-color: #c2cdd1; }
  .list-group-item-slate-blue.list-group-item-action:hover, .list-group-item-slate-blue.list-group-item-action:focus {
    color: #2c424e;
    background-color: #b3c1c6; }
  .list-group-item-slate-blue.list-group-item-action.active {
    color: #fff;
    background-color: #2c424e;
    border-color: #2c424e; }

.list-group-item-soft-blue {
  color: #798394;
  background-color: #ebf0f7; }
  .list-group-item-soft-blue.list-group-item-action:hover, .list-group-item-soft-blue.list-group-item-action:focus {
    color: #798394;
    background-color: #d9e2f0; }
  .list-group-item-soft-blue.list-group-item-action.active {
    color: #fff;
    background-color: #798394;
    border-color: #798394; }

.list-group-item-warm-grey {
  color: #6d6f73;
  background-color: #e5e5e5; }
  .list-group-item-warm-grey.list-group-item-action:hover, .list-group-item-warm-grey.list-group-item-action:focus {
    color: #6d6f73;
    background-color: #d8d8d8; }
  .list-group-item-warm-grey.list-group-item-action.active {
    color: #fff;
    background-color: #6d6f73;
    border-color: #6d6f73; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #343741;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #343741;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(52, 55, 65, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #616167;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(52, 55, 65, 0.2);
  border-radius: 0;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #43434c; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.85; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #e1e1e1;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: -0.25rem;
  border-top: 0 solid #e1e1e1;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1280px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #343741; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #343741; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #343741; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #343741; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #343741;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(52, 55, 65, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(52, 55, 65, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(52, 55, 65, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(52, 55, 65, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(52, 55, 65, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #343741; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #244c5a !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #152d36 !important; }

.bg-secondary {
  background-color: #b8c9e1 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #94aed2 !important; }

.bg-success {
  background-color: #55c443 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #42a133 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ff6900 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc5400 !important; }

.bg-danger {
  background-color: #d60808 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a50606 !important; }

.bg-light {
  background-color: #e1e1e1 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c8c8c8 !important; }

.bg-dark {
  background-color: #43434c !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #2b2b31 !important; }

.bg-black {
  background-color: #343741 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #1d1f25 !important; }

.bg-light-grey {
  background-color: #ededed !important; }

a.bg-light-grey:hover, a.bg-light-grey:focus,
button.bg-light-grey:hover,
button.bg-light-grey:focus {
  background-color: #d4d4d4 !important; }

.bg-slate-grey {
  background-color: #616167 !important; }

a.bg-slate-grey:hover, a.bg-slate-grey:focus,
button.bg-slate-grey:hover,
button.bg-slate-grey:focus {
  background-color: #48484d !important; }

.bg-slate-blue {
  background-color: #244c5a !important; }

a.bg-slate-blue:hover, a.bg-slate-blue:focus,
button.bg-slate-blue:hover,
button.bg-slate-blue:focus {
  background-color: #152d36 !important; }

.bg-soft-blue {
  background-color: #b8c9e1 !important; }

a.bg-soft-blue:hover, a.bg-soft-blue:focus,
button.bg-soft-blue:hover,
button.bg-soft-blue:focus {
  background-color: #94aed2 !important; }

.bg-warm-grey {
  background-color: #a2a2a2 !important; }

a.bg-warm-grey:hover, a.bg-warm-grey:focus,
button.bg-warm-grey:hover,
button.bg-warm-grey:focus {
  background-color: #898989 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e1e1e1 !important; }

.border-top {
  border-top: 1px solid #e1e1e1 !important; }

.border-right {
  border-right: 1px solid #e1e1e1 !important; }

.border-bottom {
  border-bottom: 1px solid #e1e1e1 !important; }

.border-left {
  border-left: 1px solid #e1e1e1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #244c5a !important; }

.border-secondary {
  border-color: #b8c9e1 !important; }

.border-success {
  border-color: #55c443 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ff6900 !important; }

.border-danger {
  border-color: #d60808 !important; }

.border-light {
  border-color: #e1e1e1 !important; }

.border-dark {
  border-color: #43434c !important; }

.border-black {
  border-color: #343741 !important; }

.border-light-grey {
  border-color: #ededed !important; }

.border-slate-grey {
  border-color: #616167 !important; }

.border-slate-blue {
  border-color: #244c5a !important; }

.border-soft-blue {
  border-color: #b8c9e1 !important; }

.border-warm-grey {
  border-color: #a2a2a2 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(52, 55, 65, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(52, 55, 65, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(52, 55, 65, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5625rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5625rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5625rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5625rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5625rem !important; }

.m-2 {
  margin: 1.125rem !important; }

.mt-2,
.my-2 {
  margin-top: 1.125rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1.125rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1.125rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1.125rem !important; }

.m-3 {
  margin: 1.6875rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.6875rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.6875rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.6875rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.6875rem !important; }

.m-4 {
  margin: 2.25rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.25rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important; }

.m-5 {
  margin: 2.8125rem !important; }

.mt-5,
.my-5 {
  margin-top: 2.8125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2.8125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2.8125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2.8125rem !important; }

.m-6 {
  margin: 3.375rem !important; }

.mt-6,
.my-6 {
  margin-top: 3.375rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3.375rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3.375rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3.375rem !important; }

.m-7 {
  margin: 3.9375rem !important; }

.mt-7,
.my-7 {
  margin-top: 3.9375rem !important; }

.mr-7,
.mx-7 {
  margin-right: 3.9375rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 3.9375rem !important; }

.ml-7,
.mx-7 {
  margin-left: 3.9375rem !important; }

.m-8 {
  margin: 4.5rem !important; }

.mt-8,
.my-8 {
  margin-top: 4.5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 4.5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 4.5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 4.5rem !important; }

.m-9 {
  margin: 5.0625rem !important; }

.mt-9,
.my-9 {
  margin-top: 5.0625rem !important; }

.mr-9,
.mx-9 {
  margin-right: 5.0625rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 5.0625rem !important; }

.ml-9,
.mx-9 {
  margin-left: 5.0625rem !important; }

.m-10 {
  margin: 5.625rem !important; }

.mt-10,
.my-10 {
  margin-top: 5.625rem !important; }

.mr-10,
.mx-10 {
  margin-right: 5.625rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 5.625rem !important; }

.ml-10,
.mx-10 {
  margin-left: 5.625rem !important; }

.m-11 {
  margin: 6.1875rem !important; }

.mt-11,
.my-11 {
  margin-top: 6.1875rem !important; }

.mr-11,
.mx-11 {
  margin-right: 6.1875rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 6.1875rem !important; }

.ml-11,
.mx-11 {
  margin-left: 6.1875rem !important; }

.m-12 {
  margin: 6.75rem !important; }

.mt-12,
.my-12 {
  margin-top: 6.75rem !important; }

.mr-12,
.mx-12 {
  margin-right: 6.75rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 6.75rem !important; }

.ml-12,
.mx-12 {
  margin-left: 6.75rem !important; }

.m-13 {
  margin: 7.3125rem !important; }

.mt-13,
.my-13 {
  margin-top: 7.3125rem !important; }

.mr-13,
.mx-13 {
  margin-right: 7.3125rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 7.3125rem !important; }

.ml-13,
.mx-13 {
  margin-left: 7.3125rem !important; }

.m-14 {
  margin: 7.875rem !important; }

.mt-14,
.my-14 {
  margin-top: 7.875rem !important; }

.mr-14,
.mx-14 {
  margin-right: 7.875rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 7.875rem !important; }

.ml-14,
.mx-14 {
  margin-left: 7.875rem !important; }

.m-15 {
  margin: 8.4375rem !important; }

.mt-15,
.my-15 {
  margin-top: 8.4375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 8.4375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 8.4375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 8.4375rem !important; }

.m-16 {
  margin: 9rem !important; }

.mt-16,
.my-16 {
  margin-top: 9rem !important; }

.mr-16,
.mx-16 {
  margin-right: 9rem !important; }

.mb-16,
.my-16 {
  margin-bottom: 9rem !important; }

.ml-16,
.mx-16 {
  margin-left: 9rem !important; }

.m-17 {
  margin: 9.5625rem !important; }

.mt-17,
.my-17 {
  margin-top: 9.5625rem !important; }

.mr-17,
.mx-17 {
  margin-right: 9.5625rem !important; }

.mb-17,
.my-17 {
  margin-bottom: 9.5625rem !important; }

.ml-17,
.mx-17 {
  margin-left: 9.5625rem !important; }

.m-18 {
  margin: 10.125rem !important; }

.mt-18,
.my-18 {
  margin-top: 10.125rem !important; }

.mr-18,
.mx-18 {
  margin-right: 10.125rem !important; }

.mb-18,
.my-18 {
  margin-bottom: 10.125rem !important; }

.ml-18,
.mx-18 {
  margin-left: 10.125rem !important; }

.m-19 {
  margin: 10.6875rem !important; }

.mt-19,
.my-19 {
  margin-top: 10.6875rem !important; }

.mr-19,
.mx-19 {
  margin-right: 10.6875rem !important; }

.mb-19,
.my-19 {
  margin-bottom: 10.6875rem !important; }

.ml-19,
.mx-19 {
  margin-left: 10.6875rem !important; }

.m-20 {
  margin: 11.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 11.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 11.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 11.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 11.25rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5625rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5625rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5625rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5625rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5625rem !important; }

.p-2 {
  padding: 1.125rem !important; }

.pt-2,
.py-2 {
  padding-top: 1.125rem !important; }

.pr-2,
.px-2 {
  padding-right: 1.125rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1.125rem !important; }

.pl-2,
.px-2 {
  padding-left: 1.125rem !important; }

.p-3 {
  padding: 1.6875rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.6875rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.6875rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.6875rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.6875rem !important; }

.p-4 {
  padding: 2.25rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.25rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.25rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.25rem !important; }

.p-5 {
  padding: 2.8125rem !important; }

.pt-5,
.py-5 {
  padding-top: 2.8125rem !important; }

.pr-5,
.px-5 {
  padding-right: 2.8125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2.8125rem !important; }

.pl-5,
.px-5 {
  padding-left: 2.8125rem !important; }

.p-6 {
  padding: 3.375rem !important; }

.pt-6,
.py-6 {
  padding-top: 3.375rem !important; }

.pr-6,
.px-6 {
  padding-right: 3.375rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3.375rem !important; }

.pl-6,
.px-6 {
  padding-left: 3.375rem !important; }

.p-7 {
  padding: 3.9375rem !important; }

.pt-7,
.py-7 {
  padding-top: 3.9375rem !important; }

.pr-7,
.px-7 {
  padding-right: 3.9375rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 3.9375rem !important; }

.pl-7,
.px-7 {
  padding-left: 3.9375rem !important; }

.p-8 {
  padding: 4.5rem !important; }

.pt-8,
.py-8 {
  padding-top: 4.5rem !important; }

.pr-8,
.px-8 {
  padding-right: 4.5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 4.5rem !important; }

.pl-8,
.px-8 {
  padding-left: 4.5rem !important; }

.p-9 {
  padding: 5.0625rem !important; }

.pt-9,
.py-9 {
  padding-top: 5.0625rem !important; }

.pr-9,
.px-9 {
  padding-right: 5.0625rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 5.0625rem !important; }

.pl-9,
.px-9 {
  padding-left: 5.0625rem !important; }

.p-10 {
  padding: 5.625rem !important; }

.pt-10,
.py-10 {
  padding-top: 5.625rem !important; }

.pr-10,
.px-10 {
  padding-right: 5.625rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 5.625rem !important; }

.pl-10,
.px-10 {
  padding-left: 5.625rem !important; }

.p-11 {
  padding: 6.1875rem !important; }

.pt-11,
.py-11 {
  padding-top: 6.1875rem !important; }

.pr-11,
.px-11 {
  padding-right: 6.1875rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 6.1875rem !important; }

.pl-11,
.px-11 {
  padding-left: 6.1875rem !important; }

.p-12 {
  padding: 6.75rem !important; }

.pt-12,
.py-12 {
  padding-top: 6.75rem !important; }

.pr-12,
.px-12 {
  padding-right: 6.75rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 6.75rem !important; }

.pl-12,
.px-12 {
  padding-left: 6.75rem !important; }

.p-13 {
  padding: 7.3125rem !important; }

.pt-13,
.py-13 {
  padding-top: 7.3125rem !important; }

.pr-13,
.px-13 {
  padding-right: 7.3125rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 7.3125rem !important; }

.pl-13,
.px-13 {
  padding-left: 7.3125rem !important; }

.p-14 {
  padding: 7.875rem !important; }

.pt-14,
.py-14 {
  padding-top: 7.875rem !important; }

.pr-14,
.px-14 {
  padding-right: 7.875rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 7.875rem !important; }

.pl-14,
.px-14 {
  padding-left: 7.875rem !important; }

.p-15 {
  padding: 8.4375rem !important; }

.pt-15,
.py-15 {
  padding-top: 8.4375rem !important; }

.pr-15,
.px-15 {
  padding-right: 8.4375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 8.4375rem !important; }

.pl-15,
.px-15 {
  padding-left: 8.4375rem !important; }

.p-16 {
  padding: 9rem !important; }

.pt-16,
.py-16 {
  padding-top: 9rem !important; }

.pr-16,
.px-16 {
  padding-right: 9rem !important; }

.pb-16,
.py-16 {
  padding-bottom: 9rem !important; }

.pl-16,
.px-16 {
  padding-left: 9rem !important; }

.p-17 {
  padding: 9.5625rem !important; }

.pt-17,
.py-17 {
  padding-top: 9.5625rem !important; }

.pr-17,
.px-17 {
  padding-right: 9.5625rem !important; }

.pb-17,
.py-17 {
  padding-bottom: 9.5625rem !important; }

.pl-17,
.px-17 {
  padding-left: 9.5625rem !important; }

.p-18 {
  padding: 10.125rem !important; }

.pt-18,
.py-18 {
  padding-top: 10.125rem !important; }

.pr-18,
.px-18 {
  padding-right: 10.125rem !important; }

.pb-18,
.py-18 {
  padding-bottom: 10.125rem !important; }

.pl-18,
.px-18 {
  padding-left: 10.125rem !important; }

.p-19 {
  padding: 10.6875rem !important; }

.pt-19,
.py-19 {
  padding-top: 10.6875rem !important; }

.pr-19,
.px-19 {
  padding-right: 10.6875rem !important; }

.pb-19,
.py-19 {
  padding-bottom: 10.6875rem !important; }

.pl-19,
.px-19 {
  padding-left: 10.6875rem !important; }

.p-20 {
  padding: 11.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 11.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 11.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 11.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 11.25rem !important; }

.m-n1 {
  margin: -0.5625rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.5625rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.5625rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.5625rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.5625rem !important; }

.m-n2 {
  margin: -1.125rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1.125rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1.125rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1.125rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1.125rem !important; }

.m-n3 {
  margin: -1.6875rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.6875rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.6875rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.6875rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.6875rem !important; }

.m-n4 {
  margin: -2.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important; }

.m-n5 {
  margin: -2.8125rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2.8125rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2.8125rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2.8125rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2.8125rem !important; }

.m-n6 {
  margin: -3.375rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3.375rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3.375rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3.375rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3.375rem !important; }

.m-n7 {
  margin: -3.9375rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -3.9375rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -3.9375rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -3.9375rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -3.9375rem !important; }

.m-n8 {
  margin: -4.5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -4.5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -4.5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -4.5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -4.5rem !important; }

.m-n9 {
  margin: -5.0625rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -5.0625rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -5.0625rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -5.0625rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -5.0625rem !important; }

.m-n10 {
  margin: -5.625rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -5.625rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -5.625rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -5.625rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -5.625rem !important; }

.m-n11 {
  margin: -6.1875rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -6.1875rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -6.1875rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -6.1875rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -6.1875rem !important; }

.m-n12 {
  margin: -6.75rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -6.75rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -6.75rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -6.75rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -6.75rem !important; }

.m-n13 {
  margin: -7.3125rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -7.3125rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -7.3125rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -7.3125rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -7.3125rem !important; }

.m-n14 {
  margin: -7.875rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -7.875rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -7.875rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -7.875rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -7.875rem !important; }

.m-n15 {
  margin: -8.4375rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -8.4375rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -8.4375rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -8.4375rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -8.4375rem !important; }

.m-n16 {
  margin: -9rem !important; }

.mt-n16,
.my-n16 {
  margin-top: -9rem !important; }

.mr-n16,
.mx-n16 {
  margin-right: -9rem !important; }

.mb-n16,
.my-n16 {
  margin-bottom: -9rem !important; }

.ml-n16,
.mx-n16 {
  margin-left: -9rem !important; }

.m-n17 {
  margin: -9.5625rem !important; }

.mt-n17,
.my-n17 {
  margin-top: -9.5625rem !important; }

.mr-n17,
.mx-n17 {
  margin-right: -9.5625rem !important; }

.mb-n17,
.my-n17 {
  margin-bottom: -9.5625rem !important; }

.ml-n17,
.mx-n17 {
  margin-left: -9.5625rem !important; }

.m-n18 {
  margin: -10.125rem !important; }

.mt-n18,
.my-n18 {
  margin-top: -10.125rem !important; }

.mr-n18,
.mx-n18 {
  margin-right: -10.125rem !important; }

.mb-n18,
.my-n18 {
  margin-bottom: -10.125rem !important; }

.ml-n18,
.mx-n18 {
  margin-left: -10.125rem !important; }

.m-n19 {
  margin: -10.6875rem !important; }

.mt-n19,
.my-n19 {
  margin-top: -10.6875rem !important; }

.mr-n19,
.mx-n19 {
  margin-right: -10.6875rem !important; }

.mb-n19,
.my-n19 {
  margin-bottom: -10.6875rem !important; }

.ml-n19,
.mx-n19 {
  margin-left: -10.6875rem !important; }

.m-n20 {
  margin: -11.25rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -11.25rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -11.25rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -11.25rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -11.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5625rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5625rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5625rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5625rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5625rem !important; }
  .m-sm-2 {
    margin: 1.125rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.125rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.125rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.125rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.125rem !important; }
  .m-sm-3 {
    margin: 1.6875rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.6875rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.6875rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.6875rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.6875rem !important; }
  .m-sm-4 {
    margin: 2.25rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important; }
  .m-sm-5 {
    margin: 2.8125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.8125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.8125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.8125rem !important; }
  .m-sm-6 {
    margin: 3.375rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.375rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.375rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.375rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.375rem !important; }
  .m-sm-7 {
    margin: 3.9375rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.9375rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.9375rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.9375rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.9375rem !important; }
  .m-sm-8 {
    margin: 4.5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4.5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4.5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4.5rem !important; }
  .m-sm-9 {
    margin: 5.0625rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 5.0625rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 5.0625rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 5.0625rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 5.0625rem !important; }
  .m-sm-10 {
    margin: 5.625rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5.625rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5.625rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5.625rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5.625rem !important; }
  .m-sm-11 {
    margin: 6.1875rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 6.1875rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 6.1875rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 6.1875rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 6.1875rem !important; }
  .m-sm-12 {
    margin: 6.75rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6.75rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6.75rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6.75rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6.75rem !important; }
  .m-sm-13 {
    margin: 7.3125rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 7.3125rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 7.3125rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 7.3125rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 7.3125rem !important; }
  .m-sm-14 {
    margin: 7.875rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 7.875rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 7.875rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 7.875rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 7.875rem !important; }
  .m-sm-15 {
    margin: 8.4375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 8.4375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 8.4375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 8.4375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 8.4375rem !important; }
  .m-sm-16 {
    margin: 9rem !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 9rem !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 9rem !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 9rem !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 9rem !important; }
  .m-sm-17 {
    margin: 9.5625rem !important; }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 9.5625rem !important; }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 9.5625rem !important; }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 9.5625rem !important; }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 9.5625rem !important; }
  .m-sm-18 {
    margin: 10.125rem !important; }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 10.125rem !important; }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 10.125rem !important; }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 10.125rem !important; }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 10.125rem !important; }
  .m-sm-19 {
    margin: 10.6875rem !important; }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 10.6875rem !important; }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 10.6875rem !important; }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 10.6875rem !important; }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 10.6875rem !important; }
  .m-sm-20 {
    margin: 11.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 11.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 11.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 11.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 11.25rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5625rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5625rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5625rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5625rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5625rem !important; }
  .p-sm-2 {
    padding: 1.125rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.125rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.125rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.125rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.125rem !important; }
  .p-sm-3 {
    padding: 1.6875rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.6875rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.6875rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.6875rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.6875rem !important; }
  .p-sm-4 {
    padding: 2.25rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important; }
  .p-sm-5 {
    padding: 2.8125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.8125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.8125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.8125rem !important; }
  .p-sm-6 {
    padding: 3.375rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.375rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.375rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.375rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.375rem !important; }
  .p-sm-7 {
    padding: 3.9375rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.9375rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.9375rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.9375rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.9375rem !important; }
  .p-sm-8 {
    padding: 4.5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4.5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4.5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4.5rem !important; }
  .p-sm-9 {
    padding: 5.0625rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 5.0625rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 5.0625rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 5.0625rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 5.0625rem !important; }
  .p-sm-10 {
    padding: 5.625rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5.625rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5.625rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5.625rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5.625rem !important; }
  .p-sm-11 {
    padding: 6.1875rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 6.1875rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 6.1875rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 6.1875rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 6.1875rem !important; }
  .p-sm-12 {
    padding: 6.75rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6.75rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6.75rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6.75rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6.75rem !important; }
  .p-sm-13 {
    padding: 7.3125rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 7.3125rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 7.3125rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 7.3125rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 7.3125rem !important; }
  .p-sm-14 {
    padding: 7.875rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 7.875rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 7.875rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 7.875rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 7.875rem !important; }
  .p-sm-15 {
    padding: 8.4375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 8.4375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 8.4375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 8.4375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 8.4375rem !important; }
  .p-sm-16 {
    padding: 9rem !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 9rem !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 9rem !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 9rem !important; }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 9rem !important; }
  .p-sm-17 {
    padding: 9.5625rem !important; }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 9.5625rem !important; }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 9.5625rem !important; }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 9.5625rem !important; }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 9.5625rem !important; }
  .p-sm-18 {
    padding: 10.125rem !important; }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 10.125rem !important; }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 10.125rem !important; }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 10.125rem !important; }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 10.125rem !important; }
  .p-sm-19 {
    padding: 10.6875rem !important; }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 10.6875rem !important; }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 10.6875rem !important; }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 10.6875rem !important; }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 10.6875rem !important; }
  .p-sm-20 {
    padding: 11.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 11.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 11.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 11.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 11.25rem !important; }
  .m-sm-n1 {
    margin: -0.5625rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5625rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5625rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5625rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5625rem !important; }
  .m-sm-n2 {
    margin: -1.125rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1.125rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1.125rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1.125rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1.125rem !important; }
  .m-sm-n3 {
    margin: -1.6875rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.6875rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.6875rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.6875rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.6875rem !important; }
  .m-sm-n4 {
    margin: -2.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important; }
  .m-sm-n5 {
    margin: -2.8125rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2.8125rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2.8125rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2.8125rem !important; }
  .m-sm-n6 {
    margin: -3.375rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3.375rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3.375rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3.375rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3.375rem !important; }
  .m-sm-n7 {
    margin: -3.9375rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3.9375rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3.9375rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3.9375rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3.9375rem !important; }
  .m-sm-n8 {
    margin: -4.5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -4.5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -4.5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -4.5rem !important; }
  .m-sm-n9 {
    margin: -5.0625rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -5.0625rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -5.0625rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -5.0625rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -5.0625rem !important; }
  .m-sm-n10 {
    margin: -5.625rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -5.625rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -5.625rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -5.625rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -5.625rem !important; }
  .m-sm-n11 {
    margin: -6.1875rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -6.1875rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -6.1875rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -6.1875rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -6.1875rem !important; }
  .m-sm-n12 {
    margin: -6.75rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -6.75rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -6.75rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -6.75rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -6.75rem !important; }
  .m-sm-n13 {
    margin: -7.3125rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -7.3125rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -7.3125rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -7.3125rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -7.3125rem !important; }
  .m-sm-n14 {
    margin: -7.875rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -7.875rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -7.875rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -7.875rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -7.875rem !important; }
  .m-sm-n15 {
    margin: -8.4375rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -8.4375rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -8.4375rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -8.4375rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -8.4375rem !important; }
  .m-sm-n16 {
    margin: -9rem !important; }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -9rem !important; }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -9rem !important; }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -9rem !important; }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -9rem !important; }
  .m-sm-n17 {
    margin: -9.5625rem !important; }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -9.5625rem !important; }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -9.5625rem !important; }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -9.5625rem !important; }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -9.5625rem !important; }
  .m-sm-n18 {
    margin: -10.125rem !important; }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -10.125rem !important; }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -10.125rem !important; }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -10.125rem !important; }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -10.125rem !important; }
  .m-sm-n19 {
    margin: -10.6875rem !important; }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -10.6875rem !important; }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -10.6875rem !important; }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -10.6875rem !important; }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -10.6875rem !important; }
  .m-sm-n20 {
    margin: -11.25rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -11.25rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -11.25rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -11.25rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -11.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5625rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5625rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5625rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5625rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5625rem !important; }
  .m-md-2 {
    margin: 1.125rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.125rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.125rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.125rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.125rem !important; }
  .m-md-3 {
    margin: 1.6875rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.6875rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.6875rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.6875rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.6875rem !important; }
  .m-md-4 {
    margin: 2.25rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important; }
  .m-md-5 {
    margin: 2.8125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.8125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.8125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.8125rem !important; }
  .m-md-6 {
    margin: 3.375rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.375rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.375rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.375rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.375rem !important; }
  .m-md-7 {
    margin: 3.9375rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.9375rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.9375rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.9375rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.9375rem !important; }
  .m-md-8 {
    margin: 4.5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4.5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4.5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4.5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4.5rem !important; }
  .m-md-9 {
    margin: 5.0625rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 5.0625rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 5.0625rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 5.0625rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 5.0625rem !important; }
  .m-md-10 {
    margin: 5.625rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5.625rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5.625rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5.625rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5.625rem !important; }
  .m-md-11 {
    margin: 6.1875rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 6.1875rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 6.1875rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 6.1875rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 6.1875rem !important; }
  .m-md-12 {
    margin: 6.75rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6.75rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6.75rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6.75rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6.75rem !important; }
  .m-md-13 {
    margin: 7.3125rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 7.3125rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 7.3125rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 7.3125rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 7.3125rem !important; }
  .m-md-14 {
    margin: 7.875rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 7.875rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 7.875rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 7.875rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 7.875rem !important; }
  .m-md-15 {
    margin: 8.4375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 8.4375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 8.4375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 8.4375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 8.4375rem !important; }
  .m-md-16 {
    margin: 9rem !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 9rem !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 9rem !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 9rem !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 9rem !important; }
  .m-md-17 {
    margin: 9.5625rem !important; }
  .mt-md-17,
  .my-md-17 {
    margin-top: 9.5625rem !important; }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 9.5625rem !important; }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 9.5625rem !important; }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 9.5625rem !important; }
  .m-md-18 {
    margin: 10.125rem !important; }
  .mt-md-18,
  .my-md-18 {
    margin-top: 10.125rem !important; }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 10.125rem !important; }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 10.125rem !important; }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 10.125rem !important; }
  .m-md-19 {
    margin: 10.6875rem !important; }
  .mt-md-19,
  .my-md-19 {
    margin-top: 10.6875rem !important; }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 10.6875rem !important; }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 10.6875rem !important; }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 10.6875rem !important; }
  .m-md-20 {
    margin: 11.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 11.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 11.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 11.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 11.25rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5625rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5625rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5625rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5625rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5625rem !important; }
  .p-md-2 {
    padding: 1.125rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.125rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.125rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.125rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.125rem !important; }
  .p-md-3 {
    padding: 1.6875rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.6875rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.6875rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.6875rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.6875rem !important; }
  .p-md-4 {
    padding: 2.25rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important; }
  .p-md-5 {
    padding: 2.8125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.8125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.8125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.8125rem !important; }
  .p-md-6 {
    padding: 3.375rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.375rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.375rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.375rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.375rem !important; }
  .p-md-7 {
    padding: 3.9375rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.9375rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.9375rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.9375rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.9375rem !important; }
  .p-md-8 {
    padding: 4.5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4.5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4.5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4.5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 4.5rem !important; }
  .p-md-9 {
    padding: 5.0625rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 5.0625rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 5.0625rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 5.0625rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 5.0625rem !important; }
  .p-md-10 {
    padding: 5.625rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5.625rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5.625rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5.625rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5.625rem !important; }
  .p-md-11 {
    padding: 6.1875rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 6.1875rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 6.1875rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 6.1875rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 6.1875rem !important; }
  .p-md-12 {
    padding: 6.75rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6.75rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6.75rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6.75rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 6.75rem !important; }
  .p-md-13 {
    padding: 7.3125rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 7.3125rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 7.3125rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 7.3125rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 7.3125rem !important; }
  .p-md-14 {
    padding: 7.875rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 7.875rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 7.875rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 7.875rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 7.875rem !important; }
  .p-md-15 {
    padding: 8.4375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 8.4375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 8.4375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 8.4375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 8.4375rem !important; }
  .p-md-16 {
    padding: 9rem !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 9rem !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 9rem !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 9rem !important; }
  .pl-md-16,
  .px-md-16 {
    padding-left: 9rem !important; }
  .p-md-17 {
    padding: 9.5625rem !important; }
  .pt-md-17,
  .py-md-17 {
    padding-top: 9.5625rem !important; }
  .pr-md-17,
  .px-md-17 {
    padding-right: 9.5625rem !important; }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 9.5625rem !important; }
  .pl-md-17,
  .px-md-17 {
    padding-left: 9.5625rem !important; }
  .p-md-18 {
    padding: 10.125rem !important; }
  .pt-md-18,
  .py-md-18 {
    padding-top: 10.125rem !important; }
  .pr-md-18,
  .px-md-18 {
    padding-right: 10.125rem !important; }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 10.125rem !important; }
  .pl-md-18,
  .px-md-18 {
    padding-left: 10.125rem !important; }
  .p-md-19 {
    padding: 10.6875rem !important; }
  .pt-md-19,
  .py-md-19 {
    padding-top: 10.6875rem !important; }
  .pr-md-19,
  .px-md-19 {
    padding-right: 10.6875rem !important; }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 10.6875rem !important; }
  .pl-md-19,
  .px-md-19 {
    padding-left: 10.6875rem !important; }
  .p-md-20 {
    padding: 11.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 11.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 11.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 11.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 11.25rem !important; }
  .m-md-n1 {
    margin: -0.5625rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5625rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5625rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5625rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5625rem !important; }
  .m-md-n2 {
    margin: -1.125rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1.125rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1.125rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1.125rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1.125rem !important; }
  .m-md-n3 {
    margin: -1.6875rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.6875rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.6875rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.6875rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.6875rem !important; }
  .m-md-n4 {
    margin: -2.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important; }
  .m-md-n5 {
    margin: -2.8125rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2.8125rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2.8125rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2.8125rem !important; }
  .m-md-n6 {
    margin: -3.375rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3.375rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3.375rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3.375rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3.375rem !important; }
  .m-md-n7 {
    margin: -3.9375rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3.9375rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3.9375rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3.9375rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3.9375rem !important; }
  .m-md-n8 {
    margin: -4.5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -4.5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -4.5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -4.5rem !important; }
  .m-md-n9 {
    margin: -5.0625rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -5.0625rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -5.0625rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -5.0625rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -5.0625rem !important; }
  .m-md-n10 {
    margin: -5.625rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -5.625rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -5.625rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -5.625rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -5.625rem !important; }
  .m-md-n11 {
    margin: -6.1875rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -6.1875rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -6.1875rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -6.1875rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -6.1875rem !important; }
  .m-md-n12 {
    margin: -6.75rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -6.75rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -6.75rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -6.75rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -6.75rem !important; }
  .m-md-n13 {
    margin: -7.3125rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -7.3125rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -7.3125rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -7.3125rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -7.3125rem !important; }
  .m-md-n14 {
    margin: -7.875rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -7.875rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -7.875rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -7.875rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -7.875rem !important; }
  .m-md-n15 {
    margin: -8.4375rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -8.4375rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -8.4375rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -8.4375rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -8.4375rem !important; }
  .m-md-n16 {
    margin: -9rem !important; }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -9rem !important; }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -9rem !important; }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -9rem !important; }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -9rem !important; }
  .m-md-n17 {
    margin: -9.5625rem !important; }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -9.5625rem !important; }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -9.5625rem !important; }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -9.5625rem !important; }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -9.5625rem !important; }
  .m-md-n18 {
    margin: -10.125rem !important; }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -10.125rem !important; }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -10.125rem !important; }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -10.125rem !important; }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -10.125rem !important; }
  .m-md-n19 {
    margin: -10.6875rem !important; }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -10.6875rem !important; }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -10.6875rem !important; }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -10.6875rem !important; }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -10.6875rem !important; }
  .m-md-n20 {
    margin: -11.25rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -11.25rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -11.25rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -11.25rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -11.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5625rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5625rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5625rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5625rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5625rem !important; }
  .m-lg-2 {
    margin: 1.125rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.125rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.125rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.125rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.125rem !important; }
  .m-lg-3 {
    margin: 1.6875rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.6875rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.6875rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.6875rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.6875rem !important; }
  .m-lg-4 {
    margin: 2.25rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important; }
  .m-lg-5 {
    margin: 2.8125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.8125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.8125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.8125rem !important; }
  .m-lg-6 {
    margin: 3.375rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.375rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.375rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.375rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.375rem !important; }
  .m-lg-7 {
    margin: 3.9375rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.9375rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.9375rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.9375rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.9375rem !important; }
  .m-lg-8 {
    margin: 4.5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4.5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4.5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4.5rem !important; }
  .m-lg-9 {
    margin: 5.0625rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 5.0625rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 5.0625rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 5.0625rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 5.0625rem !important; }
  .m-lg-10 {
    margin: 5.625rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5.625rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5.625rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5.625rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5.625rem !important; }
  .m-lg-11 {
    margin: 6.1875rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 6.1875rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 6.1875rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 6.1875rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 6.1875rem !important; }
  .m-lg-12 {
    margin: 6.75rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6.75rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6.75rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6.75rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6.75rem !important; }
  .m-lg-13 {
    margin: 7.3125rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 7.3125rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 7.3125rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 7.3125rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 7.3125rem !important; }
  .m-lg-14 {
    margin: 7.875rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 7.875rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 7.875rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 7.875rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 7.875rem !important; }
  .m-lg-15 {
    margin: 8.4375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 8.4375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 8.4375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 8.4375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 8.4375rem !important; }
  .m-lg-16 {
    margin: 9rem !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 9rem !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 9rem !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 9rem !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 9rem !important; }
  .m-lg-17 {
    margin: 9.5625rem !important; }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 9.5625rem !important; }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 9.5625rem !important; }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 9.5625rem !important; }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 9.5625rem !important; }
  .m-lg-18 {
    margin: 10.125rem !important; }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 10.125rem !important; }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 10.125rem !important; }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 10.125rem !important; }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 10.125rem !important; }
  .m-lg-19 {
    margin: 10.6875rem !important; }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 10.6875rem !important; }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 10.6875rem !important; }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 10.6875rem !important; }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 10.6875rem !important; }
  .m-lg-20 {
    margin: 11.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 11.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 11.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 11.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 11.25rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5625rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5625rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5625rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5625rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5625rem !important; }
  .p-lg-2 {
    padding: 1.125rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.125rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.125rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.125rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.125rem !important; }
  .p-lg-3 {
    padding: 1.6875rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.6875rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.6875rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.6875rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.6875rem !important; }
  .p-lg-4 {
    padding: 2.25rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important; }
  .p-lg-5 {
    padding: 2.8125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.8125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.8125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.8125rem !important; }
  .p-lg-6 {
    padding: 3.375rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.375rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.375rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.375rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.375rem !important; }
  .p-lg-7 {
    padding: 3.9375rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.9375rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.9375rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.9375rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.9375rem !important; }
  .p-lg-8 {
    padding: 4.5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4.5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4.5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4.5rem !important; }
  .p-lg-9 {
    padding: 5.0625rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 5.0625rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 5.0625rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 5.0625rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 5.0625rem !important; }
  .p-lg-10 {
    padding: 5.625rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5.625rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5.625rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5.625rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5.625rem !important; }
  .p-lg-11 {
    padding: 6.1875rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 6.1875rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 6.1875rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 6.1875rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 6.1875rem !important; }
  .p-lg-12 {
    padding: 6.75rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6.75rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6.75rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6.75rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6.75rem !important; }
  .p-lg-13 {
    padding: 7.3125rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 7.3125rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 7.3125rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 7.3125rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 7.3125rem !important; }
  .p-lg-14 {
    padding: 7.875rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 7.875rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 7.875rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 7.875rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 7.875rem !important; }
  .p-lg-15 {
    padding: 8.4375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 8.4375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 8.4375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 8.4375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 8.4375rem !important; }
  .p-lg-16 {
    padding: 9rem !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 9rem !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 9rem !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 9rem !important; }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 9rem !important; }
  .p-lg-17 {
    padding: 9.5625rem !important; }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 9.5625rem !important; }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 9.5625rem !important; }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 9.5625rem !important; }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 9.5625rem !important; }
  .p-lg-18 {
    padding: 10.125rem !important; }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 10.125rem !important; }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 10.125rem !important; }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 10.125rem !important; }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 10.125rem !important; }
  .p-lg-19 {
    padding: 10.6875rem !important; }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 10.6875rem !important; }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 10.6875rem !important; }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 10.6875rem !important; }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 10.6875rem !important; }
  .p-lg-20 {
    padding: 11.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 11.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 11.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 11.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 11.25rem !important; }
  .m-lg-n1 {
    margin: -0.5625rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5625rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5625rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5625rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5625rem !important; }
  .m-lg-n2 {
    margin: -1.125rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1.125rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1.125rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1.125rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1.125rem !important; }
  .m-lg-n3 {
    margin: -1.6875rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.6875rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.6875rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.6875rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.6875rem !important; }
  .m-lg-n4 {
    margin: -2.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important; }
  .m-lg-n5 {
    margin: -2.8125rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2.8125rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2.8125rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2.8125rem !important; }
  .m-lg-n6 {
    margin: -3.375rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3.375rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3.375rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3.375rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3.375rem !important; }
  .m-lg-n7 {
    margin: -3.9375rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3.9375rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3.9375rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3.9375rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3.9375rem !important; }
  .m-lg-n8 {
    margin: -4.5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -4.5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -4.5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -4.5rem !important; }
  .m-lg-n9 {
    margin: -5.0625rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -5.0625rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -5.0625rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -5.0625rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -5.0625rem !important; }
  .m-lg-n10 {
    margin: -5.625rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -5.625rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -5.625rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -5.625rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -5.625rem !important; }
  .m-lg-n11 {
    margin: -6.1875rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -6.1875rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -6.1875rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -6.1875rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -6.1875rem !important; }
  .m-lg-n12 {
    margin: -6.75rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -6.75rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -6.75rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -6.75rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -6.75rem !important; }
  .m-lg-n13 {
    margin: -7.3125rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -7.3125rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -7.3125rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -7.3125rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -7.3125rem !important; }
  .m-lg-n14 {
    margin: -7.875rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -7.875rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -7.875rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -7.875rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -7.875rem !important; }
  .m-lg-n15 {
    margin: -8.4375rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -8.4375rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -8.4375rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -8.4375rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -8.4375rem !important; }
  .m-lg-n16 {
    margin: -9rem !important; }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -9rem !important; }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -9rem !important; }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -9rem !important; }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -9rem !important; }
  .m-lg-n17 {
    margin: -9.5625rem !important; }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -9.5625rem !important; }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -9.5625rem !important; }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -9.5625rem !important; }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -9.5625rem !important; }
  .m-lg-n18 {
    margin: -10.125rem !important; }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -10.125rem !important; }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -10.125rem !important; }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -10.125rem !important; }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -10.125rem !important; }
  .m-lg-n19 {
    margin: -10.6875rem !important; }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -10.6875rem !important; }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -10.6875rem !important; }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -10.6875rem !important; }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -10.6875rem !important; }
  .m-lg-n20 {
    margin: -11.25rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -11.25rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -11.25rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -11.25rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -11.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5625rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5625rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5625rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5625rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5625rem !important; }
  .m-xl-2 {
    margin: 1.125rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.125rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.125rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.125rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.125rem !important; }
  .m-xl-3 {
    margin: 1.6875rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.6875rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.6875rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.6875rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.6875rem !important; }
  .m-xl-4 {
    margin: 2.25rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important; }
  .m-xl-5 {
    margin: 2.8125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.8125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.8125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.8125rem !important; }
  .m-xl-6 {
    margin: 3.375rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3.375rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3.375rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3.375rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3.375rem !important; }
  .m-xl-7 {
    margin: 3.9375rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.9375rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.9375rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.9375rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.9375rem !important; }
  .m-xl-8 {
    margin: 4.5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4.5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4.5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4.5rem !important; }
  .m-xl-9 {
    margin: 5.0625rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 5.0625rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 5.0625rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 5.0625rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 5.0625rem !important; }
  .m-xl-10 {
    margin: 5.625rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5.625rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5.625rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5.625rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5.625rem !important; }
  .m-xl-11 {
    margin: 6.1875rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 6.1875rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 6.1875rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 6.1875rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 6.1875rem !important; }
  .m-xl-12 {
    margin: 6.75rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6.75rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6.75rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6.75rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6.75rem !important; }
  .m-xl-13 {
    margin: 7.3125rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 7.3125rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 7.3125rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 7.3125rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 7.3125rem !important; }
  .m-xl-14 {
    margin: 7.875rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 7.875rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 7.875rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 7.875rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 7.875rem !important; }
  .m-xl-15 {
    margin: 8.4375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 8.4375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 8.4375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 8.4375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 8.4375rem !important; }
  .m-xl-16 {
    margin: 9rem !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 9rem !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 9rem !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 9rem !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 9rem !important; }
  .m-xl-17 {
    margin: 9.5625rem !important; }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 9.5625rem !important; }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 9.5625rem !important; }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 9.5625rem !important; }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 9.5625rem !important; }
  .m-xl-18 {
    margin: 10.125rem !important; }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 10.125rem !important; }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 10.125rem !important; }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 10.125rem !important; }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 10.125rem !important; }
  .m-xl-19 {
    margin: 10.6875rem !important; }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 10.6875rem !important; }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 10.6875rem !important; }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 10.6875rem !important; }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 10.6875rem !important; }
  .m-xl-20 {
    margin: 11.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 11.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 11.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 11.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 11.25rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5625rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5625rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5625rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5625rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5625rem !important; }
  .p-xl-2 {
    padding: 1.125rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.125rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.125rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.125rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.125rem !important; }
  .p-xl-3 {
    padding: 1.6875rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.6875rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.6875rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.6875rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.6875rem !important; }
  .p-xl-4 {
    padding: 2.25rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important; }
  .p-xl-5 {
    padding: 2.8125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.8125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.8125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.8125rem !important; }
  .p-xl-6 {
    padding: 3.375rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3.375rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3.375rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3.375rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3.375rem !important; }
  .p-xl-7 {
    padding: 3.9375rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.9375rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.9375rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.9375rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.9375rem !important; }
  .p-xl-8 {
    padding: 4.5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4.5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4.5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4.5rem !important; }
  .p-xl-9 {
    padding: 5.0625rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 5.0625rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 5.0625rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 5.0625rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 5.0625rem !important; }
  .p-xl-10 {
    padding: 5.625rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5.625rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5.625rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5.625rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5.625rem !important; }
  .p-xl-11 {
    padding: 6.1875rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 6.1875rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 6.1875rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 6.1875rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 6.1875rem !important; }
  .p-xl-12 {
    padding: 6.75rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6.75rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6.75rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6.75rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6.75rem !important; }
  .p-xl-13 {
    padding: 7.3125rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 7.3125rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 7.3125rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 7.3125rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 7.3125rem !important; }
  .p-xl-14 {
    padding: 7.875rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 7.875rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 7.875rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 7.875rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 7.875rem !important; }
  .p-xl-15 {
    padding: 8.4375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 8.4375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 8.4375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 8.4375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 8.4375rem !important; }
  .p-xl-16 {
    padding: 9rem !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 9rem !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 9rem !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 9rem !important; }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 9rem !important; }
  .p-xl-17 {
    padding: 9.5625rem !important; }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 9.5625rem !important; }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 9.5625rem !important; }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 9.5625rem !important; }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 9.5625rem !important; }
  .p-xl-18 {
    padding: 10.125rem !important; }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 10.125rem !important; }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 10.125rem !important; }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 10.125rem !important; }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 10.125rem !important; }
  .p-xl-19 {
    padding: 10.6875rem !important; }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 10.6875rem !important; }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 10.6875rem !important; }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 10.6875rem !important; }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 10.6875rem !important; }
  .p-xl-20 {
    padding: 11.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 11.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 11.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 11.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 11.25rem !important; }
  .m-xl-n1 {
    margin: -0.5625rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5625rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5625rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5625rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5625rem !important; }
  .m-xl-n2 {
    margin: -1.125rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1.125rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1.125rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1.125rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1.125rem !important; }
  .m-xl-n3 {
    margin: -1.6875rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.6875rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.6875rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.6875rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.6875rem !important; }
  .m-xl-n4 {
    margin: -2.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important; }
  .m-xl-n5 {
    margin: -2.8125rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2.8125rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2.8125rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2.8125rem !important; }
  .m-xl-n6 {
    margin: -3.375rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3.375rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3.375rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3.375rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3.375rem !important; }
  .m-xl-n7 {
    margin: -3.9375rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3.9375rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3.9375rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3.9375rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3.9375rem !important; }
  .m-xl-n8 {
    margin: -4.5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -4.5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -4.5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -4.5rem !important; }
  .m-xl-n9 {
    margin: -5.0625rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -5.0625rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -5.0625rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -5.0625rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -5.0625rem !important; }
  .m-xl-n10 {
    margin: -5.625rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -5.625rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -5.625rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -5.625rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -5.625rem !important; }
  .m-xl-n11 {
    margin: -6.1875rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -6.1875rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -6.1875rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -6.1875rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -6.1875rem !important; }
  .m-xl-n12 {
    margin: -6.75rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -6.75rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -6.75rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -6.75rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -6.75rem !important; }
  .m-xl-n13 {
    margin: -7.3125rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -7.3125rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -7.3125rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -7.3125rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -7.3125rem !important; }
  .m-xl-n14 {
    margin: -7.875rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -7.875rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -7.875rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -7.875rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -7.875rem !important; }
  .m-xl-n15 {
    margin: -8.4375rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -8.4375rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -8.4375rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -8.4375rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -8.4375rem !important; }
  .m-xl-n16 {
    margin: -9rem !important; }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -9rem !important; }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -9rem !important; }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -9rem !important; }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -9rem !important; }
  .m-xl-n17 {
    margin: -9.5625rem !important; }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -9.5625rem !important; }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -9.5625rem !important; }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -9.5625rem !important; }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -9.5625rem !important; }
  .m-xl-n18 {
    margin: -10.125rem !important; }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -10.125rem !important; }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -10.125rem !important; }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -10.125rem !important; }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -10.125rem !important; }
  .m-xl-n19 {
    margin: -10.6875rem !important; }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -10.6875rem !important; }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -10.6875rem !important; }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -10.6875rem !important; }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -10.6875rem !important; }
  .m-xl-n20 {
    margin: -11.25rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -11.25rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -11.25rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -11.25rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -11.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate, .list-group-item .list-group-item-text, .list-group-item .list-group-item-text .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #244c5a !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0e1e23 !important; }

.text-secondary {
  color: #b8c9e1 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #82a0ca !important; }

.text-success {
  color: #55c443 !important; }

a.text-success:hover, a.text-success:focus {
  color: #3a8e2d !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ff6900 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b34a00 !important; }

.text-danger {
  color: #d60808 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #8c0505 !important; }

.text-light {
  color: #e1e1e1 !important; }

a.text-light:hover, a.text-light:focus {
  color: #bbbbbb !important; }

.text-dark {
  color: #43434c !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1f1f23 !important; }

.text-black {
  color: #343741 !important; }

a.text-black:hover, a.text-black:focus {
  color: #121317 !important; }

.text-light-grey {
  color: #ededed !important; }

a.text-light-grey:hover, a.text-light-grey:focus {
  color: #c7c7c7 !important; }

.text-slate-grey {
  color: #616167 !important; }

a.text-slate-grey:hover, a.text-slate-grey:focus {
  color: #3c3c40 !important; }

.text-slate-blue {
  color: #244c5a !important; }

a.text-slate-blue:hover, a.text-slate-blue:focus {
  color: #0e1e23 !important; }

.text-soft-blue {
  color: #b8c9e1 !important; }

a.text-soft-blue:hover, a.text-soft-blue:focus {
  color: #82a0ca !important; }

.text-warm-grey {
  color: #a2a2a2 !important; }

a.text-warm-grey:hover, a.text-warm-grey:focus {
  color: #7c7c7c !important; }

.text-body {
  color: #343741 !important; }

.text-muted {
  color: #616167 !important; }

.text-black-50 {
  color: rgba(52, 55, 65, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #343741; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e1e1e1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e1e1e1; } }

/**********************************************************
*****                      Mixins                     *****
**********************************************************/
.full-size-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.flex-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

/**********************************************************
*****                  CoreNet                        *****
**********************************************************/
.corenet {
  position: relative;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 767.98px) {
    .corenet {
      background-image: none !important; } }
  .corenet .form-control {
    height: 45px;
    line-height: 2.5; }
  .corenet .btn.bottom-action {
    line-height: 2.75; }
    .corenet .btn.bottom-action.bottom-action-small {
      line-height: 1.75; }
  .corenet .logoCorenet {
    margin-bottom: 2.8125rem;
    width: 130px;
    text-align: right; }
    .corenet .logoCorenet img {
      width: 130px; }
    @media (min-width: 992px) {
      .corenet .logoCorenet {
        position: absolute;
        margin-bottom: 0;
        right: 1.6875rem;
        bottom: 2.25rem; } }
    @media (min-width: 1200px) {
      .corenet .logoCorenet {
        width: 220px; }
        .corenet .logoCorenet img {
          width: 220px; } }

/**********************************************************
*****                  Utility classes                *****
**********************************************************/
.mh-0 {
  min-height: 0 !important; }

.pb-05 {
  padding-bottom: 0.28125rem; }

.pt-05 {
  padding-top: 0.28125rem; }

.pl-05 {
  padding-left: 0.28125rem; }

.pr-05 {
  padding-right: 0.28125rem; }

.flex-1 {
  flex: 1; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0;
  flex-grow: 1; }

.collapseTitle .CollapseArrow {
  width: 24px;
  height: 24px;
  opacity: 1 !important; }
  .collapseTitle .CollapseArrow svg {
    position: absolute;
    left: 0;
    top: 0; }

.collapseTitle .open {
  transform: rotate(90deg);
  transition: transform 200ms ease-in-out 0s; }
  .collapseTitle .open.CollapseArrow {
    transform: rotate(0deg); }

.collapseTitle .close {
  transform: rotate(-90deg);
  transition: transform 200ms ease-in-out 0s; }
  .collapseTitle .close.CollapseArrow {
    transform: rotate(-180deg); }

.collapseTitle .small b {
  font-weight: 500; }

.modal-dialog-centered {
  min-height: 0;
  max-height: calc(100% - (1.75rem * 2)); }

.bg-primary-opacity-6 {
  background-color: rgba(36, 76, 90, 0.6); }

.text-xs {
  font-size: 0.75rem; }

.text-sm {
  font-size: 0.875rem; }

.text-lg {
  font-size: 1.125rem; }

.text-xl {
  font-size: 1.2rem; }

.text-vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg); }

.text-none {
  text-transform: none !important; }

.text-prewrap {
  white-space: pre-wrap; }

.font-weight-light {
  font-weight: 200 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-medium {
  font-weight: 500 !important; }

.font-weight-semibold {
  font-weight: 600 !important; }

.btn-primary {
  color: #fff; }

.overflow-auto {
  overflow: auto !important; }

.align-item-center, .img, .list-group-item .list-group-item-image .img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.hover-no-decoration:hover {
  text-decoration: none; }

.wg-0 {
  width: 0; }

.hg-0 {
  height: 0; }

.wg-1 {
  width: 0.5625rem; }

.hg-1 {
  height: 0.5625rem; }

.wg-2 {
  width: 1.125rem; }

.hg-2 {
  height: 1.125rem; }

.wg-3 {
  width: 1.6875rem; }

.hg-3 {
  height: 1.6875rem; }

.wg-4 {
  width: 2.25rem; }

.hg-4 {
  height: 2.25rem; }

.wg-5 {
  width: 2.8125rem; }

.hg-5 {
  height: 2.8125rem; }

.wg-6 {
  width: 3.375rem; }

.hg-6 {
  height: 3.375rem; }

.wg-7 {
  width: 3.9375rem; }

.hg-7 {
  height: 3.9375rem; }

.wg-8 {
  width: 4.5rem; }

.hg-8 {
  height: 4.5rem; }

.wg-9 {
  width: 5.0625rem; }

.hg-9 {
  height: 5.0625rem; }

.wg-10 {
  width: 5.625rem; }

.hg-10 {
  height: 5.625rem; }

.wg-11 {
  width: 6.1875rem; }

.hg-11 {
  height: 6.1875rem; }

.wg-12 {
  width: 6.75rem; }

.hg-12 {
  height: 6.75rem; }

.wg-13 {
  width: 7.3125rem; }

.hg-13 {
  height: 7.3125rem; }

.wg-14 {
  width: 7.875rem; }

.hg-14 {
  height: 7.875rem; }

.wg-15 {
  width: 8.4375rem; }

.hg-15 {
  height: 8.4375rem; }

.wg-16 {
  width: 9rem; }

.hg-16 {
  height: 9rem; }

.wg-17 {
  width: 9.5625rem; }

.hg-17 {
  height: 9.5625rem; }

.wg-18 {
  width: 10.125rem; }

.hg-18 {
  height: 10.125rem; }

.wg-19 {
  width: 10.6875rem; }

.hg-19 {
  height: 10.6875rem; }

.wg-20 {
  width: 11.25rem; }

.hg-20 {
  height: 11.25rem; }

.bg-body {
  background-color: #ededed; }

.minh-50 {
  min-height: 50% !important; }

.minh-100 {
  min-height: 100% !important; }

.c-pointer {
  cursor: pointer; }

.divider-v {
  border-left: 1px solid #a2a2a2;
  height: 1.125rem; }

.border-4 {
  border-width: 0.28125rem !important; }

.nav-tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: none; }
  .nav-tabs .nav-item .nav-link {
    border: none;
    background-color: transparent;
    border-bottom: 3px solid transparent; }
    .nav-tabs .nav-item .nav-link:hover, .nav-tabs .nav-item .nav-link:focus, .nav-tabs .nav-item .nav-link.active {
      border-color: #244c5a; }
    .nav-tabs .nav-item .nav-link:invalid {
      border-color: #616167; }

.MuiSwitch-root {
  margin: 12px -12px 0 0; }

.h-separator {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap; }
  .h-separator span {
    position: relative;
    display: inline-block; }
    .h-separator span::after, .h-separator span::before {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: #a2a2a2; }
    .h-separator span::before {
      right: 100%;
      margin-right: 15px; }
    .h-separator span::after {
      left: 100%;
      margin-left: 15px; }

.payment-method {
  height: 364px;
  max-height: 364px; }

.corenetBanner a {
  display: inline-block;
  margin: 0 0.5625rem; }
  .corenetBanner a img {
    height: 3.9375rem; }

/**********************************************************
*****                Styling overrides                *****
**********************************************************/
.tox-statusbar__branding {
  display: none; }

dt {
  font-weight: 600; }

.modal-header .close,
.step-header .close,
.header .close {
  position: absolute;
  left: 1.125rem;
  padding: 0;
  margin: 0;
  letter-spacing: 0.0925em;
  font-weight: 500; }
  .modal-header .close:not(.modal-flow),
  .step-header .close:not(.modal-flow),
  .header .close:not(.modal-flow) {
    top: 1.125rem; }
  .modal-header .right.close,
  .step-header .right.close,
  .header .right.close {
    left: initial;
    right: 1.125rem; }
  @media (min-width: 768px) {
    .modal-header .close,
    .step-header .close,
    .header .close {
      left: 2.25rem; }
      .modal-header .close:not(.modal-flow),
      .step-header .close:not(.modal-flow),
      .header .close:not(.modal-flow) {
        top: 2.25rem; }
      .modal-header .right.close,
      .step-header .right.close,
      .header .right.close {
        left: initial;
        right: 2.25rem; } }

.bg-black .close,
.bg-dark .close,
.card-inverse .close,
.modal-dark .close,
.close.modal-dark {
  color: #fff;
  opacity: 1; }
  .bg-black .close:hover,
  .bg-dark .close:hover,
  .card-inverse .close:hover,
  .modal-dark .close:hover,
  .close.modal-dark:hover {
    opacity: 0.7; }

body {
  font-family: 'Alleyn', sans-serif;
  letter-spacing: 0.0375em;
  line-height: 1.36; }
  body.modal-open #base {
    filter: blur(0.25rem);
    transition: filter 0.3s ease-out; }

html,
body,
#root {
  height: 100%;
  padding: 0 !important; }

#root.fullscreen-view-open {
  overflow: hidden; }

img {
  object-fit: cover; }

ul.spaced li + li {
  margin-top: 0.5625rem; }

.btn {
  font-size: 0.875rem;
  font-weight: normal;
  letter-spacing: 0.0925em; }
  .btn.rounded-circle {
    width: 3em;
    text-align: center;
    padding: 0; }
  .btn span,
  .btn svg {
    vertical-align: middle; }

.btn.bottom-action {
  line-height: 4.75;
  border-radius: 0; }
  .btn.bottom-action.bottom-action-small {
    line-height: 3.75; }

.btn-light {
  color: #244c5a;
  border: none;
  background-color: #fff;
  font-weight: 500; }
  .btn-light:hover {
    color: #244c5a; }

.btn.modal-header-action {
  border-radius: 0;
  font-weight: 600;
  min-width: 320px;
  min-height: 64px; }

.badge {
  line-height: 1.2;
  letter-spacing: 0.5px; }

.form-control,
.form-control-like {
  line-height: 3.5;
  font-size: 0.875rem;
  border-radius: 0;
  height: calc(3.3em + 0.75rem + 2px);
  caret-color: #b8c9e1; }
  .form-control.focus,
  .form-control-like.focus {
    box-shadow: inset 0 0 0 1px #244c5a; }

textarea.form-control {
  line-height: 1.5em;
  min-height: 5.625rem; }

label,
input::placeholder,
.form-control::placeholder {
  font-size: 0.8125rem; }

input::placeholder,
.form-control::placeholder {
  color: #969696; }

label {
  width: 100%;
  position: relative;
  letter-spacing: normal;
  color: #616167;
  font-weight: 500; }
  label.required::after {
    content: '*';
    color: #b8c9e1;
    position: absolute;
    right: 0; }

.consentChecked {
  height: 50px; }

h1,
h2,
h3 {
  letter-spacing: 0.1em; }

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 100px #ededed inset !important;
  background-color: transparent;
  -webkit-text-fill-color: #616167;
  transition: none; }

input:invalid {
  -webkit-box-shadow: 0 0 0px 100px #ededed inset !important;
  background-color: transparent;
  -webkit-text-fill-color: #616167;
  transition: none; }

.form-control:focus, .form-control.focus,
.form-control-like:focus,
.form-control-like.focus {
  border-color: #b8c9e1;
  -webkit-box-shadow: none !important;
  box-shadow: none; }

.form-control:invalid,
.form-control-like:invalid {
  border-color: #ededed;
  -webkit-box-shadow: none !important;
  box-shadow: none; }

.bg-black,
.bg-dark,
.card-inverse,
.modal-dark {
  color: #fff; }
  .bg-black.bg-black, .bg-black.bg-dark, .bg-black.card-inverse,
  .bg-black .card-header,
  .bg-black .card-body,
  .bg-dark.bg-black,
  .bg-dark.bg-dark,
  .bg-dark.card-inverse,
  .bg-dark .card-header,
  .bg-dark .card-body,
  .card-inverse.bg-black,
  .card-inverse.bg-dark,
  .card-inverse.card-inverse,
  .card-inverse .card-header,
  .card-inverse .card-body,
  .modal-dark.bg-black,
  .modal-dark.bg-dark,
  .modal-dark.card-inverse,
  .modal-dark .card-header,
  .modal-dark .card-body {
    background-color: #343741;
    border-color: #343741; }
  .bg-black textarea:-webkit-autofill,
  .bg-black textarea:-webkit-autofill:hover,
  .bg-black textarea:-webkit-autofill:focus,
  .bg-black textarea:-webkit-autofill:active,
  .bg-black input:-webkit-autofill,
  .bg-black input:-webkit-autofill:hover,
  .bg-black input:-webkit-autofill:focus,
  .bg-black input:-webkit-autofill:active,
  .bg-dark textarea:-webkit-autofill,
  .bg-dark textarea:-webkit-autofill:hover,
  .bg-dark textarea:-webkit-autofill:focus,
  .bg-dark textarea:-webkit-autofill:active,
  .bg-dark input:-webkit-autofill,
  .bg-dark input:-webkit-autofill:hover,
  .bg-dark input:-webkit-autofill:focus,
  .bg-dark input:-webkit-autofill:active,
  .card-inverse textarea:-webkit-autofill,
  .card-inverse textarea:-webkit-autofill:hover,
  .card-inverse textarea:-webkit-autofill:focus,
  .card-inverse textarea:-webkit-autofill:active,
  .card-inverse input:-webkit-autofill,
  .card-inverse input:-webkit-autofill:hover,
  .card-inverse input:-webkit-autofill:focus,
  .card-inverse input:-webkit-autofill:active,
  .modal-dark textarea:-webkit-autofill,
  .modal-dark textarea:-webkit-autofill:hover,
  .modal-dark textarea:-webkit-autofill:focus,
  .modal-dark textarea:-webkit-autofill:active,
  .modal-dark input:-webkit-autofill,
  .modal-dark input:-webkit-autofill:hover,
  .modal-dark input:-webkit-autofill:focus,
  .modal-dark input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 100px #616167 inset !important;
    background-color: transparent;
    -webkit-text-fill-color: #ededed;
    transition: none; }
  .bg-black input:invalid,
  .bg-black textarea:invalid,
  .bg-dark input:invalid,
  .bg-dark textarea:invalid,
  .card-inverse input:invalid,
  .card-inverse textarea:invalid,
  .modal-dark input:invalid,
  .modal-dark textarea:invalid {
    -webkit-box-shadow: 0 0 0px 100px #616167 inset !important;
    background-color: transparent;
    -webkit-text-fill-color: #ededed;
    transition: none; }
  .bg-black .form-control:focus, .bg-black .form-control.focus,
  .bg-black .form-control-like:focus,
  .bg-black .form-control-like.focus,
  .bg-dark .form-control:focus,
  .bg-dark .form-control.focus,
  .bg-dark .form-control-like:focus,
  .bg-dark .form-control-like.focus,
  .card-inverse .form-control:focus,
  .card-inverse .form-control.focus,
  .card-inverse .form-control-like:focus,
  .card-inverse .form-control-like.focus,
  .modal-dark .form-control:focus,
  .modal-dark .form-control.focus,
  .modal-dark .form-control-like:focus,
  .modal-dark .form-control-like.focus {
    border-color: #b8c9e1; }
  .bg-black .form-control:invalid,
  .bg-black .form-control-like:invalid,
  .bg-dark .form-control:invalid,
  .bg-dark .form-control-like:invalid,
  .card-inverse .form-control:invalid,
  .card-inverse .form-control-like:invalid,
  .modal-dark .form-control:invalid,
  .modal-dark .form-control-like:invalid {
    border-color: #616167; }
  .bg-black .close.disabled,
  .bg-dark .close.disabled,
  .card-inverse .close.disabled,
  .modal-dark .close.disabled {
    opacity: 0.5; }
  .bg-black h1,
  .bg-black h2,
  .bg-black h3,
  .bg-black h4,
  .bg-black h5,
  .bg-black h6,
  .bg-dark h1,
  .bg-dark h2,
  .bg-dark h3,
  .bg-dark h4,
  .bg-dark h5,
  .bg-dark h6,
  .card-inverse h1,
  .card-inverse h2,
  .card-inverse h3,
  .card-inverse h4,
  .card-inverse h5,
  .card-inverse h6,
  .modal-dark h1,
  .modal-dark h2,
  .modal-dark h3,
  .modal-dark h4,
  .modal-dark h5,
  .modal-dark h6 {
    font-weight: 500;
    margin-bottom: 1rem; }
  .bg-black > .card-body + .card-body,
  .bg-black > .card-body + .card-header,
  .bg-black > .card-header + .card-body,
  .bg-black > .card-header + .card-header,
  .bg-dark > .card-body + .card-body,
  .bg-dark > .card-body + .card-header,
  .bg-dark > .card-header + .card-body,
  .bg-dark > .card-header + .card-header,
  .card-inverse > .card-body + .card-body,
  .card-inverse > .card-body + .card-header,
  .card-inverse > .card-header + .card-body,
  .card-inverse > .card-header + .card-header,
  .modal-dark > .card-body + .card-body,
  .modal-dark > .card-body + .card-header,
  .modal-dark > .card-header + .card-body,
  .modal-dark > .card-header + .card-header {
    padding-top: 0.9375rem; }
  .bg-black .consent a,
  .bg-dark .consent a,
  .card-inverse .consent a,
  .modal-dark .consent a {
    color: white;
    font-weight: bold; }
  .bg-black .consent.checked .consent-check,
  .bg-dark .consent.checked .consent-check,
  .card-inverse .consent.checked .consent-check,
  .modal-dark .consent.checked .consent-check {
    border-color: #244c5a; }
  .bg-black .list-group-item .list-group-item-text .title,
  .bg-dark .list-group-item .list-group-item-text .title,
  .card-inverse .list-group-item .list-group-item-text .title,
  .modal-dark .list-group-item .list-group-item-text .title {
    color: #fff; }
  .bg-black .input-group,
  .bg-black .form-control,
  .bg-black .consent,
  .bg-dark .input-group,
  .bg-dark .form-control,
  .bg-dark .consent,
  .card-inverse .input-group,
  .card-inverse .form-control,
  .card-inverse .consent,
  .modal-dark .input-group,
  .modal-dark .form-control,
  .modal-dark .consent {
    background-color: #616167;
    color: #ededed;
    border: 1px solid #616167;
    transition: none; }
  .bg-black .invitationEmail input:-webkit-autofill:hover,
  .bg-black .invitationEmail input:-webkit-autofill:focus,
  .bg-black .invitationEmail input:-webkit-autofill:active,
  .bg-black .invitationEmail .form-control,
  .bg-dark .invitationEmail input:-webkit-autofill:hover,
  .bg-dark .invitationEmail input:-webkit-autofill:focus,
  .bg-dark .invitationEmail input:-webkit-autofill:active,
  .bg-dark .invitationEmail .form-control,
  .card-inverse .invitationEmail input:-webkit-autofill:hover,
  .card-inverse .invitationEmail input:-webkit-autofill:focus,
  .card-inverse .invitationEmail input:-webkit-autofill:active,
  .card-inverse .invitationEmail .form-control,
  .modal-dark .invitationEmail input:-webkit-autofill:hover,
  .modal-dark .invitationEmail input:-webkit-autofill:focus,
  .modal-dark .invitationEmail input:-webkit-autofill:active,
  .modal-dark .invitationEmail .form-control {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0px 100px #343741 inset !important;
    -webkit-text-fill-color: #ededed; }
    .bg-black .invitationEmail input:-webkit-autofill:hover:focus,
    .bg-black .invitationEmail input:-webkit-autofill:focus:focus,
    .bg-black .invitationEmail input:-webkit-autofill:active:focus,
    .bg-black .invitationEmail .form-control:focus,
    .bg-dark .invitationEmail input:-webkit-autofill:hover:focus,
    .bg-dark .invitationEmail input:-webkit-autofill:focus:focus,
    .bg-dark .invitationEmail input:-webkit-autofill:active:focus,
    .bg-dark .invitationEmail .form-control:focus,
    .card-inverse .invitationEmail input:-webkit-autofill:hover:focus,
    .card-inverse .invitationEmail input:-webkit-autofill:focus:focus,
    .card-inverse .invitationEmail input:-webkit-autofill:active:focus,
    .card-inverse .invitationEmail .form-control:focus,
    .modal-dark .invitationEmail input:-webkit-autofill:hover:focus,
    .modal-dark .invitationEmail input:-webkit-autofill:focus:focus,
    .modal-dark .invitationEmail input:-webkit-autofill:active:focus,
    .modal-dark .invitationEmail .form-control:focus {
      box-shadow: none; }
  .bg-black .text-danger,
  .bg-dark .text-danger,
  .card-inverse .text-danger,
  .modal-dark .text-danger {
    color: #f73333 !important; }
  .bg-black label,
  .bg-dark label,
  .card-inverse label,
  .modal-dark label {
    color: #ededed; }

.modal-header .close,
.step-header .close,
.header .close {
  z-index: 5; }

.modal .modal-dark .modal-header,
.modal .modal-dark .modal-body {
  background-color: #43434c;
  color: #fff; }

.modal .modal-header {
  display: block; }
  .modal .modal-header .img.rounded-circle {
    width: 100%;
    height: 100%; }
  .modal .modal-header .modal-title {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 6px;
    margin-top: 3rem-1rem 1rem;
    margin-bottom: 2.5rem-1rem 1rem; }
    @media (min-width: 768px) {
      .modal .modal-header .modal-title {
        font-size: 2.4375rem; } }
  .modal .modal-header .modal-title-small {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.6px; }
  .modal .modal-header.bg-black, .modal .modal-header.bg-dark {
    color: #fff; }

.modal .modal-body {
  min-height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column; }
  .modal .modal-body.two-panes {
    flex-direction: row; }

.modal .modal-content {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2); }

.modal .modal-dialog.bg-transparent {
  color: #fff; }
  .modal .modal-dialog.bg-transparent .modal-content {
    background-color: transparent !important;
    box-shadow: none; }

.modal .modal-profile .modal-body {
  padding: 30px; }
  .modal .modal-profile .modal-body .header .img.rounded-circle {
    width: 100%;
    height: 100%; }

.modal .modal-profile .close {
  position: absolute;
  top: 16px;
  right: 17px; }

.modal-xl {
  max-width: 95%; }

.modal-full-screen {
  height: calc(100% - (1.75rem * 2)); }
  .modal-full-screen .modal-content {
    height: 100%;
    max-height: 100%; }
  .modal-full-screen.adaptive .modal-content {
    height: auto; }

.nav-link {
  min-width: 11.25rem;
  text-align: center;
  color: #343741;
  border: none;
  background: none;
  cursor: pointer; }
  .nav-link * {
    vertical-align: middle; }
  .nav-link:hover {
    color: #244c5a; }
  .nav-link:focus {
    outline: none; }

.nav-link.active {
  border-bottom: solid 2px #244c5a; }

.dropdown-toggle::after {
  margin-left: 1.125rem;
  border-top-color: #244c5a; }

select.form-control:not([size]):not([multiple]) {
  height: calc(3.5em + 0.75rem); }

/**********************************************************
*****              Media form control                 *****
**********************************************************/
.media-form-control {
  width: 8.125rem;
  height: 8.125rem;
  margin: auto;
  position: relative; }
  .media-form-control input[type='file'] {
    width: 0;
    height: 0;
    padding: 0 !important;
    opacity: 0;
    position: absolute; }
  .media-form-control .bg,
  .media-form-control img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden; }
  .media-form-control .bg {
    padding: 1px; }
  .media-form-control label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 50%;
    top: 50%;
    width: 46%;
    height: 46%;
    transform: translate(-50%, -50%);
    overflow: hidden; }

/**********************************************************
*****                    Loading                      *****
**********************************************************/
.loading-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(52, 55, 65, 0.5);
  align-items: center;
  justify-content: center; }
  .loading-container.global {
    z-index: 10000001;
    position: fixed; }
  .loading-container.loading {
    display: flex;
    animation: 1s fade-in; }

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/**********************************************************
*****                     Toast                       *****
**********************************************************/
.toast-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999; }

.cg-toast {
  animation: 350ms slide-in;
  background: rgba(36, 76, 90, 0.9); }
  .cg-toast.closing {
    animation: 350ms slide-out; }

@keyframes slide-in {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0%); } }

@keyframes slide-out {
  0% {
    transform: translateY(0%); }
  100% {
    transform: translateY(-100%); } }

/**********************************************************
*****                 Select lists                    *****
**********************************************************/
.img {
  background-color: #e1e1e1;
  color: #fff; }

.list-group-header {
  padding: 1.125rem;
  padding-bottom: 0.5625rem;
  background-color: #fff; }
  .list-group-header .super-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1px; }
  .list-group-header .title {
    text-transform: uppercase;
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 2.2px;
    padding-bottom: 0.5625rem;
    border-bottom: 1px solid #b8c9e1; }
  .list-group-header + .list-group-item {
    border-top: none; }

.roomList .list-group .list-group-item-image {
  align-self: flex-start; }

.roomList .timeSlot {
  font-size: 1.6rem;
  border-bottom: 2px solid #244c5a; }

.isPrivateIcon {
  right: 0; }

.bookingDetail .bookingTitle::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(52, 55, 65, 0), rgba(52, 55, 65, 0.71)); }

.bookingDetail .bookingTitle div {
  z-index: 2; }

.bookingDetail .bookingTitle .action-btn {
  z-index: 10; }

.bookingDetail .bookingTitle .roomPicture {
  object-fit: cover;
  object-position: center;
  z-index: 0; }

.bookingDetail .recurringBookingInfo {
  min-height: auto;
  border-bottom: 0; }

.title-list-expertises {
  font-weight: 500;
  color: #616167; }

.expanded-list {
  max-height: 100vh;
  overflow: auto; }

.list-group-item {
  border-top: 0.14063rem solid transparent;
  border-bottom: 0.14063rem solid transparent;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5625rem 1.125rem;
  min-height: 5.625rem; }
  .list-group-item * {
    /* Useful to support hovers */
    z-index: 0; }
  .list-group-item:not(.list-settings):not(.list-more):first-child {
    border-top: 0; }
  .list-group-item:not(.list-item-subscription):not(.list-settings):not(.list-more):last-child {
    border-bottom: 0; }
  .list-group-item.list-group-item-spaced {
    border-top-width: 0.28125rem;
    border-bottom-width: 0.28125rem; }
  .list-group-item:not(.list-group-item-action):disabled, .list-group-item:not(.list-group-item-action).disabled {
    opacity: 0.7; }
    .list-group-item:not(.list-group-item-action):disabled .list-group-item-status, .list-group-item:not(.list-group-item-action).disabled .list-group-item-status {
      display: none; }
    .list-group-item:not(.list-group-item-action):disabled.time-slot-item, .list-group-item:not(.list-group-item-action).disabled.time-slot-item {
      opacity: 1;
      background-color: rgba(162, 162, 162, 0.2); }
      .list-group-item:not(.list-group-item-action):disabled.time-slot-item .super-title, .list-group-item:not(.list-group-item-action).disabled.time-slot-item .super-title {
        opacity: 0.5; }
  .list-group-item.list-group-item-action {
    width: auto; }
  .list-group-item .list-group-item-image img,
  .list-group-item .list-group-item-image .img {
    border-radius: 50%; }
    .list-group-item .list-group-item-image img:not([width]),
    .list-group-item .list-group-item-image .img:not([width]) {
      width: 3.375rem; }
    .list-group-item .list-group-item-image img:not([height]),
    .list-group-item .list-group-item-image .img:not([height]) {
      height: 3.375rem; }
  .list-group-item .list-group-item-image .img {
    background-color: #e1e1e1;
    color: #fff; }
  .list-group-item .list-group-item-image img {
    object-fit: cover; }
  .list-group-item .list-group-item-image svg {
    display: block; }
    .list-group-item .list-group-item-image svg.pendingMemberIcon {
      width: 54px; }
  .list-group-item.list-settings.list-group-item-action .list-group-item-image img,
  .list-group-item.list-settings.list-group-item-action .list-group-item-image .img {
    height: 2.5rem;
    width: 2.5rem; }
  .list-group-item.list-expertises.list-group-item-action .list-group-item-image img,
  .list-group-item.list-expertises.list-group-item-action .list-group-item-image .img {
    filter: invert(67%) sepia(2%) saturate(34%) hue-rotate(353deg) brightness(98%) contrast(87%); }
  .list-group-item.list-expertises.granted .list-group-item-image img,
  .list-group-item.list-expertises.granted .list-group-item-image .img {
    filter: invert(27%) sepia(8%) saturate(2609%) hue-rotate(150deg) brightness(92%) contrast(96%); }
  .list-group-item.conflict-list-item {
    flex-wrap: wrap;
    cursor: auto !important; }
    .list-group-item.conflict-list-item .list-group-item-text {
      flex: 1 1 80%; }
    .list-group-item.conflict-list-item .action {
      flex: 1 1 20%; }
    .list-group-item.conflict-list-item .conflict-info {
      display: flex;
      flex: 1 1 100%;
      margin-top: 2px; }
      .list-group-item.conflict-list-item .conflict-info .amenity-info {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
      .list-group-item.conflict-list-item .conflict-info .img {
        width: 2rem;
        height: 2rem; }
      .list-group-item.conflict-list-item .conflict-info .conflict-reason {
        flex-grow: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.625rem; }
  .list-group-item.recurring_personal > .list-group-item-status {
    align-self: flex-start;
    margin-top: 1.125rem; }
  .list-group-item.recurring_personal > .list-group-item-text {
    overflow: visible; }
    .list-group-item.recurring_personal > .list-group-item-text.activeItem {
      margin-top: 1.125rem; }
      .list-group-item.recurring_personal > .list-group-item-text.activeItem .dropdownOccurrence {
        display: block; }
    .list-group-item.recurring_personal > .list-group-item-text .dropdownOccurrence {
      display: none;
      width: 11.25rem; }
      .list-group-item.recurring_personal > .list-group-item-text .dropdownOccurrence select {
        display: block;
        height: auto;
        line-height: 2.5rem; }
      .list-group-item.recurring_personal > .list-group-item-text .dropdownOccurrence button {
        background-color: #ededed;
        color: #a2a2a2;
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
        border: 0; }
        .list-group-item.recurring_personal > .list-group-item-text .dropdownOccurrence button:focus {
          border: 0;
          box-shadow: none; }
  .list-group-item .list-group-item-text {
    width: 0;
    flex-grow: 1;
    position: relative;
    z-index: 1; }
    .list-group-item .list-group-item-text .title,
    .list-group-item .list-group-item-text .super-title,
    .list-group-item .list-group-item-text .sub-title {
      display: block; }
    .list-group-item .list-group-item-text .title {
      font-weight: bold;
      color: #43434c;
      max-width: 100%; }
    .list-group-item .list-group-item-text .sub-title {
      font-weight: 500;
      font-size: 0.8125rem;
      letter-spacing: 0.4px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 2px; }
      .list-group-item .list-group-item-text .sub-title .sub-title-email {
        padding-top: 0.28125rem; }
  .list-group-item .list-group-item-status {
    margin-left: auto; }
    .list-group-item .list-group-item-status svg {
      vertical-align: middle; }
  .list-group-item .list-group-item-link {
    min-height: 90px;
    min-width: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0; }

.profile-list-item .list-scrollable {
  max-height: 18.675rem;
  overflow: auto; }

.profile-list-item .list-group-item,
.profile-list-item .list-group-item-link {
  min-height: 50px;
  min-width: 50px;
  border-top: 0; }

.profile-list-item .linkProfile .list-group-item {
  border-left: 2px solid #ededed;
  border-bottom: 0; }
  .profile-list-item .linkProfile .list-group-item:first-child {
    border-left: none; }

.list-group-item-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #343741;
  opacity: 0.36;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0; }

.list-group-item-action.shaded .list-group-item-hover {
  opacity: 0.73; }

.list-group-item-action:not(.disabled) {
  cursor: pointer; }
  .list-group-item-action:not(.disabled):hover .list-group-item-hover {
    opacity: 0.85; }

.list-group-item-action.inactive {
  opacity: 0.7; }
  .list-group-item-action.inactive:hover {
    opacity: 0.9; }

.list-group-item-action.disabled .list-group-item-hover {
  opacity: 0.9; }

.image-list-group-item {
  flex-direction: column;
  background-position: center;
  background-size: cover;
  align-items: flex-start;
  min-height: 10.125rem; }
  .image-list-group-item .list-group-item-text {
    flex-grow: 0;
    width: auto; }
  .image-list-group-item:focus {
    color: #e6e6e6; }
  .image-list-group-item, .image-list-group-item:hover {
    color: #fff; }
  .image-list-group-item:disabled, .image-list-group-item.disabled {
    color: #a2a2a2; }

.list-group.large .list-group-item {
  min-height: 16.3125rem; }
  .list-group.large .list-group-item.bordered {
    min-height: 16.6875rem;
    border-bottom: solid 0.375rem #b8c9e1; }

.list-group.list-group-horizontal {
  flex-direction: row;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .list-group.list-group-horizontal .list-group-item:not(.image-list-group-item) {
    min-height: initial; }
  .list-group.list-group-horizontal .list-group-item {
    min-width: 24.75rem;
    padding: 1.125rem;
    margin: 0 0.5625rem; }
    .list-group.list-group-horizontal .list-group-item:first-child {
      margin-left: 0; }
    .list-group.list-group-horizontal .list-group-item:last-child {
      margin-right: 0; }
  .list-group.list-group-horizontal .list-group-item-image img {
    width: 2em;
    height: 2em; }

.dropdown-list .multi-select-dropdown-items {
  width: inherit; }

.dropdown-grey-section .list-group-item .list-group-item-text .title {
  color: #343741; }

.multi-select-dropdown.campus {
  position: absolute;
  right: 0;
  top: 0.5625rem; }
  .multi-select-dropdown.campus .multi-select-dropdown-items {
    width: 100%;
    left: 13%;
    transform: translateX(-13%); }

.multi-select-dropdown .form-control-like {
  line-height: 1.7em;
  padding: 0px 3px 3px 10px;
  height: 35px;
  max-width: 200px;
  border-radius: 35px; }

.multi-select-dropdown .list-group-item.default {
  padding: 1.125rem;
  margin-bottom: 0.28125rem; }

.list-group-compact .list-group-item,
.list-group-compact .list-group-item.default {
  padding: 0.5625rem;
  min-height: 4.5rem; }

.list-group-compact.email-invitation {
  overflow: auto;
  max-height: 9rem; }
  .list-group-compact.email-invitation .list-group-item,
  .list-group-compact.email-invitation .list-group-item.default {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    min-height: 3.375rem; }

.list-group-very-compact .form-control {
  line-height: 1.8rem;
  height: calc(1.8em + 0.75rem + 2px); }

.list-group-very-compact .list-group-item,
.list-group-very-compact .list-group-item.default {
  padding: 0.28125rem 0.5625rem;
  min-height: 4.21875rem; }

.list-group-very-compact .btn-birthday {
  padding-top: 0; }

.multi-select-dropdown-items {
  position: absolute;
  z-index: 3;
  background-color: #ededed;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  min-width: 16.3125rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .multi-select-dropdown-items.open {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 30vh;
    animation: open 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }

@keyframes open {
  0% {
    overflow: hidden; }
  50% {
    overflow: hidden; }
  100% {
    overflow: auto; } }

.multi-select-dropdown-icon.open {
  transform: scaleY(-1); }

.dropdown-indicator {
  transform: rotate(0deg);
  transition: transform 200ms ease-in-out 0s; }
  .dropdown-indicator.open {
    transform: rotate(180deg); }

/**********************************************************
*****              Wide modal with panes              *****
**********************************************************/
.two-panes {
  position: relative; }

.action-pane {
  width: 100%; }

.info-pane,
.info-pane + .action-pane {
  width: 50%; }

.action-pane {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  z-index: 2; }
  .action-pane.bg-dark {
    color: #fff; }
  .action-pane .content {
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; }
  .action-pane .btn.bottom-action {
    flex-shrink: 0; }

.info-pane {
  background: no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-weight: 600; }
  .info-pane::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
    background: rgba(52, 55, 65, 0.73);
    z-index: 1; }
  .info-pane .contents {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .info-pane h1 {
    font-weight: 600;
    letter-spacing: 15.3px;
    text-align: center; }
  .info-pane .sub-title {
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1rem; }
    @media (min-width: 1200px) {
      .info-pane .sub-title {
        font-size: 1.25rem; } }
  .info-pane .title.confirmation {
    font-size: 1.75rem; }
  @media (min-width: 1200px) {
    .info-pane .title {
      font-size: 3.5rem; } }

.steps {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center; }
  .steps a,
  .steps span {
    color: #a2a2a2;
    font-size: 0.5625rem;
    text-align: center;
    letter-spacing: 0.4px; }
    .steps a.completed,
    .steps span.completed {
      color: #fff; }
    .steps a.active,
    .steps span.active {
      color: white; }
      .steps a.active svg,
      .steps span.active svg {
        color: #b8c9e1; }

.step {
  display: inline-block;
  margin-left: 1.40625rem;
  margin-right: 1.40625rem; }

.dot-steps {
  display: flex;
  align-items: center;
  justify-content: center; }
  .dot-steps span {
    display: flex;
    align-items: center;
    color: #a2a2a2; }
    .dot-steps span.completed {
      color: #b8c9e1; }
    .dot-steps span.active {
      color: #fff; }
  .dot-steps .step-separator {
    width: 1.6875rem;
    display: inline;
    border-top: 1px solid #a2a2a2; }
    .dot-steps .step-separator.completed {
      border-top-color: #b8c9e1; }
  .dot-steps span,
  .dot-steps .step-separator {
    margin-left: 0.28125rem;
    margin-right: 0.28125rem; }

/**********************************************************
*****             Rounded picture list              *****
**********************************************************/
.rounded-picture-list {
  list-style-type: none;
  padding-left: 0; }
  .rounded-picture-list .rounded-picture-list-item {
    display: inline-block;
    margin-left: 0.28125rem;
    margin-right: 0.28125rem; }
    .rounded-picture-list .rounded-picture-list-item:first-child {
      margin-left: 0; }
    .rounded-picture-list .rounded-picture-list-item:last-child {
      margin-right: 0; }
    .rounded-picture-list .rounded-picture-list-item .isPrivateIcon {
      right: -5px;
      top: -4px; }
    .rounded-picture-list .rounded-picture-list-item img,
    .rounded-picture-list .rounded-picture-list-item .img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%; }
  .rounded-picture-list .picture-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.875rem;
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
    text-align: center;
    color: #fff; }

/**********************************************************
*****               Notification badge                *****
**********************************************************/
.notification-badge {
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  z-index: 2; }
  .notification-badge.top-left {
    left: 0;
    top: 0; }
  .notification-badge.top-right {
    right: 0;
    top: 0; }
  .notification-badge.bottom-right {
    right: 0;
    bottom: 0; }
  .notification-badge.bottom-left {
    left: 0;
    bottom: 0; }
  .notification-badge.navigation-badge {
    left: -4px;
    top: -3px; }

.notification-badge-wrapper {
  position: relative;
  display: inline-block;
  line-height: 1; }

/**********************************************************
*****               Action result page                *****
**********************************************************/
.action-result-page {
  position: relative;
  width: 100%;
  min-height: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .action-result-page .grey-section {
    background-color: #43434c;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 32%;
    z-index: 1; }
  .action-result-page .blue-section {
    background-color: #244c5a;
    width: 100%;
    position: absolute;
    top: 68%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  .action-result-page .content {
    width: 95%;
    max-width: 790px;
    position: relative;
    z-index: 2; }
    .action-result-page .content.component {
      max-width: 95vw; }
  .action-result-page .result {
    background-color: #fff;
    border-radius: 2px;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center; }
    @media (min-width: 768px) {
      .action-result-page .result {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .action-result-page .result {
        font-size: 2.25rem; } }

/**********************************************************
*****                 View specific                   *****
**********************************************************/
#payment-request-button {
  background-color: #43434c; }

#base {
  min-height: 100%;
  overflow: auto; }
  @media (max-width: 900px), (max-height: 500px) {
    #base {
      display: none; } }
  #base.anonymous {
    background-color: #43434c;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

#login p {
  font-weight: 500; }

#login .forgot-password {
  font-size: 0.75rem; }

.member-invitation {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1; }
  .member-invitation .form-control:not(.btn) {
    background-color: #fff; }
  .member-invitation .input-group-text {
    background-color: #fff;
    border-radius: 0; }
  .member-invitation .input-group-prepend .input-group-text {
    border-right: none; }
  .member-invitation .input-group-prepend ~ .form-control {
    border: 1px solid white;
    border-left: none; }
  .member-invitation .member-list {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    height: 0; }
  .member-invitation .addEmail span {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px; }
  .member-invitation .addEmail input {
    border-top-right-radius: 25px;
    padding-left: 0;
    border-bottom-right-radius: 25px;
    line-height: 1.5;
    flex: none;
    max-width: 90%; }

.invitation-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column; }
  .invitation-container .member-invitation {
    height: 0;
    flex-grow: 1; }

.explanations-step p {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.6px; }

.consent {
  display: flex;
  padding: 0.5625rem 1.125rem;
  cursor: pointer;
  font-size: 0.8125rem;
  align-items: center; }
  .consent .consent-check {
    flex-shrink: 0;
    border-radius: 50%;
    color: #a2a2a2;
    border: 2px solid #a2a2a2;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7; }
  .consent.checked.checked .consent-check {
    background-color: #244c5a;
    color: #fff;
    border-color: #244c5a;
    opacity: 1; }
  .consent .consent-text {
    padding-left: 1.125rem; }

.modal-flow-title {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  justify-content: center; }

.btn-link:not(:disabled):not(.disabled).active, .btn-link:not(:disabled):not(.disabled):active {
  color: #204451; }

.step-header {
  display: flex;
  justify-content: center;
  position: relative; }
  .step-header .close {
    left: 1.6875rem; }
    .step-header .close:not(.modal-flow) {
      top: 1.6875rem; }
  .step-header p {
    flex-grow: 1; }

.subscription-statuses {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 1.125rem; }

.simple-modal-icon img {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%; }

.list-item-spacer {
  margin: 0;
  border-top: 1px #ededed solid; }

.no-match {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000002; }
  .no-match h1 {
    font-size: 2rem; }
  .no-match ._404 {
    font-size: 10.5vh;
    font-weight: 600; }
  @media (min-width: 768px) {
    .no-match h1 {
      font-size: 2.5rem; }
    .no-match ._404 {
      font-size: 10.5vw; } }

.mobile-blocker {
  display: none !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000003; }
  .mobile-blocker .intro-message {
    line-height: 2; }

@media (max-width: 900px), (max-height: 500px) {
  .mobile-blocker {
    display: flex !important; } }

#message-list {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

#infinite-list {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.message-body {
  min-height: 220px;
  font-size: 0.8125rem;
  font-weight: 500; }
  .message-body h5,
  .message-body h6 {
    font-weight: bold; }

.message-actions {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 2; }

.list-group-item-action:hover, .list-group-item-action:focus, .list-group-item-action.active-item {
  background-color: rgba(36, 76, 90, 0.15);
  box-shadow: inset 0 0 5px -5px rgba(0, 0, 0, 0.75); }

.subscription-router {
  height: 600px;
  position: relative; }

.settings-menu {
  border-right: 1px solid #a2a2a2; }

.profile-link {
  text-decoration: none;
  color: #fff; }
  .profile-link:hover {
    text-decoration: none; }
  .profile-link .btn {
    padding: 0; }
  .profile-link .dropdown-menu.show {
    margin-top: 0.5625rem;
    margin-left: 0.5625rem;
    width: 16.3125rem; }
    .profile-link .dropdown-menu.show .dropdown-item {
      margin: 0.5625rem 0;
      min-height: 48px; }
      .profile-link .dropdown-menu.show .dropdown-item:hover {
        background-color: rgba(0, 0, 0, 0.08); }
  .profile-link .dropdown-item:hover,
  .profile-link .dropdown-item:focus {
    color: #43434c; }
  .profile-link .dropdown-toggle {
    display: flex;
    align-items: center;
    color: #fff;
    width: 17.4375rem; }
    .profile-link .dropdown-toggle:focus {
      box-shadow: none;
      border-color: transparent; }
  .profile-link .dropdown-toggle::after {
    vertical-align: middle;
    margin-right: 1.125rem;
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-left: 0.5em solid transparent; }
  .profile-link .profile-image {
    width: 50px;
    height: 50px; }

.header-col {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.segment-header {
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.segment-body {
  min-height: 296px; }

.segment-top-action {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.break-words {
  hyphens: auto;
  word-wrap: break-word; }

.time-slot-info {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(68, 68, 68, 0.6);
  position: relative;
  z-index: 2; }
  .time-slot-info hr {
    margin-top: 0.28125rem;
    margin-bottom: 0; }

.time-slot-date-selection {
  display: flex;
  flex-direction: row;
  overflow-x: auto; }

.time-slot-date {
  min-height: 100px;
  min-width: 60px;
  cursor: pointer;
  color: #a2a2a2;
  background-color: #43434c;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase; }
  .time-slot-date .week-day,
  .time-slot-date .month {
    font-size: 0.75rem;
    font-weight: 500; }
  .time-slot-date .day {
    font-size: 1.5rem;
    font-weight: bold; }
  .time-slot-date:hover, .time-slot-date:focus {
    background-color: #5b5b67;
    color: #fff; }
  .time-slot-date.selected {
    background-color: #244c5a;
    color: #fff; }

.filter-button {
  min-width: 90px;
  color: #fff;
  font-weight: normal;
  border-radius: 50px;
  line-height: 1.55;
  margin-right: 0.5625rem; }
  .filter-button:last-child {
    margin-right: 0; }

.filters {
  display: flex;
  align-items: center;
  justify-content: center; }
  .filters .v-separator {
    border-right: 2px solid #e1e1e1;
    height: 35px; }

.time-slot-filters {
  box-shadow: 0 -2px 10px 0 rgba(68, 68, 68, 0.6);
  position: relative;
  z-index: 2; }
  .time-slot-filters .title {
    font-size: 0.8125rem; }

.time-slots-container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .time-slots-container .back {
    position: absolute;
    padding-left: 0.28125rem;
    padding-top: 1.6875rem;
    top: 0;
    left: 0; }
    @media (min-width: 1200px) {
      .time-slots-container .back {
        padding-left: 1.6875rem; } }

.time-slots {
  flex-grow: 1;
  overflow: auto;
  padding-top: 0.28125rem;
  padding-bottom: 0.28125rem; }

.modal-create-booking,
.modal-edit-booking {
  position: relative; }
  .modal-create-booking > .close,
  .modal-edit-booking > .close {
    top: 50%;
    transform: translateY(-50%); }
  .modal-create-booking .modal-flow-title,
  .modal-edit-booking .modal-flow-title {
    position: absolute;
    left: 92px !important;
    margin-bottom: 0 !important; }
  .modal-create-booking + .modal-body .content:not(.booking-confirmation-summary),
  .modal-edit-booking + .modal-body .content:not(.booking-confirmation-summary) {
    margin: 20px !important; }

.modal-company-floor-plan .modal-header .close {
  left: inherit;
  right: 2.25rem;
  top: 2rem; }

.modal-company-floor-plan .modal-header .back {
  left: 2.25rem;
  right: inherit; }

.modal-company-floor-plan .location-info span {
  font-size: 12px;
  line-height: 19px;
  display: block; }

.modal-company-floor-plan .location-info h6 {
  text-transform: uppercase;
  line-height: 20px; }

.modal-company-floor-plan .modal-body {
  padding: 30px; }

.modal-company-floor-plan .modal-footer .btn {
  border-radius: 0;
  font-weight: 600;
  width: 100%;
  min-height: 64px; }

.modal-membership-members {
  max-width: 1340px; }
  .modal-membership-members .modal-header .close {
    left: inherit;
    right: 2.25rem;
    top: 2rem; }
  .modal-membership-members .modal-header .back {
    left: 2.25rem;
    right: inherit; }
  .modal-membership-members .modal-body {
    padding: 30px; }
    .modal-membership-members .modal-body .list-group-item {
      min-height: 60px; }
      .modal-membership-members .modal-body .list-group-item .list-group-item-link {
        min-height: 60px; }

.FlexibleBooking {
  position: relative;
  height: calc(100% - 48px); }
  .FlexibleBooking__Calendar {
    display: flex;
    align-items: flex-start;
    border: 1px solid rgba(16, 24, 32, 0.05);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto; }
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        .FlexibleBooking__Calendar {
          position: inherit; } } }
  .FlexibleBooking .left-col {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: #fff;
    width: 120px; }
  .FlexibleBooking .zoom {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    height: 72px;
    padding: 8px;
    background-color: #fff;
    width: 120px; }
    .FlexibleBooking .zoom button {
      border: 1px solid transparent; }
    .FlexibleBooking .zoom button:not(:disabled) {
      border-color: #ededed;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
      background-color: #fff; }
    .FlexibleBooking .zoom button:disabled {
      background-color: #ededed; }
  .FlexibleBooking .zoom button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    margin: 0 0.3rem; }
    .FlexibleBooking .zoom button:disabled {
      cursor: auto; }
      .FlexibleBooking .zoom button:disabled path {
        fill: #a7b7bd; }
  .FlexibleBooking .time-cell {
    display: flex;
    align-items: center;
    height: 40px; }
    .FlexibleBooking .time-cell .time {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 100%; }
  .FlexibleBooking .time-cell:nth-child(odd) {
    background-color: #fff; }
  .FlexibleBooking .time-cell:nth-child(even) {
    background-color: #ededed; }
  .FlexibleBooking__Resources {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .FlexibleBooking__Resources .resources-header-row,
    .FlexibleBooking__Resources .resources-content-row {
      display: flex; }
      .FlexibleBooking__Resources .resources-header-row > div,
      .FlexibleBooking__Resources .resources-content-row > div {
        min-width: 175px;
        width: 100%; }
  .FlexibleBooking .resources-header-row {
    position: sticky;
    top: 0;
    z-index: 2; }
  .FlexibleBooking .resources-arrows-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    z-index: 3; }
  .FlexibleBooking .resources-arrows {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1px;
    width: 16px;
    height: 72px;
    background-color: rgba(36, 76, 90, 0.8); }
  .FlexibleBooking .arrow-left {
    left: 122px; }
  .FlexibleBooking .arrow-right {
    right: 1px; }
  .FlexibleBooking .resource-head {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 8px;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    z-index: 1; }
  .FlexibleBooking .resource-head-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    margin-right: 1rem;
    position: relative; }
    .FlexibleBooking .resource-head-thumb > .rounded-circle,
    .FlexibleBooking .resource-head-thumb > .FlexibleBooking__Amenity_Image {
      position: absolute;
      left: 0;
      top: 0; }
  .FlexibleBooking .resource-head-seats {
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    z-index: 1;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7); }
    .FlexibleBooking .resource-head-seats .people {
      font-size: 12px; }
  .FlexibleBooking .resource-private {
    display: inherit;
    position: absolute;
    top: -4px;
    right: 0;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 50%; }
  .FlexibleBooking .resource-title {
    font-size: 13px; }
  .FlexibleBooking .Cutoff .period-background,
  .FlexibleBooking .OutsideOpeningHours .period-background,
  .FlexibleBooking .MinBookingDuration .period-background,
  .FlexibleBooking .MaxContiguousPeriodReached .period-background,
  .FlexibleBooking .MaxPeriodPerDayReached .period-background {
    background-color: rgba(67, 67, 76, 0.4); }
  .FlexibleBooking .period-background {
    pointer-events: none; }
  .FlexibleBooking .Booked:not(.CurrentBookingPeriod) {
    background-color: #244c5a;
    position: relative; }
    .FlexibleBooking .Booked:not(.CurrentBookingPeriod)::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 100%;
      background-color: #fff;
      z-index: 1; }
  .FlexibleBooking .resource-content {
    border-left: 1px solid #e1e1e1; }
    .FlexibleBooking .resource-content .CurrentBooking-details {
      transition: all 0.2s ease-in-out; }
    .FlexibleBooking .resource-content .CurrentBooking {
      background-color: rgba(184, 201, 225, 0.6); }
      .FlexibleBooking .resource-content .CurrentBooking__Error {
        font-size: 11px;
        line-height: 13px; }
      .FlexibleBooking .resource-content .CurrentBooking.minimumDuration, .FlexibleBooking .resource-content .CurrentBooking.maximumDuration, .FlexibleBooking .resource-content .CurrentBooking.BookingConflict {
        background-color: rgba(214, 8, 8, 0.6);
        border: 1px solid #d60808;
        color: #fff; }
        .FlexibleBooking .resource-content .CurrentBooking.minimumDuration .react-resizable-handle-s,
        .FlexibleBooking .resource-content .CurrentBooking.minimumDuration .resize-handle, .FlexibleBooking .resource-content .CurrentBooking.maximumDuration .react-resizable-handle-s,
        .FlexibleBooking .resource-content .CurrentBooking.maximumDuration .resize-handle, .FlexibleBooking .resource-content .CurrentBooking.BookingConflict .react-resizable-handle-s,
        .FlexibleBooking .resource-content .CurrentBooking.BookingConflict .resize-handle {
          border-color: rgba(214, 8, 8, 0.6); }
      .FlexibleBooking .resource-content .CurrentBooking .react-resizable {
        width: 100%;
        height: 100%; }
      .FlexibleBooking .resource-content .CurrentBooking .react-resizable-handle {
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        margin-left: 0;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #b8c9e1; }
      .FlexibleBooking .resource-content .CurrentBooking .react-resizable-handle-n {
        top: -3px; }
      .FlexibleBooking .resource-content .CurrentBooking .react-resizable-handle-s {
        bottom: -3px; }
    .FlexibleBooking .resource-content .BookingConflict {
      font-size: 0.6875rem;
      color: #fff;
      overflow: hidden; }
  .FlexibleBooking .period-thumbnail {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover; }
  .FlexibleBooking .Available .quarterCells.odd,
  .FlexibleBooking .CurrentBookingPeriod .quarterCells.odd,
  .FlexibleBooking .Cutoff .quarterCells.odd,
  .FlexibleBooking .OutsideOpeningHours .quarterCells.odd,
  .FlexibleBooking .MinBookingDuration .quarterCells.odd,
  .FlexibleBooking .MaxContiguousPeriodReached .quarterCells.odd,
  .FlexibleBooking .MaxPeriodPerDayReached .quarterCells.odd {
    background-color: #fff; }
  .FlexibleBooking .Available .quarterCells.even,
  .FlexibleBooking .CurrentBookingPeriod .quarterCells.even,
  .FlexibleBooking .Cutoff .quarterCells.even,
  .FlexibleBooking .OutsideOpeningHours .quarterCells.even,
  .FlexibleBooking .MinBookingDuration .quarterCells.even,
  .FlexibleBooking .MaxContiguousPeriodReached .quarterCells.even,
  .FlexibleBooking .MaxPeriodPerDayReached .quarterCells.even {
    background-color: #ededed; }
  .FlexibleBooking .CurrentBookingPeriod .booking-information {
    display: none !important; }
  .FlexibleBooking .CurrentBooking__Information {
    font-size: 11px;
    line-height: 13px; }
  .FlexibleBooking .zoom-0 .quarterCells {
    height: 10px; }
  .FlexibleBooking .zoom-1 .quarterCells {
    height: 20px; }
  .FlexibleBooking .zoom-2 .quarterCells {
    height: 40px; }
  .FlexibleBooking .booking-information {
    padding: 4px; }
    .FlexibleBooking .booking-information > div {
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 5px; }
    .FlexibleBooking .booking-information > p {
      font-size: 10px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }

.FlexibleBooking__Amenity_Image {
  width: 55px;
  height: 55px;
  border-radius: 50%; }

.FlexibleBooking__Amenity_Information_Cost {
  padding-left: 0.4em;
  padding-right: 0.4em; }

.FlexibleBooking__actions .room_filter_icon_filter_count {
  width: 12px;
  height: 12px;
  background-color: #b8c9e1;
  border-radius: 50%;
  font-size: 9px;
  bottom: 4px;
  right: -1px;
  padding-left: 2px; }

.FlexibleBooking__actions .FlexibleBooking__ActionsBar_DateControls {
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase; }

.FlexibleBooking__actions .FlexibleBooking__ActionsBar_DateControls_DatePicker {
  min-width: 300px;
  margin: 0 0.6rem;
  text-align: center; }

.FlexibleBooking__actions .FlexibleBooking__ActionsBar_DateControls_CurrentDate {
  cursor: pointer; }

.FlexibleBooking__actions .btn-change-date {
  line-height: normal;
  padding: 0 0.2rem;
  cursor: pointer; }

.FlexibleBooking__actions_subtitle {
  font-weight: 500; }

.MultiCheckbox_checkbox:hover {
  background-color: inherit !important; }

.time-slot-item {
  font-weight: 500;
  min-height: 69px; }
  .time-slot-item.small {
    min-height: 30px; }
    .time-slot-item.small .list-group-item-text {
      text-overflow: unset;
      white-space: normal; }
  .time-slot-item.selected {
    background-color: #244c5a; }
    .time-slot-item.selected .rounded-picture-list-item {
      color: #fff; }
  .time-slot-item .list-group-item-status {
    font-size: 0.8125rem; }
  .time-slot-item .sub-title {
    margin-top: 0.14063rem; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .flexible-details .FlexibleBooking__Calendar {
      position: absolute !important; } } }

.floorplan-container .no-results {
  min-height: calc(100vh - 200px);
  border: 1px solid grey;
  background-color: rgba(128, 128, 128, 0.1); }

.floorplan-container.floorplan-modal .floorplan-svg-container {
  width: 100%; }
  .floorplan-container.floorplan-modal .floorplan-svg-container > div {
    min-height: calc(100vh - 400px);
    border: none; }

.floorplan-container .floorplan-svg-container {
  width: calc(100% - 305px); }
  .floorplan-container .floorplan-svg-container > div {
    min-height: calc(100vh - 200px);
    cursor: grab;
    border: 1px solid grey;
    background-color: rgba(128, 128, 128, 0.1); }
  .floorplan-container .floorplan-svg-container .floorplan-unit {
    overflow: visible;
    transition: fill .2s ease-in-out; }
    .floorplan-container .floorplan-svg-container .floorplan-unit.selected {
      stroke-width: 2px; }
    .floorplan-container .floorplan-svg-container .floorplan-unit text,
    .floorplan-container .floorplan-svg-container .floorplan-unit .icon {
      font-size: 10.5px;
      fill: black !important;
      dominant-baseline: middle;
      text-anchor: middle;
      stroke-width: 0px; }
    .floorplan-container .floorplan-svg-container .floorplan-unit.CommunalArea {
      fill: rgba(184, 201, 225, 0.8); }
      .floorplan-container .floorplan-svg-container .floorplan-unit.CommunalArea.selected {
        fill: #c6d4e7;
        stroke: #b8c9e1; }
      .floorplan-container .floorplan-svg-container .floorplan-unit.CommunalArea.unselected {
        fill: rgba(184, 201, 225, 0.4); }
    .floorplan-container .floorplan-svg-container .floorplan-unit.Enterprise {
      fill: rgba(36, 76, 90, 0.8); }
      .floorplan-container .floorplan-svg-container .floorplan-unit.Enterprise.selected {
        fill: rgba(36, 76, 90, 0.9);
        stroke: #244c5a; }
      .floorplan-container .floorplan-svg-container .floorplan-unit.Enterprise.unselected {
        fill: #a7b7bd; }

.tooltip-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 0px 12px; }
  .tooltip-content p {
    font-size: 16px;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 125px;
    overflow: hidden; }

.floorplan-dropdowns {
  width: 305px;
  margin: -4px -10px; }
  .floorplan-dropdowns .floorplan-dropdown {
    margin: 4px 10px; }
    .floorplan-dropdowns .floorplan-dropdown .floorplan-dropdown-button {
      min-width: 100px;
      height: 44px;
      border-radius: 100px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .floorplan-dropdowns .floorplan-dropdown .floorplan-dropdown-button .icon-container {
        width: 24px;
        height: 24px;
        background-color: rgba(255, 255, 255, 0.3);
        margin-left: 10px; }
      .floorplan-dropdowns .floorplan-dropdown .floorplan-dropdown-button .icon {
        display: inline-block;
        vertical-align: middle;
        border-top: 0.5em solid;
        border-right: 0.5em solid transparent;
        border-left: 0.5em solid transparent;
        border-bottom: 0;
        transition: transform 0.2s ease-in-out; }
      .floorplan-dropdowns .floorplan-dropdown .floorplan-dropdown-button.toggled .icon {
        transform: rotate(-180deg); }

.floorplan-search input.form-control {
  border: none; }

.floorplan-search .multi-select-dropdown-items.open {
  max-height: 35vh; }

.floorplan-search .floorplan-search-dropdown .multi-select-dropdown-items {
  min-width: 17.813rem; }
  .floorplan-search .floorplan-search-dropdown .multi-select-dropdown-items .floorplan-dropdown-button {
    height: 32px;
    background-color: #a2a2a2; }
  .floorplan-search .floorplan-search-dropdown .multi-select-dropdown-items .list-group-item {
    padding: 9px;
    min-height: 4.5rem; }

.floorplan-searchexpertise .list-expertises {
  min-height: inherit;
  margin-bottom: 10px; }
  .floorplan-searchexpertise .list-expertises .list-group-item-image {
    margin-left: 10px; }

.floorplan-searchexpertise .btn.bottom-action {
  width: 500px;
  line-height: 3.6; }

.CommunalAreaModal .modal-header {
  height: 96px; }
  .CommunalAreaModal .modal-header h3 {
    font-size: 39px; }
  .CommunalAreaModal .modal-header .close {
    left: inherit;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%); }

.creditsInfo > div:first-child {
  border-right: 1px solid #a2a2a2; }

.booking-confirmation-summary .privacy-section .list-group-item {
  min-height: 62px; }

.booking-confirmation-summary .summary-section {
  letter-spacing: normal; }
  .booking-confirmation-summary .summary-section .time-info {
    font-size: 0.9375rem; }

.animated-fill g,
.animated-fill path {
  transition: fill 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; }

.time-zone {
  font-size: 0.625rem;
  color: #a2a2a2;
  font-weight: 500; }

.recurring-booking-duration-list .sub-title {
  white-space: pre-wrap; }

.eventList {
  margin-bottom: 4px; }
  .eventList .eventDay {
    width: 62px; }
  .eventList .eventType {
    font-size: 0.6rem;
    padding: 3px; }
  .eventList .badge {
    margin-top: 3px; }

#list-page {
  min-height: 950px; }
  #list-page #infinite-list {
    height: 100%;
    width: calc(100% - 18px);
    overflow-y: auto; }

/**********************************************************
*****                  Tour                   *****
**********************************************************/
#tourDetail .whereTour {
  min-height: 16.6875rem;
  background-size: cover;
  background-position: center; }
  #tourDetail .whereTour * {
    z-index: 2; }
  #tourDetail .whereTour::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(52, 55, 65, 0.65);
    z-index: 1;
    border-radius: 0; }

/**********************************************************
*****                  New Concepts                   *****
**********************************************************/
.btn-link.btn-link-primary {
  color: #244c5a; }
  .btn-link.btn-link-primary:hover {
    color: #19353f; }
  .btn-link.btn-link-primary:not(:disabled):not(.disabled).active, .btn-link.btn-link-primary:not(:disabled):not(.disabled):active {
    color: #152d36; }

.btn-link.btn-link-secondary {
  color: #b8c9e1; }
  .btn-link.btn-link-secondary:hover {
    color: #9db5d6; }
  .btn-link.btn-link-secondary:not(:disabled):not(.disabled).active, .btn-link.btn-link-secondary:not(:disabled):not(.disabled):active {
    color: #94aed2; }

.btn-link.btn-link-success {
  color: #55c443; }
  .btn-link.btn-link-success:hover {
    color: #46ab36; }
  .btn-link.btn-link-success:not(:disabled):not(.disabled).active, .btn-link.btn-link-success:not(:disabled):not(.disabled):active {
    color: #42a133; }

.btn-link.btn-link-info {
  color: #17a2b8; }
  .btn-link.btn-link-info:hover {
    color: #138496; }
  .btn-link.btn-link-info:not(:disabled):not(.disabled).active, .btn-link.btn-link-info:not(:disabled):not(.disabled):active {
    color: #117a8b; }

.btn-link.btn-link-warning {
  color: #ff6900; }
  .btn-link.btn-link-warning:hover {
    color: #d95900; }
  .btn-link.btn-link-warning:not(:disabled):not(.disabled).active, .btn-link.btn-link-warning:not(:disabled):not(.disabled):active {
    color: #cc5400; }

.btn-link.btn-link-danger {
  color: #d60808; }
  .btn-link.btn-link-danger:hover {
    color: #b10707; }
  .btn-link.btn-link-danger:not(:disabled):not(.disabled).active, .btn-link.btn-link-danger:not(:disabled):not(.disabled):active {
    color: #a50606; }

.btn-link.btn-link-light {
  color: #e1e1e1; }
  .btn-link.btn-link-light:hover {
    color: #cecece; }
  .btn-link.btn-link-light:not(:disabled):not(.disabled).active, .btn-link.btn-link-light:not(:disabled):not(.disabled):active {
    color: #c8c8c8; }

.btn-link.btn-link-dark {
  color: #43434c; }
  .btn-link.btn-link-dark:hover {
    color: #313138; }
  .btn-link.btn-link-dark:not(:disabled):not(.disabled).active, .btn-link.btn-link-dark:not(:disabled):not(.disabled):active {
    color: #2b2b31; }

.btn-link.btn-link-black {
  color: #343741; }
  .btn-link.btn-link-black:hover {
    color: #23252c; }
  .btn-link.btn-link-black:not(:disabled):not(.disabled).active, .btn-link.btn-link-black:not(:disabled):not(.disabled):active {
    color: #1d1f25; }

.btn-link.btn-link-light-grey {
  color: #ededed; }
  .btn-link.btn-link-light-grey:hover {
    color: #dadada; }
  .btn-link.btn-link-light-grey:not(:disabled):not(.disabled).active, .btn-link.btn-link-light-grey:not(:disabled):not(.disabled):active {
    color: #d4d4d4; }

.btn-link.btn-link-slate-grey {
  color: #616167; }
  .btn-link.btn-link-slate-grey:hover {
    color: #4e4e53; }
  .btn-link.btn-link-slate-grey:not(:disabled):not(.disabled).active, .btn-link.btn-link-slate-grey:not(:disabled):not(.disabled):active {
    color: #48484d; }

.btn-link.btn-link-slate-blue {
  color: #244c5a; }
  .btn-link.btn-link-slate-blue:hover {
    color: #19353f; }
  .btn-link.btn-link-slate-blue:not(:disabled):not(.disabled).active, .btn-link.btn-link-slate-blue:not(:disabled):not(.disabled):active {
    color: #152d36; }

.btn-link.btn-link-soft-blue {
  color: #b8c9e1; }
  .btn-link.btn-link-soft-blue:hover {
    color: #9db5d6; }
  .btn-link.btn-link-soft-blue:not(:disabled):not(.disabled).active, .btn-link.btn-link-soft-blue:not(:disabled):not(.disabled):active {
    color: #94aed2; }

.btn-link.btn-link-warm-grey {
  color: #a2a2a2; }
  .btn-link.btn-link-warm-grey:hover {
    color: #8f8f8f; }
  .btn-link.btn-link-warm-grey:not(:disabled):not(.disabled).active, .btn-link.btn-link-warm-grey:not(:disabled):not(.disabled):active {
    color: #898989; }

.row.grid:not(.no-gutters) {
  margin-top: -0.5625rem;
  margin-bottom: -0.5625rem; }
  .row.grid:not(.no-gutters) > [class*='col-'],
  .row.grid:not(.no-gutters) > .col {
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem; }
  .row.grid:not(.no-gutters).padded {
    margin: -1.125rem; }
    .row.grid:not(.no-gutters).padded [class*='col-'] {
      padding: 1.125rem; }

.row:not(.no-gutters).m-0 {
  margin: 0 -0.5625rem !important; }

.row:not(.no-gutters).grid.m-0 {
  margin: -0.5625rem !important; }

.row:not(.no-gutters).grid.mt-0, .row:not(.no-gutters).grid.my-0 {
  margin-top: -0.5625rem !important; }

.row:not(.no-gutters).mr-0, .row:not(.no-gutters).mx-0 {
  margin-right: -0.5625rem !important; }

.row:not(.no-gutters).grid.mb-0, .row:not(.no-gutters).grid.my-0 {
  margin-bottom: -0.5625rem !important; }

.row:not(.no-gutters).ml-0, .row:not(.no-gutters).mx-0 {
  margin-left: -0.5625rem !important; }

.row:not(.no-gutters).m-1 {
  margin: 0.5625rem 0rem !important; }

.row:not(.no-gutters).grid.m-1 {
  margin: 0rem !important; }

.row:not(.no-gutters).grid.mt-1, .row:not(.no-gutters).grid.my-1 {
  margin-top: 0rem !important; }

.row:not(.no-gutters).mr-1, .row:not(.no-gutters).mx-1 {
  margin-right: 0rem !important; }

.row:not(.no-gutters).grid.mb-1, .row:not(.no-gutters).grid.my-1 {
  margin-bottom: 0rem !important; }

.row:not(.no-gutters).ml-1, .row:not(.no-gutters).mx-1 {
  margin-left: 0rem !important; }

.row:not(.no-gutters).m-2 {
  margin: 1.125rem 0.5625rem !important; }

.row:not(.no-gutters).grid.m-2 {
  margin: 0.5625rem !important; }

.row:not(.no-gutters).grid.mt-2, .row:not(.no-gutters).grid.my-2 {
  margin-top: 0.5625rem !important; }

.row:not(.no-gutters).mr-2, .row:not(.no-gutters).mx-2 {
  margin-right: 0.5625rem !important; }

.row:not(.no-gutters).grid.mb-2, .row:not(.no-gutters).grid.my-2 {
  margin-bottom: 0.5625rem !important; }

.row:not(.no-gutters).ml-2, .row:not(.no-gutters).mx-2 {
  margin-left: 0.5625rem !important; }

.row:not(.no-gutters).m-3 {
  margin: 1.6875rem 1.125rem !important; }

.row:not(.no-gutters).grid.m-3 {
  margin: 1.125rem !important; }

.row:not(.no-gutters).grid.mt-3, .row:not(.no-gutters).grid.my-3 {
  margin-top: 1.125rem !important; }

.row:not(.no-gutters).mr-3, .row:not(.no-gutters).mx-3 {
  margin-right: 1.125rem !important; }

.row:not(.no-gutters).grid.mb-3, .row:not(.no-gutters).grid.my-3 {
  margin-bottom: 1.125rem !important; }

.row:not(.no-gutters).ml-3, .row:not(.no-gutters).mx-3 {
  margin-left: 1.125rem !important; }

.row:not(.no-gutters).m-4 {
  margin: 2.25rem 1.6875rem !important; }

.row:not(.no-gutters).grid.m-4 {
  margin: 1.6875rem !important; }

.row:not(.no-gutters).grid.mt-4, .row:not(.no-gutters).grid.my-4 {
  margin-top: 1.6875rem !important; }

.row:not(.no-gutters).mr-4, .row:not(.no-gutters).mx-4 {
  margin-right: 1.6875rem !important; }

.row:not(.no-gutters).grid.mb-4, .row:not(.no-gutters).grid.my-4 {
  margin-bottom: 1.6875rem !important; }

.row:not(.no-gutters).ml-4, .row:not(.no-gutters).mx-4 {
  margin-left: 1.6875rem !important; }

.row:not(.no-gutters).m-5 {
  margin: 2.8125rem 2.25rem !important; }

.row:not(.no-gutters).grid.m-5 {
  margin: 2.25rem !important; }

.row:not(.no-gutters).grid.mt-5, .row:not(.no-gutters).grid.my-5 {
  margin-top: 2.25rem !important; }

.row:not(.no-gutters).mr-5, .row:not(.no-gutters).mx-5 {
  margin-right: 2.25rem !important; }

.row:not(.no-gutters).grid.mb-5, .row:not(.no-gutters).grid.my-5 {
  margin-bottom: 2.25rem !important; }

.row:not(.no-gutters).ml-5, .row:not(.no-gutters).mx-5 {
  margin-left: 2.25rem !important; }

.row:not(.no-gutters).m-6 {
  margin: 3.375rem 2.8125rem !important; }

.row:not(.no-gutters).grid.m-6 {
  margin: 2.8125rem !important; }

.row:not(.no-gutters).grid.mt-6, .row:not(.no-gutters).grid.my-6 {
  margin-top: 2.8125rem !important; }

.row:not(.no-gutters).mr-6, .row:not(.no-gutters).mx-6 {
  margin-right: 2.8125rem !important; }

.row:not(.no-gutters).grid.mb-6, .row:not(.no-gutters).grid.my-6 {
  margin-bottom: 2.8125rem !important; }

.row:not(.no-gutters).ml-6, .row:not(.no-gutters).mx-6 {
  margin-left: 2.8125rem !important; }

.row:not(.no-gutters).m-7 {
  margin: 3.9375rem 3.375rem !important; }

.row:not(.no-gutters).grid.m-7 {
  margin: 3.375rem !important; }

.row:not(.no-gutters).grid.mt-7, .row:not(.no-gutters).grid.my-7 {
  margin-top: 3.375rem !important; }

.row:not(.no-gutters).mr-7, .row:not(.no-gutters).mx-7 {
  margin-right: 3.375rem !important; }

.row:not(.no-gutters).grid.mb-7, .row:not(.no-gutters).grid.my-7 {
  margin-bottom: 3.375rem !important; }

.row:not(.no-gutters).ml-7, .row:not(.no-gutters).mx-7 {
  margin-left: 3.375rem !important; }

.row:not(.no-gutters).m-8 {
  margin: 4.5rem 3.9375rem !important; }

.row:not(.no-gutters).grid.m-8 {
  margin: 3.9375rem !important; }

.row:not(.no-gutters).grid.mt-8, .row:not(.no-gutters).grid.my-8 {
  margin-top: 3.9375rem !important; }

.row:not(.no-gutters).mr-8, .row:not(.no-gutters).mx-8 {
  margin-right: 3.9375rem !important; }

.row:not(.no-gutters).grid.mb-8, .row:not(.no-gutters).grid.my-8 {
  margin-bottom: 3.9375rem !important; }

.row:not(.no-gutters).ml-8, .row:not(.no-gutters).mx-8 {
  margin-left: 3.9375rem !important; }

.row:not(.no-gutters).m-9 {
  margin: 5.0625rem 4.5rem !important; }

.row:not(.no-gutters).grid.m-9 {
  margin: 4.5rem !important; }

.row:not(.no-gutters).grid.mt-9, .row:not(.no-gutters).grid.my-9 {
  margin-top: 4.5rem !important; }

.row:not(.no-gutters).mr-9, .row:not(.no-gutters).mx-9 {
  margin-right: 4.5rem !important; }

.row:not(.no-gutters).grid.mb-9, .row:not(.no-gutters).grid.my-9 {
  margin-bottom: 4.5rem !important; }

.row:not(.no-gutters).ml-9, .row:not(.no-gutters).mx-9 {
  margin-left: 4.5rem !important; }

.row:not(.no-gutters).m-10 {
  margin: 5.625rem 5.0625rem !important; }

.row:not(.no-gutters).grid.m-10 {
  margin: 5.0625rem !important; }

.row:not(.no-gutters).grid.mt-10, .row:not(.no-gutters).grid.my-10 {
  margin-top: 5.0625rem !important; }

.row:not(.no-gutters).mr-10, .row:not(.no-gutters).mx-10 {
  margin-right: 5.0625rem !important; }

.row:not(.no-gutters).grid.mb-10, .row:not(.no-gutters).grid.my-10 {
  margin-bottom: 5.0625rem !important; }

.row:not(.no-gutters).ml-10, .row:not(.no-gutters).mx-10 {
  margin-left: 5.0625rem !important; }

.row:not(.no-gutters).m-11 {
  margin: 6.1875rem 5.625rem !important; }

.row:not(.no-gutters).grid.m-11 {
  margin: 5.625rem !important; }

.row:not(.no-gutters).grid.mt-11, .row:not(.no-gutters).grid.my-11 {
  margin-top: 5.625rem !important; }

.row:not(.no-gutters).mr-11, .row:not(.no-gutters).mx-11 {
  margin-right: 5.625rem !important; }

.row:not(.no-gutters).grid.mb-11, .row:not(.no-gutters).grid.my-11 {
  margin-bottom: 5.625rem !important; }

.row:not(.no-gutters).ml-11, .row:not(.no-gutters).mx-11 {
  margin-left: 5.625rem !important; }

.row:not(.no-gutters).m-12 {
  margin: 6.75rem 6.1875rem !important; }

.row:not(.no-gutters).grid.m-12 {
  margin: 6.1875rem !important; }

.row:not(.no-gutters).grid.mt-12, .row:not(.no-gutters).grid.my-12 {
  margin-top: 6.1875rem !important; }

.row:not(.no-gutters).mr-12, .row:not(.no-gutters).mx-12 {
  margin-right: 6.1875rem !important; }

.row:not(.no-gutters).grid.mb-12, .row:not(.no-gutters).grid.my-12 {
  margin-bottom: 6.1875rem !important; }

.row:not(.no-gutters).ml-12, .row:not(.no-gutters).mx-12 {
  margin-left: 6.1875rem !important; }

.row:not(.no-gutters).m-13 {
  margin: 7.3125rem 6.75rem !important; }

.row:not(.no-gutters).grid.m-13 {
  margin: 6.75rem !important; }

.row:not(.no-gutters).grid.mt-13, .row:not(.no-gutters).grid.my-13 {
  margin-top: 6.75rem !important; }

.row:not(.no-gutters).mr-13, .row:not(.no-gutters).mx-13 {
  margin-right: 6.75rem !important; }

.row:not(.no-gutters).grid.mb-13, .row:not(.no-gutters).grid.my-13 {
  margin-bottom: 6.75rem !important; }

.row:not(.no-gutters).ml-13, .row:not(.no-gutters).mx-13 {
  margin-left: 6.75rem !important; }

.row:not(.no-gutters).m-14 {
  margin: 7.875rem 7.3125rem !important; }

.row:not(.no-gutters).grid.m-14 {
  margin: 7.3125rem !important; }

.row:not(.no-gutters).grid.mt-14, .row:not(.no-gutters).grid.my-14 {
  margin-top: 7.3125rem !important; }

.row:not(.no-gutters).mr-14, .row:not(.no-gutters).mx-14 {
  margin-right: 7.3125rem !important; }

.row:not(.no-gutters).grid.mb-14, .row:not(.no-gutters).grid.my-14 {
  margin-bottom: 7.3125rem !important; }

.row:not(.no-gutters).ml-14, .row:not(.no-gutters).mx-14 {
  margin-left: 7.3125rem !important; }

.row:not(.no-gutters).m-15 {
  margin: 8.4375rem 7.875rem !important; }

.row:not(.no-gutters).grid.m-15 {
  margin: 7.875rem !important; }

.row:not(.no-gutters).grid.mt-15, .row:not(.no-gutters).grid.my-15 {
  margin-top: 7.875rem !important; }

.row:not(.no-gutters).mr-15, .row:not(.no-gutters).mx-15 {
  margin-right: 7.875rem !important; }

.row:not(.no-gutters).grid.mb-15, .row:not(.no-gutters).grid.my-15 {
  margin-bottom: 7.875rem !important; }

.row:not(.no-gutters).ml-15, .row:not(.no-gutters).mx-15 {
  margin-left: 7.875rem !important; }

.row:not(.no-gutters).m-16 {
  margin: 9rem 8.4375rem !important; }

.row:not(.no-gutters).grid.m-16 {
  margin: 8.4375rem !important; }

.row:not(.no-gutters).grid.mt-16, .row:not(.no-gutters).grid.my-16 {
  margin-top: 8.4375rem !important; }

.row:not(.no-gutters).mr-16, .row:not(.no-gutters).mx-16 {
  margin-right: 8.4375rem !important; }

.row:not(.no-gutters).grid.mb-16, .row:not(.no-gutters).grid.my-16 {
  margin-bottom: 8.4375rem !important; }

.row:not(.no-gutters).ml-16, .row:not(.no-gutters).mx-16 {
  margin-left: 8.4375rem !important; }

.row:not(.no-gutters).m-17 {
  margin: 9.5625rem 9rem !important; }

.row:not(.no-gutters).grid.m-17 {
  margin: 9rem !important; }

.row:not(.no-gutters).grid.mt-17, .row:not(.no-gutters).grid.my-17 {
  margin-top: 9rem !important; }

.row:not(.no-gutters).mr-17, .row:not(.no-gutters).mx-17 {
  margin-right: 9rem !important; }

.row:not(.no-gutters).grid.mb-17, .row:not(.no-gutters).grid.my-17 {
  margin-bottom: 9rem !important; }

.row:not(.no-gutters).ml-17, .row:not(.no-gutters).mx-17 {
  margin-left: 9rem !important; }

.row:not(.no-gutters).m-18 {
  margin: 10.125rem 9.5625rem !important; }

.row:not(.no-gutters).grid.m-18 {
  margin: 9.5625rem !important; }

.row:not(.no-gutters).grid.mt-18, .row:not(.no-gutters).grid.my-18 {
  margin-top: 9.5625rem !important; }

.row:not(.no-gutters).mr-18, .row:not(.no-gutters).mx-18 {
  margin-right: 9.5625rem !important; }

.row:not(.no-gutters).grid.mb-18, .row:not(.no-gutters).grid.my-18 {
  margin-bottom: 9.5625rem !important; }

.row:not(.no-gutters).ml-18, .row:not(.no-gutters).mx-18 {
  margin-left: 9.5625rem !important; }

.row:not(.no-gutters).m-19 {
  margin: 10.6875rem 10.125rem !important; }

.row:not(.no-gutters).grid.m-19 {
  margin: 10.125rem !important; }

.row:not(.no-gutters).grid.mt-19, .row:not(.no-gutters).grid.my-19 {
  margin-top: 10.125rem !important; }

.row:not(.no-gutters).mr-19, .row:not(.no-gutters).mx-19 {
  margin-right: 10.125rem !important; }

.row:not(.no-gutters).grid.mb-19, .row:not(.no-gutters).grid.my-19 {
  margin-bottom: 10.125rem !important; }

.row:not(.no-gutters).ml-19, .row:not(.no-gutters).mx-19 {
  margin-left: 10.125rem !important; }

.row:not(.no-gutters).m-20 {
  margin: 11.25rem 10.6875rem !important; }

.row:not(.no-gutters).grid.m-20 {
  margin: 10.6875rem !important; }

.row:not(.no-gutters).grid.mt-20, .row:not(.no-gutters).grid.my-20 {
  margin-top: 10.6875rem !important; }

.row:not(.no-gutters).mr-20, .row:not(.no-gutters).mx-20 {
  margin-right: 10.6875rem !important; }

.row:not(.no-gutters).grid.mb-20, .row:not(.no-gutters).grid.my-20 {
  margin-bottom: 10.6875rem !important; }

.row:not(.no-gutters).ml-20, .row:not(.no-gutters).mx-20 {
  margin-left: 10.6875rem !important; }

@media (min-width: 576px) {
  .row:not(.no-gutters).m-sm-0 {
    margin: 0 -0.5625rem !important; }
  .row:not(.no-gutters).grid.m-sm-0 {
    margin: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-sm-0, .row:not(.no-gutters).grid.my-sm-0 {
    margin-top: -0.5625rem !important; }
  .row:not(.no-gutters).mr-sm-0, .row:not(.no-gutters).mx-sm-0 {
    margin-right: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-sm-0, .row:not(.no-gutters).grid.my-sm-0 {
    margin-bottom: -0.5625rem !important; }
  .row:not(.no-gutters).ml-sm-0, .row:not(.no-gutters).mx-sm-0 {
    margin-left: -0.5625rem !important; }
  .row:not(.no-gutters).m-sm-1 {
    margin: 0.5625rem 0rem !important; }
  .row:not(.no-gutters).grid.m-sm-1 {
    margin: 0rem !important; }
  .row:not(.no-gutters).grid.mt-sm-1, .row:not(.no-gutters).grid.my-sm-1 {
    margin-top: 0rem !important; }
  .row:not(.no-gutters).mr-sm-1, .row:not(.no-gutters).mx-sm-1 {
    margin-right: 0rem !important; }
  .row:not(.no-gutters).grid.mb-sm-1, .row:not(.no-gutters).grid.my-sm-1 {
    margin-bottom: 0rem !important; }
  .row:not(.no-gutters).ml-sm-1, .row:not(.no-gutters).mx-sm-1 {
    margin-left: 0rem !important; }
  .row:not(.no-gutters).m-sm-2 {
    margin: 1.125rem 0.5625rem !important; }
  .row:not(.no-gutters).grid.m-sm-2 {
    margin: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-sm-2, .row:not(.no-gutters).grid.my-sm-2 {
    margin-top: 0.5625rem !important; }
  .row:not(.no-gutters).mr-sm-2, .row:not(.no-gutters).mx-sm-2 {
    margin-right: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-sm-2, .row:not(.no-gutters).grid.my-sm-2 {
    margin-bottom: 0.5625rem !important; }
  .row:not(.no-gutters).ml-sm-2, .row:not(.no-gutters).mx-sm-2 {
    margin-left: 0.5625rem !important; }
  .row:not(.no-gutters).m-sm-3 {
    margin: 1.6875rem 1.125rem !important; }
  .row:not(.no-gutters).grid.m-sm-3 {
    margin: 1.125rem !important; }
  .row:not(.no-gutters).grid.mt-sm-3, .row:not(.no-gutters).grid.my-sm-3 {
    margin-top: 1.125rem !important; }
  .row:not(.no-gutters).mr-sm-3, .row:not(.no-gutters).mx-sm-3 {
    margin-right: 1.125rem !important; }
  .row:not(.no-gutters).grid.mb-sm-3, .row:not(.no-gutters).grid.my-sm-3 {
    margin-bottom: 1.125rem !important; }
  .row:not(.no-gutters).ml-sm-3, .row:not(.no-gutters).mx-sm-3 {
    margin-left: 1.125rem !important; }
  .row:not(.no-gutters).m-sm-4 {
    margin: 2.25rem 1.6875rem !important; }
  .row:not(.no-gutters).grid.m-sm-4 {
    margin: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mt-sm-4, .row:not(.no-gutters).grid.my-sm-4 {
    margin-top: 1.6875rem !important; }
  .row:not(.no-gutters).mr-sm-4, .row:not(.no-gutters).mx-sm-4 {
    margin-right: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mb-sm-4, .row:not(.no-gutters).grid.my-sm-4 {
    margin-bottom: 1.6875rem !important; }
  .row:not(.no-gutters).ml-sm-4, .row:not(.no-gutters).mx-sm-4 {
    margin-left: 1.6875rem !important; }
  .row:not(.no-gutters).m-sm-5 {
    margin: 2.8125rem 2.25rem !important; }
  .row:not(.no-gutters).grid.m-sm-5 {
    margin: 2.25rem !important; }
  .row:not(.no-gutters).grid.mt-sm-5, .row:not(.no-gutters).grid.my-sm-5 {
    margin-top: 2.25rem !important; }
  .row:not(.no-gutters).mr-sm-5, .row:not(.no-gutters).mx-sm-5 {
    margin-right: 2.25rem !important; }
  .row:not(.no-gutters).grid.mb-sm-5, .row:not(.no-gutters).grid.my-sm-5 {
    margin-bottom: 2.25rem !important; }
  .row:not(.no-gutters).ml-sm-5, .row:not(.no-gutters).mx-sm-5 {
    margin-left: 2.25rem !important; }
  .row:not(.no-gutters).m-sm-6 {
    margin: 3.375rem 2.8125rem !important; }
  .row:not(.no-gutters).grid.m-sm-6 {
    margin: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mt-sm-6, .row:not(.no-gutters).grid.my-sm-6 {
    margin-top: 2.8125rem !important; }
  .row:not(.no-gutters).mr-sm-6, .row:not(.no-gutters).mx-sm-6 {
    margin-right: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mb-sm-6, .row:not(.no-gutters).grid.my-sm-6 {
    margin-bottom: 2.8125rem !important; }
  .row:not(.no-gutters).ml-sm-6, .row:not(.no-gutters).mx-sm-6 {
    margin-left: 2.8125rem !important; }
  .row:not(.no-gutters).m-sm-7 {
    margin: 3.9375rem 3.375rem !important; }
  .row:not(.no-gutters).grid.m-sm-7 {
    margin: 3.375rem !important; }
  .row:not(.no-gutters).grid.mt-sm-7, .row:not(.no-gutters).grid.my-sm-7 {
    margin-top: 3.375rem !important; }
  .row:not(.no-gutters).mr-sm-7, .row:not(.no-gutters).mx-sm-7 {
    margin-right: 3.375rem !important; }
  .row:not(.no-gutters).grid.mb-sm-7, .row:not(.no-gutters).grid.my-sm-7 {
    margin-bottom: 3.375rem !important; }
  .row:not(.no-gutters).ml-sm-7, .row:not(.no-gutters).mx-sm-7 {
    margin-left: 3.375rem !important; }
  .row:not(.no-gutters).m-sm-8 {
    margin: 4.5rem 3.9375rem !important; }
  .row:not(.no-gutters).grid.m-sm-8 {
    margin: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mt-sm-8, .row:not(.no-gutters).grid.my-sm-8 {
    margin-top: 3.9375rem !important; }
  .row:not(.no-gutters).mr-sm-8, .row:not(.no-gutters).mx-sm-8 {
    margin-right: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mb-sm-8, .row:not(.no-gutters).grid.my-sm-8 {
    margin-bottom: 3.9375rem !important; }
  .row:not(.no-gutters).ml-sm-8, .row:not(.no-gutters).mx-sm-8 {
    margin-left: 3.9375rem !important; }
  .row:not(.no-gutters).m-sm-9 {
    margin: 5.0625rem 4.5rem !important; }
  .row:not(.no-gutters).grid.m-sm-9 {
    margin: 4.5rem !important; }
  .row:not(.no-gutters).grid.mt-sm-9, .row:not(.no-gutters).grid.my-sm-9 {
    margin-top: 4.5rem !important; }
  .row:not(.no-gutters).mr-sm-9, .row:not(.no-gutters).mx-sm-9 {
    margin-right: 4.5rem !important; }
  .row:not(.no-gutters).grid.mb-sm-9, .row:not(.no-gutters).grid.my-sm-9 {
    margin-bottom: 4.5rem !important; }
  .row:not(.no-gutters).ml-sm-9, .row:not(.no-gutters).mx-sm-9 {
    margin-left: 4.5rem !important; }
  .row:not(.no-gutters).m-sm-10 {
    margin: 5.625rem 5.0625rem !important; }
  .row:not(.no-gutters).grid.m-sm-10 {
    margin: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mt-sm-10, .row:not(.no-gutters).grid.my-sm-10 {
    margin-top: 5.0625rem !important; }
  .row:not(.no-gutters).mr-sm-10, .row:not(.no-gutters).mx-sm-10 {
    margin-right: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mb-sm-10, .row:not(.no-gutters).grid.my-sm-10 {
    margin-bottom: 5.0625rem !important; }
  .row:not(.no-gutters).ml-sm-10, .row:not(.no-gutters).mx-sm-10 {
    margin-left: 5.0625rem !important; }
  .row:not(.no-gutters).m-sm-11 {
    margin: 6.1875rem 5.625rem !important; }
  .row:not(.no-gutters).grid.m-sm-11 {
    margin: 5.625rem !important; }
  .row:not(.no-gutters).grid.mt-sm-11, .row:not(.no-gutters).grid.my-sm-11 {
    margin-top: 5.625rem !important; }
  .row:not(.no-gutters).mr-sm-11, .row:not(.no-gutters).mx-sm-11 {
    margin-right: 5.625rem !important; }
  .row:not(.no-gutters).grid.mb-sm-11, .row:not(.no-gutters).grid.my-sm-11 {
    margin-bottom: 5.625rem !important; }
  .row:not(.no-gutters).ml-sm-11, .row:not(.no-gutters).mx-sm-11 {
    margin-left: 5.625rem !important; }
  .row:not(.no-gutters).m-sm-12 {
    margin: 6.75rem 6.1875rem !important; }
  .row:not(.no-gutters).grid.m-sm-12 {
    margin: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mt-sm-12, .row:not(.no-gutters).grid.my-sm-12 {
    margin-top: 6.1875rem !important; }
  .row:not(.no-gutters).mr-sm-12, .row:not(.no-gutters).mx-sm-12 {
    margin-right: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mb-sm-12, .row:not(.no-gutters).grid.my-sm-12 {
    margin-bottom: 6.1875rem !important; }
  .row:not(.no-gutters).ml-sm-12, .row:not(.no-gutters).mx-sm-12 {
    margin-left: 6.1875rem !important; }
  .row:not(.no-gutters).m-sm-13 {
    margin: 7.3125rem 6.75rem !important; }
  .row:not(.no-gutters).grid.m-sm-13 {
    margin: 6.75rem !important; }
  .row:not(.no-gutters).grid.mt-sm-13, .row:not(.no-gutters).grid.my-sm-13 {
    margin-top: 6.75rem !important; }
  .row:not(.no-gutters).mr-sm-13, .row:not(.no-gutters).mx-sm-13 {
    margin-right: 6.75rem !important; }
  .row:not(.no-gutters).grid.mb-sm-13, .row:not(.no-gutters).grid.my-sm-13 {
    margin-bottom: 6.75rem !important; }
  .row:not(.no-gutters).ml-sm-13, .row:not(.no-gutters).mx-sm-13 {
    margin-left: 6.75rem !important; }
  .row:not(.no-gutters).m-sm-14 {
    margin: 7.875rem 7.3125rem !important; }
  .row:not(.no-gutters).grid.m-sm-14 {
    margin: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mt-sm-14, .row:not(.no-gutters).grid.my-sm-14 {
    margin-top: 7.3125rem !important; }
  .row:not(.no-gutters).mr-sm-14, .row:not(.no-gutters).mx-sm-14 {
    margin-right: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mb-sm-14, .row:not(.no-gutters).grid.my-sm-14 {
    margin-bottom: 7.3125rem !important; }
  .row:not(.no-gutters).ml-sm-14, .row:not(.no-gutters).mx-sm-14 {
    margin-left: 7.3125rem !important; }
  .row:not(.no-gutters).m-sm-15 {
    margin: 8.4375rem 7.875rem !important; }
  .row:not(.no-gutters).grid.m-sm-15 {
    margin: 7.875rem !important; }
  .row:not(.no-gutters).grid.mt-sm-15, .row:not(.no-gutters).grid.my-sm-15 {
    margin-top: 7.875rem !important; }
  .row:not(.no-gutters).mr-sm-15, .row:not(.no-gutters).mx-sm-15 {
    margin-right: 7.875rem !important; }
  .row:not(.no-gutters).grid.mb-sm-15, .row:not(.no-gutters).grid.my-sm-15 {
    margin-bottom: 7.875rem !important; }
  .row:not(.no-gutters).ml-sm-15, .row:not(.no-gutters).mx-sm-15 {
    margin-left: 7.875rem !important; }
  .row:not(.no-gutters).m-sm-16 {
    margin: 9rem 8.4375rem !important; }
  .row:not(.no-gutters).grid.m-sm-16 {
    margin: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mt-sm-16, .row:not(.no-gutters).grid.my-sm-16 {
    margin-top: 8.4375rem !important; }
  .row:not(.no-gutters).mr-sm-16, .row:not(.no-gutters).mx-sm-16 {
    margin-right: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mb-sm-16, .row:not(.no-gutters).grid.my-sm-16 {
    margin-bottom: 8.4375rem !important; }
  .row:not(.no-gutters).ml-sm-16, .row:not(.no-gutters).mx-sm-16 {
    margin-left: 8.4375rem !important; }
  .row:not(.no-gutters).m-sm-17 {
    margin: 9.5625rem 9rem !important; }
  .row:not(.no-gutters).grid.m-sm-17 {
    margin: 9rem !important; }
  .row:not(.no-gutters).grid.mt-sm-17, .row:not(.no-gutters).grid.my-sm-17 {
    margin-top: 9rem !important; }
  .row:not(.no-gutters).mr-sm-17, .row:not(.no-gutters).mx-sm-17 {
    margin-right: 9rem !important; }
  .row:not(.no-gutters).grid.mb-sm-17, .row:not(.no-gutters).grid.my-sm-17 {
    margin-bottom: 9rem !important; }
  .row:not(.no-gutters).ml-sm-17, .row:not(.no-gutters).mx-sm-17 {
    margin-left: 9rem !important; }
  .row:not(.no-gutters).m-sm-18 {
    margin: 10.125rem 9.5625rem !important; }
  .row:not(.no-gutters).grid.m-sm-18 {
    margin: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mt-sm-18, .row:not(.no-gutters).grid.my-sm-18 {
    margin-top: 9.5625rem !important; }
  .row:not(.no-gutters).mr-sm-18, .row:not(.no-gutters).mx-sm-18 {
    margin-right: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mb-sm-18, .row:not(.no-gutters).grid.my-sm-18 {
    margin-bottom: 9.5625rem !important; }
  .row:not(.no-gutters).ml-sm-18, .row:not(.no-gutters).mx-sm-18 {
    margin-left: 9.5625rem !important; }
  .row:not(.no-gutters).m-sm-19 {
    margin: 10.6875rem 10.125rem !important; }
  .row:not(.no-gutters).grid.m-sm-19 {
    margin: 10.125rem !important; }
  .row:not(.no-gutters).grid.mt-sm-19, .row:not(.no-gutters).grid.my-sm-19 {
    margin-top: 10.125rem !important; }
  .row:not(.no-gutters).mr-sm-19, .row:not(.no-gutters).mx-sm-19 {
    margin-right: 10.125rem !important; }
  .row:not(.no-gutters).grid.mb-sm-19, .row:not(.no-gutters).grid.my-sm-19 {
    margin-bottom: 10.125rem !important; }
  .row:not(.no-gutters).ml-sm-19, .row:not(.no-gutters).mx-sm-19 {
    margin-left: 10.125rem !important; }
  .row:not(.no-gutters).m-sm-20 {
    margin: 11.25rem 10.6875rem !important; }
  .row:not(.no-gutters).grid.m-sm-20 {
    margin: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mt-sm-20, .row:not(.no-gutters).grid.my-sm-20 {
    margin-top: 10.6875rem !important; }
  .row:not(.no-gutters).mr-sm-20, .row:not(.no-gutters).mx-sm-20 {
    margin-right: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mb-sm-20, .row:not(.no-gutters).grid.my-sm-20 {
    margin-bottom: 10.6875rem !important; }
  .row:not(.no-gutters).ml-sm-20, .row:not(.no-gutters).mx-sm-20 {
    margin-left: 10.6875rem !important; } }

@media (min-width: 768px) {
  .row:not(.no-gutters).m-md-0 {
    margin: 0 -0.5625rem !important; }
  .row:not(.no-gutters).grid.m-md-0 {
    margin: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-md-0, .row:not(.no-gutters).grid.my-md-0 {
    margin-top: -0.5625rem !important; }
  .row:not(.no-gutters).mr-md-0, .row:not(.no-gutters).mx-md-0 {
    margin-right: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-md-0, .row:not(.no-gutters).grid.my-md-0 {
    margin-bottom: -0.5625rem !important; }
  .row:not(.no-gutters).ml-md-0, .row:not(.no-gutters).mx-md-0 {
    margin-left: -0.5625rem !important; }
  .row:not(.no-gutters).m-md-1 {
    margin: 0.5625rem 0rem !important; }
  .row:not(.no-gutters).grid.m-md-1 {
    margin: 0rem !important; }
  .row:not(.no-gutters).grid.mt-md-1, .row:not(.no-gutters).grid.my-md-1 {
    margin-top: 0rem !important; }
  .row:not(.no-gutters).mr-md-1, .row:not(.no-gutters).mx-md-1 {
    margin-right: 0rem !important; }
  .row:not(.no-gutters).grid.mb-md-1, .row:not(.no-gutters).grid.my-md-1 {
    margin-bottom: 0rem !important; }
  .row:not(.no-gutters).ml-md-1, .row:not(.no-gutters).mx-md-1 {
    margin-left: 0rem !important; }
  .row:not(.no-gutters).m-md-2 {
    margin: 1.125rem 0.5625rem !important; }
  .row:not(.no-gutters).grid.m-md-2 {
    margin: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-md-2, .row:not(.no-gutters).grid.my-md-2 {
    margin-top: 0.5625rem !important; }
  .row:not(.no-gutters).mr-md-2, .row:not(.no-gutters).mx-md-2 {
    margin-right: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-md-2, .row:not(.no-gutters).grid.my-md-2 {
    margin-bottom: 0.5625rem !important; }
  .row:not(.no-gutters).ml-md-2, .row:not(.no-gutters).mx-md-2 {
    margin-left: 0.5625rem !important; }
  .row:not(.no-gutters).m-md-3 {
    margin: 1.6875rem 1.125rem !important; }
  .row:not(.no-gutters).grid.m-md-3 {
    margin: 1.125rem !important; }
  .row:not(.no-gutters).grid.mt-md-3, .row:not(.no-gutters).grid.my-md-3 {
    margin-top: 1.125rem !important; }
  .row:not(.no-gutters).mr-md-3, .row:not(.no-gutters).mx-md-3 {
    margin-right: 1.125rem !important; }
  .row:not(.no-gutters).grid.mb-md-3, .row:not(.no-gutters).grid.my-md-3 {
    margin-bottom: 1.125rem !important; }
  .row:not(.no-gutters).ml-md-3, .row:not(.no-gutters).mx-md-3 {
    margin-left: 1.125rem !important; }
  .row:not(.no-gutters).m-md-4 {
    margin: 2.25rem 1.6875rem !important; }
  .row:not(.no-gutters).grid.m-md-4 {
    margin: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mt-md-4, .row:not(.no-gutters).grid.my-md-4 {
    margin-top: 1.6875rem !important; }
  .row:not(.no-gutters).mr-md-4, .row:not(.no-gutters).mx-md-4 {
    margin-right: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mb-md-4, .row:not(.no-gutters).grid.my-md-4 {
    margin-bottom: 1.6875rem !important; }
  .row:not(.no-gutters).ml-md-4, .row:not(.no-gutters).mx-md-4 {
    margin-left: 1.6875rem !important; }
  .row:not(.no-gutters).m-md-5 {
    margin: 2.8125rem 2.25rem !important; }
  .row:not(.no-gutters).grid.m-md-5 {
    margin: 2.25rem !important; }
  .row:not(.no-gutters).grid.mt-md-5, .row:not(.no-gutters).grid.my-md-5 {
    margin-top: 2.25rem !important; }
  .row:not(.no-gutters).mr-md-5, .row:not(.no-gutters).mx-md-5 {
    margin-right: 2.25rem !important; }
  .row:not(.no-gutters).grid.mb-md-5, .row:not(.no-gutters).grid.my-md-5 {
    margin-bottom: 2.25rem !important; }
  .row:not(.no-gutters).ml-md-5, .row:not(.no-gutters).mx-md-5 {
    margin-left: 2.25rem !important; }
  .row:not(.no-gutters).m-md-6 {
    margin: 3.375rem 2.8125rem !important; }
  .row:not(.no-gutters).grid.m-md-6 {
    margin: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mt-md-6, .row:not(.no-gutters).grid.my-md-6 {
    margin-top: 2.8125rem !important; }
  .row:not(.no-gutters).mr-md-6, .row:not(.no-gutters).mx-md-6 {
    margin-right: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mb-md-6, .row:not(.no-gutters).grid.my-md-6 {
    margin-bottom: 2.8125rem !important; }
  .row:not(.no-gutters).ml-md-6, .row:not(.no-gutters).mx-md-6 {
    margin-left: 2.8125rem !important; }
  .row:not(.no-gutters).m-md-7 {
    margin: 3.9375rem 3.375rem !important; }
  .row:not(.no-gutters).grid.m-md-7 {
    margin: 3.375rem !important; }
  .row:not(.no-gutters).grid.mt-md-7, .row:not(.no-gutters).grid.my-md-7 {
    margin-top: 3.375rem !important; }
  .row:not(.no-gutters).mr-md-7, .row:not(.no-gutters).mx-md-7 {
    margin-right: 3.375rem !important; }
  .row:not(.no-gutters).grid.mb-md-7, .row:not(.no-gutters).grid.my-md-7 {
    margin-bottom: 3.375rem !important; }
  .row:not(.no-gutters).ml-md-7, .row:not(.no-gutters).mx-md-7 {
    margin-left: 3.375rem !important; }
  .row:not(.no-gutters).m-md-8 {
    margin: 4.5rem 3.9375rem !important; }
  .row:not(.no-gutters).grid.m-md-8 {
    margin: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mt-md-8, .row:not(.no-gutters).grid.my-md-8 {
    margin-top: 3.9375rem !important; }
  .row:not(.no-gutters).mr-md-8, .row:not(.no-gutters).mx-md-8 {
    margin-right: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mb-md-8, .row:not(.no-gutters).grid.my-md-8 {
    margin-bottom: 3.9375rem !important; }
  .row:not(.no-gutters).ml-md-8, .row:not(.no-gutters).mx-md-8 {
    margin-left: 3.9375rem !important; }
  .row:not(.no-gutters).m-md-9 {
    margin: 5.0625rem 4.5rem !important; }
  .row:not(.no-gutters).grid.m-md-9 {
    margin: 4.5rem !important; }
  .row:not(.no-gutters).grid.mt-md-9, .row:not(.no-gutters).grid.my-md-9 {
    margin-top: 4.5rem !important; }
  .row:not(.no-gutters).mr-md-9, .row:not(.no-gutters).mx-md-9 {
    margin-right: 4.5rem !important; }
  .row:not(.no-gutters).grid.mb-md-9, .row:not(.no-gutters).grid.my-md-9 {
    margin-bottom: 4.5rem !important; }
  .row:not(.no-gutters).ml-md-9, .row:not(.no-gutters).mx-md-9 {
    margin-left: 4.5rem !important; }
  .row:not(.no-gutters).m-md-10 {
    margin: 5.625rem 5.0625rem !important; }
  .row:not(.no-gutters).grid.m-md-10 {
    margin: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mt-md-10, .row:not(.no-gutters).grid.my-md-10 {
    margin-top: 5.0625rem !important; }
  .row:not(.no-gutters).mr-md-10, .row:not(.no-gutters).mx-md-10 {
    margin-right: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mb-md-10, .row:not(.no-gutters).grid.my-md-10 {
    margin-bottom: 5.0625rem !important; }
  .row:not(.no-gutters).ml-md-10, .row:not(.no-gutters).mx-md-10 {
    margin-left: 5.0625rem !important; }
  .row:not(.no-gutters).m-md-11 {
    margin: 6.1875rem 5.625rem !important; }
  .row:not(.no-gutters).grid.m-md-11 {
    margin: 5.625rem !important; }
  .row:not(.no-gutters).grid.mt-md-11, .row:not(.no-gutters).grid.my-md-11 {
    margin-top: 5.625rem !important; }
  .row:not(.no-gutters).mr-md-11, .row:not(.no-gutters).mx-md-11 {
    margin-right: 5.625rem !important; }
  .row:not(.no-gutters).grid.mb-md-11, .row:not(.no-gutters).grid.my-md-11 {
    margin-bottom: 5.625rem !important; }
  .row:not(.no-gutters).ml-md-11, .row:not(.no-gutters).mx-md-11 {
    margin-left: 5.625rem !important; }
  .row:not(.no-gutters).m-md-12 {
    margin: 6.75rem 6.1875rem !important; }
  .row:not(.no-gutters).grid.m-md-12 {
    margin: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mt-md-12, .row:not(.no-gutters).grid.my-md-12 {
    margin-top: 6.1875rem !important; }
  .row:not(.no-gutters).mr-md-12, .row:not(.no-gutters).mx-md-12 {
    margin-right: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mb-md-12, .row:not(.no-gutters).grid.my-md-12 {
    margin-bottom: 6.1875rem !important; }
  .row:not(.no-gutters).ml-md-12, .row:not(.no-gutters).mx-md-12 {
    margin-left: 6.1875rem !important; }
  .row:not(.no-gutters).m-md-13 {
    margin: 7.3125rem 6.75rem !important; }
  .row:not(.no-gutters).grid.m-md-13 {
    margin: 6.75rem !important; }
  .row:not(.no-gutters).grid.mt-md-13, .row:not(.no-gutters).grid.my-md-13 {
    margin-top: 6.75rem !important; }
  .row:not(.no-gutters).mr-md-13, .row:not(.no-gutters).mx-md-13 {
    margin-right: 6.75rem !important; }
  .row:not(.no-gutters).grid.mb-md-13, .row:not(.no-gutters).grid.my-md-13 {
    margin-bottom: 6.75rem !important; }
  .row:not(.no-gutters).ml-md-13, .row:not(.no-gutters).mx-md-13 {
    margin-left: 6.75rem !important; }
  .row:not(.no-gutters).m-md-14 {
    margin: 7.875rem 7.3125rem !important; }
  .row:not(.no-gutters).grid.m-md-14 {
    margin: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mt-md-14, .row:not(.no-gutters).grid.my-md-14 {
    margin-top: 7.3125rem !important; }
  .row:not(.no-gutters).mr-md-14, .row:not(.no-gutters).mx-md-14 {
    margin-right: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mb-md-14, .row:not(.no-gutters).grid.my-md-14 {
    margin-bottom: 7.3125rem !important; }
  .row:not(.no-gutters).ml-md-14, .row:not(.no-gutters).mx-md-14 {
    margin-left: 7.3125rem !important; }
  .row:not(.no-gutters).m-md-15 {
    margin: 8.4375rem 7.875rem !important; }
  .row:not(.no-gutters).grid.m-md-15 {
    margin: 7.875rem !important; }
  .row:not(.no-gutters).grid.mt-md-15, .row:not(.no-gutters).grid.my-md-15 {
    margin-top: 7.875rem !important; }
  .row:not(.no-gutters).mr-md-15, .row:not(.no-gutters).mx-md-15 {
    margin-right: 7.875rem !important; }
  .row:not(.no-gutters).grid.mb-md-15, .row:not(.no-gutters).grid.my-md-15 {
    margin-bottom: 7.875rem !important; }
  .row:not(.no-gutters).ml-md-15, .row:not(.no-gutters).mx-md-15 {
    margin-left: 7.875rem !important; }
  .row:not(.no-gutters).m-md-16 {
    margin: 9rem 8.4375rem !important; }
  .row:not(.no-gutters).grid.m-md-16 {
    margin: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mt-md-16, .row:not(.no-gutters).grid.my-md-16 {
    margin-top: 8.4375rem !important; }
  .row:not(.no-gutters).mr-md-16, .row:not(.no-gutters).mx-md-16 {
    margin-right: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mb-md-16, .row:not(.no-gutters).grid.my-md-16 {
    margin-bottom: 8.4375rem !important; }
  .row:not(.no-gutters).ml-md-16, .row:not(.no-gutters).mx-md-16 {
    margin-left: 8.4375rem !important; }
  .row:not(.no-gutters).m-md-17 {
    margin: 9.5625rem 9rem !important; }
  .row:not(.no-gutters).grid.m-md-17 {
    margin: 9rem !important; }
  .row:not(.no-gutters).grid.mt-md-17, .row:not(.no-gutters).grid.my-md-17 {
    margin-top: 9rem !important; }
  .row:not(.no-gutters).mr-md-17, .row:not(.no-gutters).mx-md-17 {
    margin-right: 9rem !important; }
  .row:not(.no-gutters).grid.mb-md-17, .row:not(.no-gutters).grid.my-md-17 {
    margin-bottom: 9rem !important; }
  .row:not(.no-gutters).ml-md-17, .row:not(.no-gutters).mx-md-17 {
    margin-left: 9rem !important; }
  .row:not(.no-gutters).m-md-18 {
    margin: 10.125rem 9.5625rem !important; }
  .row:not(.no-gutters).grid.m-md-18 {
    margin: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mt-md-18, .row:not(.no-gutters).grid.my-md-18 {
    margin-top: 9.5625rem !important; }
  .row:not(.no-gutters).mr-md-18, .row:not(.no-gutters).mx-md-18 {
    margin-right: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mb-md-18, .row:not(.no-gutters).grid.my-md-18 {
    margin-bottom: 9.5625rem !important; }
  .row:not(.no-gutters).ml-md-18, .row:not(.no-gutters).mx-md-18 {
    margin-left: 9.5625rem !important; }
  .row:not(.no-gutters).m-md-19 {
    margin: 10.6875rem 10.125rem !important; }
  .row:not(.no-gutters).grid.m-md-19 {
    margin: 10.125rem !important; }
  .row:not(.no-gutters).grid.mt-md-19, .row:not(.no-gutters).grid.my-md-19 {
    margin-top: 10.125rem !important; }
  .row:not(.no-gutters).mr-md-19, .row:not(.no-gutters).mx-md-19 {
    margin-right: 10.125rem !important; }
  .row:not(.no-gutters).grid.mb-md-19, .row:not(.no-gutters).grid.my-md-19 {
    margin-bottom: 10.125rem !important; }
  .row:not(.no-gutters).ml-md-19, .row:not(.no-gutters).mx-md-19 {
    margin-left: 10.125rem !important; }
  .row:not(.no-gutters).m-md-20 {
    margin: 11.25rem 10.6875rem !important; }
  .row:not(.no-gutters).grid.m-md-20 {
    margin: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mt-md-20, .row:not(.no-gutters).grid.my-md-20 {
    margin-top: 10.6875rem !important; }
  .row:not(.no-gutters).mr-md-20, .row:not(.no-gutters).mx-md-20 {
    margin-right: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mb-md-20, .row:not(.no-gutters).grid.my-md-20 {
    margin-bottom: 10.6875rem !important; }
  .row:not(.no-gutters).ml-md-20, .row:not(.no-gutters).mx-md-20 {
    margin-left: 10.6875rem !important; } }

@media (min-width: 992px) {
  .row:not(.no-gutters).m-lg-0 {
    margin: 0 -0.5625rem !important; }
  .row:not(.no-gutters).grid.m-lg-0 {
    margin: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-lg-0, .row:not(.no-gutters).grid.my-lg-0 {
    margin-top: -0.5625rem !important; }
  .row:not(.no-gutters).mr-lg-0, .row:not(.no-gutters).mx-lg-0 {
    margin-right: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-lg-0, .row:not(.no-gutters).grid.my-lg-0 {
    margin-bottom: -0.5625rem !important; }
  .row:not(.no-gutters).ml-lg-0, .row:not(.no-gutters).mx-lg-0 {
    margin-left: -0.5625rem !important; }
  .row:not(.no-gutters).m-lg-1 {
    margin: 0.5625rem 0rem !important; }
  .row:not(.no-gutters).grid.m-lg-1 {
    margin: 0rem !important; }
  .row:not(.no-gutters).grid.mt-lg-1, .row:not(.no-gutters).grid.my-lg-1 {
    margin-top: 0rem !important; }
  .row:not(.no-gutters).mr-lg-1, .row:not(.no-gutters).mx-lg-1 {
    margin-right: 0rem !important; }
  .row:not(.no-gutters).grid.mb-lg-1, .row:not(.no-gutters).grid.my-lg-1 {
    margin-bottom: 0rem !important; }
  .row:not(.no-gutters).ml-lg-1, .row:not(.no-gutters).mx-lg-1 {
    margin-left: 0rem !important; }
  .row:not(.no-gutters).m-lg-2 {
    margin: 1.125rem 0.5625rem !important; }
  .row:not(.no-gutters).grid.m-lg-2 {
    margin: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-lg-2, .row:not(.no-gutters).grid.my-lg-2 {
    margin-top: 0.5625rem !important; }
  .row:not(.no-gutters).mr-lg-2, .row:not(.no-gutters).mx-lg-2 {
    margin-right: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-lg-2, .row:not(.no-gutters).grid.my-lg-2 {
    margin-bottom: 0.5625rem !important; }
  .row:not(.no-gutters).ml-lg-2, .row:not(.no-gutters).mx-lg-2 {
    margin-left: 0.5625rem !important; }
  .row:not(.no-gutters).m-lg-3 {
    margin: 1.6875rem 1.125rem !important; }
  .row:not(.no-gutters).grid.m-lg-3 {
    margin: 1.125rem !important; }
  .row:not(.no-gutters).grid.mt-lg-3, .row:not(.no-gutters).grid.my-lg-3 {
    margin-top: 1.125rem !important; }
  .row:not(.no-gutters).mr-lg-3, .row:not(.no-gutters).mx-lg-3 {
    margin-right: 1.125rem !important; }
  .row:not(.no-gutters).grid.mb-lg-3, .row:not(.no-gutters).grid.my-lg-3 {
    margin-bottom: 1.125rem !important; }
  .row:not(.no-gutters).ml-lg-3, .row:not(.no-gutters).mx-lg-3 {
    margin-left: 1.125rem !important; }
  .row:not(.no-gutters).m-lg-4 {
    margin: 2.25rem 1.6875rem !important; }
  .row:not(.no-gutters).grid.m-lg-4 {
    margin: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mt-lg-4, .row:not(.no-gutters).grid.my-lg-4 {
    margin-top: 1.6875rem !important; }
  .row:not(.no-gutters).mr-lg-4, .row:not(.no-gutters).mx-lg-4 {
    margin-right: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mb-lg-4, .row:not(.no-gutters).grid.my-lg-4 {
    margin-bottom: 1.6875rem !important; }
  .row:not(.no-gutters).ml-lg-4, .row:not(.no-gutters).mx-lg-4 {
    margin-left: 1.6875rem !important; }
  .row:not(.no-gutters).m-lg-5 {
    margin: 2.8125rem 2.25rem !important; }
  .row:not(.no-gutters).grid.m-lg-5 {
    margin: 2.25rem !important; }
  .row:not(.no-gutters).grid.mt-lg-5, .row:not(.no-gutters).grid.my-lg-5 {
    margin-top: 2.25rem !important; }
  .row:not(.no-gutters).mr-lg-5, .row:not(.no-gutters).mx-lg-5 {
    margin-right: 2.25rem !important; }
  .row:not(.no-gutters).grid.mb-lg-5, .row:not(.no-gutters).grid.my-lg-5 {
    margin-bottom: 2.25rem !important; }
  .row:not(.no-gutters).ml-lg-5, .row:not(.no-gutters).mx-lg-5 {
    margin-left: 2.25rem !important; }
  .row:not(.no-gutters).m-lg-6 {
    margin: 3.375rem 2.8125rem !important; }
  .row:not(.no-gutters).grid.m-lg-6 {
    margin: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mt-lg-6, .row:not(.no-gutters).grid.my-lg-6 {
    margin-top: 2.8125rem !important; }
  .row:not(.no-gutters).mr-lg-6, .row:not(.no-gutters).mx-lg-6 {
    margin-right: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mb-lg-6, .row:not(.no-gutters).grid.my-lg-6 {
    margin-bottom: 2.8125rem !important; }
  .row:not(.no-gutters).ml-lg-6, .row:not(.no-gutters).mx-lg-6 {
    margin-left: 2.8125rem !important; }
  .row:not(.no-gutters).m-lg-7 {
    margin: 3.9375rem 3.375rem !important; }
  .row:not(.no-gutters).grid.m-lg-7 {
    margin: 3.375rem !important; }
  .row:not(.no-gutters).grid.mt-lg-7, .row:not(.no-gutters).grid.my-lg-7 {
    margin-top: 3.375rem !important; }
  .row:not(.no-gutters).mr-lg-7, .row:not(.no-gutters).mx-lg-7 {
    margin-right: 3.375rem !important; }
  .row:not(.no-gutters).grid.mb-lg-7, .row:not(.no-gutters).grid.my-lg-7 {
    margin-bottom: 3.375rem !important; }
  .row:not(.no-gutters).ml-lg-7, .row:not(.no-gutters).mx-lg-7 {
    margin-left: 3.375rem !important; }
  .row:not(.no-gutters).m-lg-8 {
    margin: 4.5rem 3.9375rem !important; }
  .row:not(.no-gutters).grid.m-lg-8 {
    margin: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mt-lg-8, .row:not(.no-gutters).grid.my-lg-8 {
    margin-top: 3.9375rem !important; }
  .row:not(.no-gutters).mr-lg-8, .row:not(.no-gutters).mx-lg-8 {
    margin-right: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mb-lg-8, .row:not(.no-gutters).grid.my-lg-8 {
    margin-bottom: 3.9375rem !important; }
  .row:not(.no-gutters).ml-lg-8, .row:not(.no-gutters).mx-lg-8 {
    margin-left: 3.9375rem !important; }
  .row:not(.no-gutters).m-lg-9 {
    margin: 5.0625rem 4.5rem !important; }
  .row:not(.no-gutters).grid.m-lg-9 {
    margin: 4.5rem !important; }
  .row:not(.no-gutters).grid.mt-lg-9, .row:not(.no-gutters).grid.my-lg-9 {
    margin-top: 4.5rem !important; }
  .row:not(.no-gutters).mr-lg-9, .row:not(.no-gutters).mx-lg-9 {
    margin-right: 4.5rem !important; }
  .row:not(.no-gutters).grid.mb-lg-9, .row:not(.no-gutters).grid.my-lg-9 {
    margin-bottom: 4.5rem !important; }
  .row:not(.no-gutters).ml-lg-9, .row:not(.no-gutters).mx-lg-9 {
    margin-left: 4.5rem !important; }
  .row:not(.no-gutters).m-lg-10 {
    margin: 5.625rem 5.0625rem !important; }
  .row:not(.no-gutters).grid.m-lg-10 {
    margin: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mt-lg-10, .row:not(.no-gutters).grid.my-lg-10 {
    margin-top: 5.0625rem !important; }
  .row:not(.no-gutters).mr-lg-10, .row:not(.no-gutters).mx-lg-10 {
    margin-right: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mb-lg-10, .row:not(.no-gutters).grid.my-lg-10 {
    margin-bottom: 5.0625rem !important; }
  .row:not(.no-gutters).ml-lg-10, .row:not(.no-gutters).mx-lg-10 {
    margin-left: 5.0625rem !important; }
  .row:not(.no-gutters).m-lg-11 {
    margin: 6.1875rem 5.625rem !important; }
  .row:not(.no-gutters).grid.m-lg-11 {
    margin: 5.625rem !important; }
  .row:not(.no-gutters).grid.mt-lg-11, .row:not(.no-gutters).grid.my-lg-11 {
    margin-top: 5.625rem !important; }
  .row:not(.no-gutters).mr-lg-11, .row:not(.no-gutters).mx-lg-11 {
    margin-right: 5.625rem !important; }
  .row:not(.no-gutters).grid.mb-lg-11, .row:not(.no-gutters).grid.my-lg-11 {
    margin-bottom: 5.625rem !important; }
  .row:not(.no-gutters).ml-lg-11, .row:not(.no-gutters).mx-lg-11 {
    margin-left: 5.625rem !important; }
  .row:not(.no-gutters).m-lg-12 {
    margin: 6.75rem 6.1875rem !important; }
  .row:not(.no-gutters).grid.m-lg-12 {
    margin: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mt-lg-12, .row:not(.no-gutters).grid.my-lg-12 {
    margin-top: 6.1875rem !important; }
  .row:not(.no-gutters).mr-lg-12, .row:not(.no-gutters).mx-lg-12 {
    margin-right: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mb-lg-12, .row:not(.no-gutters).grid.my-lg-12 {
    margin-bottom: 6.1875rem !important; }
  .row:not(.no-gutters).ml-lg-12, .row:not(.no-gutters).mx-lg-12 {
    margin-left: 6.1875rem !important; }
  .row:not(.no-gutters).m-lg-13 {
    margin: 7.3125rem 6.75rem !important; }
  .row:not(.no-gutters).grid.m-lg-13 {
    margin: 6.75rem !important; }
  .row:not(.no-gutters).grid.mt-lg-13, .row:not(.no-gutters).grid.my-lg-13 {
    margin-top: 6.75rem !important; }
  .row:not(.no-gutters).mr-lg-13, .row:not(.no-gutters).mx-lg-13 {
    margin-right: 6.75rem !important; }
  .row:not(.no-gutters).grid.mb-lg-13, .row:not(.no-gutters).grid.my-lg-13 {
    margin-bottom: 6.75rem !important; }
  .row:not(.no-gutters).ml-lg-13, .row:not(.no-gutters).mx-lg-13 {
    margin-left: 6.75rem !important; }
  .row:not(.no-gutters).m-lg-14 {
    margin: 7.875rem 7.3125rem !important; }
  .row:not(.no-gutters).grid.m-lg-14 {
    margin: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mt-lg-14, .row:not(.no-gutters).grid.my-lg-14 {
    margin-top: 7.3125rem !important; }
  .row:not(.no-gutters).mr-lg-14, .row:not(.no-gutters).mx-lg-14 {
    margin-right: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mb-lg-14, .row:not(.no-gutters).grid.my-lg-14 {
    margin-bottom: 7.3125rem !important; }
  .row:not(.no-gutters).ml-lg-14, .row:not(.no-gutters).mx-lg-14 {
    margin-left: 7.3125rem !important; }
  .row:not(.no-gutters).m-lg-15 {
    margin: 8.4375rem 7.875rem !important; }
  .row:not(.no-gutters).grid.m-lg-15 {
    margin: 7.875rem !important; }
  .row:not(.no-gutters).grid.mt-lg-15, .row:not(.no-gutters).grid.my-lg-15 {
    margin-top: 7.875rem !important; }
  .row:not(.no-gutters).mr-lg-15, .row:not(.no-gutters).mx-lg-15 {
    margin-right: 7.875rem !important; }
  .row:not(.no-gutters).grid.mb-lg-15, .row:not(.no-gutters).grid.my-lg-15 {
    margin-bottom: 7.875rem !important; }
  .row:not(.no-gutters).ml-lg-15, .row:not(.no-gutters).mx-lg-15 {
    margin-left: 7.875rem !important; }
  .row:not(.no-gutters).m-lg-16 {
    margin: 9rem 8.4375rem !important; }
  .row:not(.no-gutters).grid.m-lg-16 {
    margin: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mt-lg-16, .row:not(.no-gutters).grid.my-lg-16 {
    margin-top: 8.4375rem !important; }
  .row:not(.no-gutters).mr-lg-16, .row:not(.no-gutters).mx-lg-16 {
    margin-right: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mb-lg-16, .row:not(.no-gutters).grid.my-lg-16 {
    margin-bottom: 8.4375rem !important; }
  .row:not(.no-gutters).ml-lg-16, .row:not(.no-gutters).mx-lg-16 {
    margin-left: 8.4375rem !important; }
  .row:not(.no-gutters).m-lg-17 {
    margin: 9.5625rem 9rem !important; }
  .row:not(.no-gutters).grid.m-lg-17 {
    margin: 9rem !important; }
  .row:not(.no-gutters).grid.mt-lg-17, .row:not(.no-gutters).grid.my-lg-17 {
    margin-top: 9rem !important; }
  .row:not(.no-gutters).mr-lg-17, .row:not(.no-gutters).mx-lg-17 {
    margin-right: 9rem !important; }
  .row:not(.no-gutters).grid.mb-lg-17, .row:not(.no-gutters).grid.my-lg-17 {
    margin-bottom: 9rem !important; }
  .row:not(.no-gutters).ml-lg-17, .row:not(.no-gutters).mx-lg-17 {
    margin-left: 9rem !important; }
  .row:not(.no-gutters).m-lg-18 {
    margin: 10.125rem 9.5625rem !important; }
  .row:not(.no-gutters).grid.m-lg-18 {
    margin: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mt-lg-18, .row:not(.no-gutters).grid.my-lg-18 {
    margin-top: 9.5625rem !important; }
  .row:not(.no-gutters).mr-lg-18, .row:not(.no-gutters).mx-lg-18 {
    margin-right: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mb-lg-18, .row:not(.no-gutters).grid.my-lg-18 {
    margin-bottom: 9.5625rem !important; }
  .row:not(.no-gutters).ml-lg-18, .row:not(.no-gutters).mx-lg-18 {
    margin-left: 9.5625rem !important; }
  .row:not(.no-gutters).m-lg-19 {
    margin: 10.6875rem 10.125rem !important; }
  .row:not(.no-gutters).grid.m-lg-19 {
    margin: 10.125rem !important; }
  .row:not(.no-gutters).grid.mt-lg-19, .row:not(.no-gutters).grid.my-lg-19 {
    margin-top: 10.125rem !important; }
  .row:not(.no-gutters).mr-lg-19, .row:not(.no-gutters).mx-lg-19 {
    margin-right: 10.125rem !important; }
  .row:not(.no-gutters).grid.mb-lg-19, .row:not(.no-gutters).grid.my-lg-19 {
    margin-bottom: 10.125rem !important; }
  .row:not(.no-gutters).ml-lg-19, .row:not(.no-gutters).mx-lg-19 {
    margin-left: 10.125rem !important; }
  .row:not(.no-gutters).m-lg-20 {
    margin: 11.25rem 10.6875rem !important; }
  .row:not(.no-gutters).grid.m-lg-20 {
    margin: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mt-lg-20, .row:not(.no-gutters).grid.my-lg-20 {
    margin-top: 10.6875rem !important; }
  .row:not(.no-gutters).mr-lg-20, .row:not(.no-gutters).mx-lg-20 {
    margin-right: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mb-lg-20, .row:not(.no-gutters).grid.my-lg-20 {
    margin-bottom: 10.6875rem !important; }
  .row:not(.no-gutters).ml-lg-20, .row:not(.no-gutters).mx-lg-20 {
    margin-left: 10.6875rem !important; } }

@media (min-width: 1200px) {
  .row:not(.no-gutters).m-xl-0 {
    margin: 0 -0.5625rem !important; }
  .row:not(.no-gutters).grid.m-xl-0 {
    margin: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-xl-0, .row:not(.no-gutters).grid.my-xl-0 {
    margin-top: -0.5625rem !important; }
  .row:not(.no-gutters).mr-xl-0, .row:not(.no-gutters).mx-xl-0 {
    margin-right: -0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-xl-0, .row:not(.no-gutters).grid.my-xl-0 {
    margin-bottom: -0.5625rem !important; }
  .row:not(.no-gutters).ml-xl-0, .row:not(.no-gutters).mx-xl-0 {
    margin-left: -0.5625rem !important; }
  .row:not(.no-gutters).m-xl-1 {
    margin: 0.5625rem 0rem !important; }
  .row:not(.no-gutters).grid.m-xl-1 {
    margin: 0rem !important; }
  .row:not(.no-gutters).grid.mt-xl-1, .row:not(.no-gutters).grid.my-xl-1 {
    margin-top: 0rem !important; }
  .row:not(.no-gutters).mr-xl-1, .row:not(.no-gutters).mx-xl-1 {
    margin-right: 0rem !important; }
  .row:not(.no-gutters).grid.mb-xl-1, .row:not(.no-gutters).grid.my-xl-1 {
    margin-bottom: 0rem !important; }
  .row:not(.no-gutters).ml-xl-1, .row:not(.no-gutters).mx-xl-1 {
    margin-left: 0rem !important; }
  .row:not(.no-gutters).m-xl-2 {
    margin: 1.125rem 0.5625rem !important; }
  .row:not(.no-gutters).grid.m-xl-2 {
    margin: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mt-xl-2, .row:not(.no-gutters).grid.my-xl-2 {
    margin-top: 0.5625rem !important; }
  .row:not(.no-gutters).mr-xl-2, .row:not(.no-gutters).mx-xl-2 {
    margin-right: 0.5625rem !important; }
  .row:not(.no-gutters).grid.mb-xl-2, .row:not(.no-gutters).grid.my-xl-2 {
    margin-bottom: 0.5625rem !important; }
  .row:not(.no-gutters).ml-xl-2, .row:not(.no-gutters).mx-xl-2 {
    margin-left: 0.5625rem !important; }
  .row:not(.no-gutters).m-xl-3 {
    margin: 1.6875rem 1.125rem !important; }
  .row:not(.no-gutters).grid.m-xl-3 {
    margin: 1.125rem !important; }
  .row:not(.no-gutters).grid.mt-xl-3, .row:not(.no-gutters).grid.my-xl-3 {
    margin-top: 1.125rem !important; }
  .row:not(.no-gutters).mr-xl-3, .row:not(.no-gutters).mx-xl-3 {
    margin-right: 1.125rem !important; }
  .row:not(.no-gutters).grid.mb-xl-3, .row:not(.no-gutters).grid.my-xl-3 {
    margin-bottom: 1.125rem !important; }
  .row:not(.no-gutters).ml-xl-3, .row:not(.no-gutters).mx-xl-3 {
    margin-left: 1.125rem !important; }
  .row:not(.no-gutters).m-xl-4 {
    margin: 2.25rem 1.6875rem !important; }
  .row:not(.no-gutters).grid.m-xl-4 {
    margin: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mt-xl-4, .row:not(.no-gutters).grid.my-xl-4 {
    margin-top: 1.6875rem !important; }
  .row:not(.no-gutters).mr-xl-4, .row:not(.no-gutters).mx-xl-4 {
    margin-right: 1.6875rem !important; }
  .row:not(.no-gutters).grid.mb-xl-4, .row:not(.no-gutters).grid.my-xl-4 {
    margin-bottom: 1.6875rem !important; }
  .row:not(.no-gutters).ml-xl-4, .row:not(.no-gutters).mx-xl-4 {
    margin-left: 1.6875rem !important; }
  .row:not(.no-gutters).m-xl-5 {
    margin: 2.8125rem 2.25rem !important; }
  .row:not(.no-gutters).grid.m-xl-5 {
    margin: 2.25rem !important; }
  .row:not(.no-gutters).grid.mt-xl-5, .row:not(.no-gutters).grid.my-xl-5 {
    margin-top: 2.25rem !important; }
  .row:not(.no-gutters).mr-xl-5, .row:not(.no-gutters).mx-xl-5 {
    margin-right: 2.25rem !important; }
  .row:not(.no-gutters).grid.mb-xl-5, .row:not(.no-gutters).grid.my-xl-5 {
    margin-bottom: 2.25rem !important; }
  .row:not(.no-gutters).ml-xl-5, .row:not(.no-gutters).mx-xl-5 {
    margin-left: 2.25rem !important; }
  .row:not(.no-gutters).m-xl-6 {
    margin: 3.375rem 2.8125rem !important; }
  .row:not(.no-gutters).grid.m-xl-6 {
    margin: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mt-xl-6, .row:not(.no-gutters).grid.my-xl-6 {
    margin-top: 2.8125rem !important; }
  .row:not(.no-gutters).mr-xl-6, .row:not(.no-gutters).mx-xl-6 {
    margin-right: 2.8125rem !important; }
  .row:not(.no-gutters).grid.mb-xl-6, .row:not(.no-gutters).grid.my-xl-6 {
    margin-bottom: 2.8125rem !important; }
  .row:not(.no-gutters).ml-xl-6, .row:not(.no-gutters).mx-xl-6 {
    margin-left: 2.8125rem !important; }
  .row:not(.no-gutters).m-xl-7 {
    margin: 3.9375rem 3.375rem !important; }
  .row:not(.no-gutters).grid.m-xl-7 {
    margin: 3.375rem !important; }
  .row:not(.no-gutters).grid.mt-xl-7, .row:not(.no-gutters).grid.my-xl-7 {
    margin-top: 3.375rem !important; }
  .row:not(.no-gutters).mr-xl-7, .row:not(.no-gutters).mx-xl-7 {
    margin-right: 3.375rem !important; }
  .row:not(.no-gutters).grid.mb-xl-7, .row:not(.no-gutters).grid.my-xl-7 {
    margin-bottom: 3.375rem !important; }
  .row:not(.no-gutters).ml-xl-7, .row:not(.no-gutters).mx-xl-7 {
    margin-left: 3.375rem !important; }
  .row:not(.no-gutters).m-xl-8 {
    margin: 4.5rem 3.9375rem !important; }
  .row:not(.no-gutters).grid.m-xl-8 {
    margin: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mt-xl-8, .row:not(.no-gutters).grid.my-xl-8 {
    margin-top: 3.9375rem !important; }
  .row:not(.no-gutters).mr-xl-8, .row:not(.no-gutters).mx-xl-8 {
    margin-right: 3.9375rem !important; }
  .row:not(.no-gutters).grid.mb-xl-8, .row:not(.no-gutters).grid.my-xl-8 {
    margin-bottom: 3.9375rem !important; }
  .row:not(.no-gutters).ml-xl-8, .row:not(.no-gutters).mx-xl-8 {
    margin-left: 3.9375rem !important; }
  .row:not(.no-gutters).m-xl-9 {
    margin: 5.0625rem 4.5rem !important; }
  .row:not(.no-gutters).grid.m-xl-9 {
    margin: 4.5rem !important; }
  .row:not(.no-gutters).grid.mt-xl-9, .row:not(.no-gutters).grid.my-xl-9 {
    margin-top: 4.5rem !important; }
  .row:not(.no-gutters).mr-xl-9, .row:not(.no-gutters).mx-xl-9 {
    margin-right: 4.5rem !important; }
  .row:not(.no-gutters).grid.mb-xl-9, .row:not(.no-gutters).grid.my-xl-9 {
    margin-bottom: 4.5rem !important; }
  .row:not(.no-gutters).ml-xl-9, .row:not(.no-gutters).mx-xl-9 {
    margin-left: 4.5rem !important; }
  .row:not(.no-gutters).m-xl-10 {
    margin: 5.625rem 5.0625rem !important; }
  .row:not(.no-gutters).grid.m-xl-10 {
    margin: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mt-xl-10, .row:not(.no-gutters).grid.my-xl-10 {
    margin-top: 5.0625rem !important; }
  .row:not(.no-gutters).mr-xl-10, .row:not(.no-gutters).mx-xl-10 {
    margin-right: 5.0625rem !important; }
  .row:not(.no-gutters).grid.mb-xl-10, .row:not(.no-gutters).grid.my-xl-10 {
    margin-bottom: 5.0625rem !important; }
  .row:not(.no-gutters).ml-xl-10, .row:not(.no-gutters).mx-xl-10 {
    margin-left: 5.0625rem !important; }
  .row:not(.no-gutters).m-xl-11 {
    margin: 6.1875rem 5.625rem !important; }
  .row:not(.no-gutters).grid.m-xl-11 {
    margin: 5.625rem !important; }
  .row:not(.no-gutters).grid.mt-xl-11, .row:not(.no-gutters).grid.my-xl-11 {
    margin-top: 5.625rem !important; }
  .row:not(.no-gutters).mr-xl-11, .row:not(.no-gutters).mx-xl-11 {
    margin-right: 5.625rem !important; }
  .row:not(.no-gutters).grid.mb-xl-11, .row:not(.no-gutters).grid.my-xl-11 {
    margin-bottom: 5.625rem !important; }
  .row:not(.no-gutters).ml-xl-11, .row:not(.no-gutters).mx-xl-11 {
    margin-left: 5.625rem !important; }
  .row:not(.no-gutters).m-xl-12 {
    margin: 6.75rem 6.1875rem !important; }
  .row:not(.no-gutters).grid.m-xl-12 {
    margin: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mt-xl-12, .row:not(.no-gutters).grid.my-xl-12 {
    margin-top: 6.1875rem !important; }
  .row:not(.no-gutters).mr-xl-12, .row:not(.no-gutters).mx-xl-12 {
    margin-right: 6.1875rem !important; }
  .row:not(.no-gutters).grid.mb-xl-12, .row:not(.no-gutters).grid.my-xl-12 {
    margin-bottom: 6.1875rem !important; }
  .row:not(.no-gutters).ml-xl-12, .row:not(.no-gutters).mx-xl-12 {
    margin-left: 6.1875rem !important; }
  .row:not(.no-gutters).m-xl-13 {
    margin: 7.3125rem 6.75rem !important; }
  .row:not(.no-gutters).grid.m-xl-13 {
    margin: 6.75rem !important; }
  .row:not(.no-gutters).grid.mt-xl-13, .row:not(.no-gutters).grid.my-xl-13 {
    margin-top: 6.75rem !important; }
  .row:not(.no-gutters).mr-xl-13, .row:not(.no-gutters).mx-xl-13 {
    margin-right: 6.75rem !important; }
  .row:not(.no-gutters).grid.mb-xl-13, .row:not(.no-gutters).grid.my-xl-13 {
    margin-bottom: 6.75rem !important; }
  .row:not(.no-gutters).ml-xl-13, .row:not(.no-gutters).mx-xl-13 {
    margin-left: 6.75rem !important; }
  .row:not(.no-gutters).m-xl-14 {
    margin: 7.875rem 7.3125rem !important; }
  .row:not(.no-gutters).grid.m-xl-14 {
    margin: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mt-xl-14, .row:not(.no-gutters).grid.my-xl-14 {
    margin-top: 7.3125rem !important; }
  .row:not(.no-gutters).mr-xl-14, .row:not(.no-gutters).mx-xl-14 {
    margin-right: 7.3125rem !important; }
  .row:not(.no-gutters).grid.mb-xl-14, .row:not(.no-gutters).grid.my-xl-14 {
    margin-bottom: 7.3125rem !important; }
  .row:not(.no-gutters).ml-xl-14, .row:not(.no-gutters).mx-xl-14 {
    margin-left: 7.3125rem !important; }
  .row:not(.no-gutters).m-xl-15 {
    margin: 8.4375rem 7.875rem !important; }
  .row:not(.no-gutters).grid.m-xl-15 {
    margin: 7.875rem !important; }
  .row:not(.no-gutters).grid.mt-xl-15, .row:not(.no-gutters).grid.my-xl-15 {
    margin-top: 7.875rem !important; }
  .row:not(.no-gutters).mr-xl-15, .row:not(.no-gutters).mx-xl-15 {
    margin-right: 7.875rem !important; }
  .row:not(.no-gutters).grid.mb-xl-15, .row:not(.no-gutters).grid.my-xl-15 {
    margin-bottom: 7.875rem !important; }
  .row:not(.no-gutters).ml-xl-15, .row:not(.no-gutters).mx-xl-15 {
    margin-left: 7.875rem !important; }
  .row:not(.no-gutters).m-xl-16 {
    margin: 9rem 8.4375rem !important; }
  .row:not(.no-gutters).grid.m-xl-16 {
    margin: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mt-xl-16, .row:not(.no-gutters).grid.my-xl-16 {
    margin-top: 8.4375rem !important; }
  .row:not(.no-gutters).mr-xl-16, .row:not(.no-gutters).mx-xl-16 {
    margin-right: 8.4375rem !important; }
  .row:not(.no-gutters).grid.mb-xl-16, .row:not(.no-gutters).grid.my-xl-16 {
    margin-bottom: 8.4375rem !important; }
  .row:not(.no-gutters).ml-xl-16, .row:not(.no-gutters).mx-xl-16 {
    margin-left: 8.4375rem !important; }
  .row:not(.no-gutters).m-xl-17 {
    margin: 9.5625rem 9rem !important; }
  .row:not(.no-gutters).grid.m-xl-17 {
    margin: 9rem !important; }
  .row:not(.no-gutters).grid.mt-xl-17, .row:not(.no-gutters).grid.my-xl-17 {
    margin-top: 9rem !important; }
  .row:not(.no-gutters).mr-xl-17, .row:not(.no-gutters).mx-xl-17 {
    margin-right: 9rem !important; }
  .row:not(.no-gutters).grid.mb-xl-17, .row:not(.no-gutters).grid.my-xl-17 {
    margin-bottom: 9rem !important; }
  .row:not(.no-gutters).ml-xl-17, .row:not(.no-gutters).mx-xl-17 {
    margin-left: 9rem !important; }
  .row:not(.no-gutters).m-xl-18 {
    margin: 10.125rem 9.5625rem !important; }
  .row:not(.no-gutters).grid.m-xl-18 {
    margin: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mt-xl-18, .row:not(.no-gutters).grid.my-xl-18 {
    margin-top: 9.5625rem !important; }
  .row:not(.no-gutters).mr-xl-18, .row:not(.no-gutters).mx-xl-18 {
    margin-right: 9.5625rem !important; }
  .row:not(.no-gutters).grid.mb-xl-18, .row:not(.no-gutters).grid.my-xl-18 {
    margin-bottom: 9.5625rem !important; }
  .row:not(.no-gutters).ml-xl-18, .row:not(.no-gutters).mx-xl-18 {
    margin-left: 9.5625rem !important; }
  .row:not(.no-gutters).m-xl-19 {
    margin: 10.6875rem 10.125rem !important; }
  .row:not(.no-gutters).grid.m-xl-19 {
    margin: 10.125rem !important; }
  .row:not(.no-gutters).grid.mt-xl-19, .row:not(.no-gutters).grid.my-xl-19 {
    margin-top: 10.125rem !important; }
  .row:not(.no-gutters).mr-xl-19, .row:not(.no-gutters).mx-xl-19 {
    margin-right: 10.125rem !important; }
  .row:not(.no-gutters).grid.mb-xl-19, .row:not(.no-gutters).grid.my-xl-19 {
    margin-bottom: 10.125rem !important; }
  .row:not(.no-gutters).ml-xl-19, .row:not(.no-gutters).mx-xl-19 {
    margin-left: 10.125rem !important; }
  .row:not(.no-gutters).m-xl-20 {
    margin: 11.25rem 10.6875rem !important; }
  .row:not(.no-gutters).grid.m-xl-20 {
    margin: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mt-xl-20, .row:not(.no-gutters).grid.my-xl-20 {
    margin-top: 10.6875rem !important; }
  .row:not(.no-gutters).mr-xl-20, .row:not(.no-gutters).mx-xl-20 {
    margin-right: 10.6875rem !important; }
  .row:not(.no-gutters).grid.mb-xl-20, .row:not(.no-gutters).grid.my-xl-20 {
    margin-bottom: 10.6875rem !important; }
  .row:not(.no-gutters).ml-xl-20, .row:not(.no-gutters).mx-xl-20 {
    margin-left: 10.6875rem !important; } }

.campusHeader,
.bookingDetail {
  min-height: 250px; }
  .campusHeader .carousel-indicators li,
  .bookingDetail .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    background-color: rgba(52, 55, 5, 0.5); }
    .campusHeader .carousel-indicators li.active,
    .bookingDetail .carousel-indicators li.active {
      background-color: #343741; }
  .campusHeader .carousel-inner,
  .bookingDetail .carousel-inner {
    height: 100%; }
    .campusHeader .carousel-inner .carousel-item,
    .bookingDetail .carousel-inner .carousel-item {
      height: 100%; }
      .campusHeader .carousel-inner .carousel-item img,
      .bookingDetail .carousel-inner .carousel-item img {
        opacity: 0.5;
        height: 100%;
        width: 100%; }

.bookingDetail .carousel-inner .carousel-item img {
  opacity: 1; }

.campusDashboardList.list-group.large .list-group-item.bordered {
  width: 48%;
  min-height: 10.125rem;
  margin: 1% !important; }
  .campusDashboardList.list-group.large .list-group-item.bordered.subscribe .list-group-item-hover {
    background-color: #fff;
    opacity: 0.5; }
    .campusDashboardList.list-group.large .list-group-item.bordered.subscribe .list-group-item-hover:hover {
      opacity: 0.75; }
  .campusDashboardList.list-group.large .list-group-item.bordered.subscribe .list-group-item-text {
    color: #343741; }
    .campusDashboardList.list-group.large .list-group-item.bordered.subscribe .list-group-item-text svg {
      color: #343741 !important; }
  .campusDashboardList.list-group.large .list-group-item.bordered .list-group-item-text {
    min-height: 3.375rem; }
    .campusDashboardList.list-group.large .list-group-item.bordered .list-group-item-text svg {
      margin-top: 0 !important; }
    .campusDashboardList.list-group.large .list-group-item.bordered .list-group-item-text h1 {
      font-size: 1.5em; }

/**********************************************************
*****              Day pass date picker               *****
**********************************************************/
.DayPicker {
  height: 425px;
  background-color: #fff; }

.DayPicker-Day--today {
  color: #152d36; }

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898; }

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff;
  background-color: #244c5a; }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover, .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):focus {
    background-color: #19353f; }

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover, .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):focus {
  background-color: #e1e1e1; }

.DayPicker-NavButton {
  width: 1.75rem;
  height: 1.75rem; }
  @media (min-width: 1200px) {
    .DayPicker-NavButton {
      width: 1.25rem;
      height: 1.25rem; } }

@media (min-width: 992px) {
  .DayPicker-Day {
    padding: 1rem; }
  .DayPicker-Caption {
    padding: 0 1rem; }
  .DayPicker-Weekday {
    padding: 0.5rem 1rem; } }
