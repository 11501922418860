@import '~react-day-picker/lib/style.css';

.DayPicker {
  height: 425px;
  background-color: $white;
}

.DayPicker-Day--today {
  color: darken($primary, 10%);
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: $white;
  background-color: $primary;

  &:hover,
  &:focus {
    background-color: darken($primary, 7.5%);
  }
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
  &:hover,
  &:focus {
    background-color: $gray-300;
  }
}

.DayPicker-NavButton {
  width: 1.75rem;
  height: 1.75rem;
  @include media-breakpoint-up(xl) {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@include media-breakpoint-up(lg) {
  .DayPicker-Day {
    padding: 1rem;
  }
  .DayPicker-Caption {
    padding: 0 1rem;
  }

  .DayPicker-Weekday {
    padding: 0.5rem 1rem;
  }
}
