@import './variables';

/**********************************************************
*****         Bootstrap variables override            *****
**********************************************************/

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$spacer: 0.5625rem;
$body-bg: $gray-200;
$dark: $gray-900;
$warning: $orange;
$body-color: $black;
$link-color: $primary;
$link-hover-color: $primary;

$input-bg: $gray-200;
$input-group-addon-bg: $input-bg;
$input-disabled-bg: $gray-400;
$input-btn-focus-width: 1px;
$input-focus-box-shadow: inset 0 0 0 $input-btn-focus-width rgba($primary, 1);
$input-btn-line-height: 3.5;
$btn-line-height: 3.25;
$btn-line-height-sm: 2.25;
$btn-line-height-lg: 3.5;
$input-border-width: 1px;
$input-border-color: transparent;
$list-group-action-active-bg: lighten($gray-200, 3%);
$h2-font-size: $font-size-base * 2.25;

$custom-select-focus-box-shadow: $input-focus-box-shadow;

$list-group-border-radius: 0;
$list-group-border-width: 0;

$modal-inner-padding: 0;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-md: 800px;
$modal-lg: 1280px;
$modal-backdrop-bg: $gray-900;
$modal-backdrop-opacity: 0.85;
$animation-speed: 0.3s;
$form-group-margin-bottom: ($spacer * 4);
$label-margin-bottom: ($spacer / 2);

$input-btn-padding-x: $spacer * 2;
$input-btn-padding-x-sm: $input-btn-padding-x * (2/3);
$input-btn-padding-x-lg: $input-btn-padding-x * (4/3);

$badge-font-weight: normal;
$badge-padding-x: $spacer;
$badge-border-radius: 0;

$nav-link-padding-x: 1rem;
$nav-link-padding-y: 1rem;

$min-width: 900px;
$min-height: 500px;

// prettier-ignore
$spacers: (
  0: 0,
  1: (1 * $spacer),
  2: (2 * $spacer),
  3: (3 * $spacer),
  4: (4 * $spacer),
  5: (5 * $spacer),
  6: (6 * $spacer),
  7: (7 * $spacer),
  8: (8 * $spacer),
  9: (9 * $spacer),
  10: (10 * $spacer),
  11: (11 * $spacer),
  12: (12 * $spacer),
  13: (13 * $spacer),
  14: (14 * $spacer),
  15: (15 * $spacer),
  16: (16 * $spacer),
  17: (17 * $spacer),
  18: (18 * $spacer),
  19: (19 * $spacer),
  20: (20 * $spacer)
);

$grid-gutter-width: $spacer * 2;

$hr-border-width: 2px;

$theme-colors: (
  'black': $black,
  'light-grey': $gray-200,
  'slate-grey': $slate-grey,
  'slate-blue': $slate-blue,
  'soft-blue': $soft-blue,
  'warm-grey': $warm-grey,
);

@import '../../node_modules/bootstrap/scss/bootstrap';

$font-size-xs: ($font-size-base * 0.75);
$font-size-lg: ($font-size-base * 1.125);
$font-size-xl: ($font-size-base * 1.2);

/**********************************************************
*****                      Mixins                     *****
**********************************************************/

@mixin full-size-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full-size-absolute {
  @include full-size-absolute;
}

@mixin cover-bg {
  background: no-repeat center;
  background-size: cover;
}

@mixin align-content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-centered {
  @include align-content-center;
}

/**********************************************************
*****                  CoreNet                        *****
**********************************************************/
.corenet {
  position: relative;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    background-image: none !important;
  }

  .form-control {
    height: 45px;
    line-height: 2.5;
  }

  .btn.bottom-action {
    line-height: 2.75;
    &.bottom-action-small {
      line-height: 1.75;
    }
  }

  .logoCorenet {
    margin-bottom: $spacer * 5;
    width: 130px;
    text-align: right;

    img {
      width: 130px;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      margin-bottom: 0;
      right: $spacer * 3;
      bottom: $spacer * 4;
    }

    @include media-breakpoint-up(xl) {
      width: 220px;

      img {
        width: 220px;
      }
    }
  }
}

/**********************************************************
*****                  Utility classes                *****
**********************************************************/
.mh-0 {
  min-height: 0 !important;
}

.pb-05 {
  padding-bottom: $spacer * 0.5;
}

.pt-05 {
  padding-top: $spacer * 0.5;
}

.pl-05 {
  padding-left: $spacer * 0.5;
}

.pr-05 {
  padding-right: $spacer * 0.5;
}

.flex-1 {
  flex: 1;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0;
  flex-grow: 1;
}

.collapseTitle {
  .CollapseArrow {
    width: 24px;
    height: 24px;
    opacity: 1 !important;

    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .open {
    transform: rotate(90deg);
    transition: transform 200ms ease-in-out 0s;

    &.CollapseArrow {
      transform: rotate(0deg);
    }
  }

  .close {
    transform: rotate(-90deg);
    transition: transform 200ms ease-in-out 0s;

    &.CollapseArrow {
      transform: rotate(-180deg);
    }
  }

  .small b {
    font-weight: 500;
  }
}

.modal-dialog-centered {
  min-height: 0;
  max-height: calc(100% - (1.75rem * 2));
}

.bg-primary-opacity-6 {
  background-color: rgba($primary, 0.6);
}

.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.text-none {
  text-transform: none !important;
}

.text-prewrap {
  white-space: pre-wrap;
}

.font-weight-light {
  font-weight: 200 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.btn-primary {
  color: $white;
}

.overflow-auto {
  overflow: auto !important;
}

.align-item-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hover-no-decoration:hover {
  text-decoration: none;
}

@each $size, $length in $spacers {
  .wg-#{$size} {
    width: $length;
  }

  .hg-#{$size} {
    height: $length;
  }
}

.bg-body {
  background-color: $body-bg;
}

.minh-50 {
  min-height: 50% !important;
}

.minh-100 {
  min-height: 100% !important;
}

.c-pointer {
  cursor: pointer;
}

.divider-v {
  border-left: 1px solid $warm-grey;
  height: $spacer * 2;
}

.border-4 {
  border-width: $spacer / 2 !important;
}

.nav-tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: none;

  .nav-item .nav-link {
    border: none;
    background-color: transparent;
    border-bottom: 3px solid transparent;

    &:hover,
    &:focus,
    &.active {
      border-color: $primary;
    }

    &:invalid {
      border-color: $slate-grey;
    }
  }
}

.MuiSwitch-root {
  margin: 12px -12px 0 0;
}

.h-separator {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  span {
    position: relative;
    display: inline-block;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: $warm-grey;
    }

    &::before {
      right: 100%;
      margin-right: 15px;
    }

    &::after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.payment-method {
  height: 364px;
  max-height: 364px;
}

.corenetBanner {
  a {
    display: inline-block;
    margin: 0 $spacer;

    img {
      height: $spacer * 7;
    }
  }
}

/**********************************************************
*****                Styling overrides                *****
**********************************************************/
.tox-statusbar__branding {
  display: none;
}

dt {
  font-weight: 600;
}

%close {
  position: absolute;

  &:not(.modal-flow) {
    top: map-get($spacers, 2);
  }

  left: map-get($spacers, 2);
  padding: 0;
  margin: 0;
  letter-spacing: 0.0925em;
  font-weight: 500;

  &.right {
    left: initial;
    right: map-get($spacers, 2);
  }

  @include media-breakpoint-up(md) {
    left: map-get($spacers, 4);

    &:not(.modal-flow) {
      top: map-get($spacers, 4);
    }

    &.right {
      left: initial;
      right: map-get($spacers, 4);
    }
  }
}

%close-dark {
  color: $white;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
}

body {
  font-family: 'Alleyn', sans-serif;
  letter-spacing: 0.0375em;
  line-height: 1.36;

  &.modal-open {
    #base {
      filter: blur(0.25rem);
      transition: filter 0.3s ease-out;
    }
  }
}

html,
body,
#root {
  height: 100%;
  padding: 0 !important;
}

#root.fullscreen-view-open {
  overflow: hidden;
}

img {
  object-fit: cover;
}

ul.spaced {
  li + li {
    margin-top: $spacer;
  }
}

.btn {
  font-size: $font-size-sm;
  font-weight: normal;
  letter-spacing: 0.0925em;

  &.rounded-circle {
    width: 3em;
    text-align: center;
    padding: 0;
  }

  span,
  svg {
    vertical-align: middle;
  }
}

.btn.bottom-action {
  line-height: 4.75;
  border-radius: 0;
  &.bottom-action-small {
    line-height: 3.75;
  }
}

.btn-light {
  color: $primary;
  border: none;
  background-color: $white;
  font-weight: 500;

  &:hover {
    color: $primary;
  }
}

.btn.modal-header-action {
    border-radius: 0;
    font-weight: 600;
    min-width: 320px;
    min-height: 64px;
}

.badge {
  line-height: 1.2;
  letter-spacing: 0.5px;
}

.form-control,
.form-control-like {
  line-height: $input-line-height;
  font-size: 0.875rem;
  border-radius: 0;
  height: calc(3.3em + 0.75rem + 2px);
  caret-color: $secondary;

  &.focus {
    box-shadow: $input-focus-box-shadow;
  }
}

textarea.form-control {
  line-height: 1.5em;
  min-height: $spacer * 10;
}

label,
input::placeholder,
.form-control::placeholder {
  font-size: 0.8125rem;
}

input::placeholder,
.form-control::placeholder {
  color: $warmer-grey;
}

label {
  width: 100%;
  position: relative;
  letter-spacing: normal;
  color: $slate-grey;
  font-weight: 500;

  &.required::after {
    content: '*';
    color: $secondary;
    position: absolute;
    right: 0;
  }
}

.consentChecked {
  height: 50px;
}

h1,
h2,
h3 {
  letter-spacing: 0.1em;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 100px $gray-200 inset !important;
  background-color: transparent;
  -webkit-text-fill-color: $slate-grey;
  transition: none;
}

input:invalid {
  -webkit-box-shadow: 0 0 0px 100px $gray-200 inset !important;
  background-color: transparent;
  -webkit-text-fill-color: $slate-grey;
  transition: none;
}

.form-control,
.form-control-like {
  &:focus,
  &.focus {
    border-color: $secondary;
    -webkit-box-shadow: none !important;
    box-shadow: none;
  }

  &:invalid {
    border-color: $gray-200;
    -webkit-box-shadow: none !important;
    box-shadow: none;
  }
}

.bg-black,
.bg-dark,
.card-inverse,
.modal-dark {
  color: $white;

  &.bg-black,
  &.bg-dark,
  &.card-inverse,
  .card-header,
  .card-body {
    background-color: $black;
    border-color: $black;
  }

  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 100px $slate-grey inset !important;
    background-color: transparent;
    -webkit-text-fill-color: $gray-200;
    transition: none;
  }

  input:invalid,
  textarea:invalid {
    -webkit-box-shadow: 0 0 0px 100px $slate-grey inset !important;
    background-color: transparent;
    -webkit-text-fill-color: $gray-200;
    transition: none;
  }

  .form-control,
  .form-control-like {
    &:focus,
    &.focus {
      border-color: $secondary;
    }

    &:invalid {
      border-color: $slate-grey;
    }
  }

  .close {
    @extend %close-dark;

    &.disabled {
      opacity: 0.5;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  > .card-body,
  > .card-header {
    & + .card-body,
    & + .card-header {
      padding-top: $card-spacer-y * 1.25;
    }
  }

  .consent {
    a {
      color: white;
      font-weight: bold;
    }

    &.checked .consent-check {
      border-color: $primary;
    }
  }

  .list-group-item .list-group-item-text .title {
    color: $white;
  }

  .input-group,
  .form-control,
  .consent {
    background-color: $slate-grey;
    color: $gray-200;
    border: 1px solid $slate-grey;
    transition: none;
  }

  .invitationEmail {
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    .form-control {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0px 100px $black inset !important;
      -webkit-text-fill-color: $gray-200;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .text-danger {
    color: lighten($red, 15%) !important;
  }

  label {
    color: $gray-200;
  }
}

.modal-header .close,
.step-header .close,
.header .close {
  @extend %close;
  z-index: 5;
}

.modal-dark .close,
.close.modal-dark {
  @extend %close-dark;
}

.modal {
  .modal-dark {
    .modal-header,
    .modal-body {
      background-color: $dark;
      color: $white;
    }
  }

  .modal-header {
    display: block;

    .img.rounded-circle {
      width: 100%;
      height: 100%;
    }

    .modal-title {
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
      letter-spacing: 6px;
      margin-top: 3rem - $modal-header-padding;
      margin-bottom: 2.5rem - $modal-header-padding;

      @include media-breakpoint-up(md) {
        font-size: 2.4375rem;
      }
    }

    .modal-title-small {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.6px;
    }

    &.bg-black,
    &.bg-dark {
      color: $white;
    }
  }

  .modal-body {
    min-height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.two-panes {
      flex-direction: row;
    }
  }

  .modal-content {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  }

  .modal-dialog.bg-transparent {
    color: $white;

    .modal-content {
      background-color: transparent !important;
      box-shadow: none;
    }
  }

  .modal-profile {
    .modal-body {
      padding:30px;
      .header {
        .img.rounded-circle {
          width:100%;
          height:100%;
        }
      }
    }

    .close {
      position: absolute;
      top:16px;
      right:17px;
    }
  }
}

.modal-xl {
  max-width: 95%;
}

.modal-full-screen {
  height: calc(100% - (1.75rem * 2));

  .modal-content {
    height: 100%;
    max-height: 100%;
  }

  &.adaptive {
    .modal-content {
      height: auto;
    }
  }
}

.nav-link {
  min-width: $spacer * 20;
  text-align: center;
  color: $black;
  border: none;
  background: none;
  cursor: pointer;

  * {
    vertical-align: middle;
  }

  &:hover {
    color: $primary;
  }

  &:focus {
    outline: none;
  }
}

.nav-link.active {
  border-bottom: solid 2px $primary;
}

.dropdown-toggle::after {
  margin-left: $spacer * 2;
  border-top-color: $primary;
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height-inner;
  }
}

/**********************************************************
*****              Media form control                 *****
**********************************************************/

.media-form-control {
  width: 8.125rem;
  height: 8.125rem;
  margin: auto;
  position: relative;

  input[type='file'] {
    width: 0;
    height: 0;
    padding: 0 !important;
    opacity: 0;
    position: absolute;
  }

  .bg,
  img {
    @include full-size-absolute;

    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  .bg {
    padding: 1px;
  }

  label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 50%;
    top: 50%;
    width: 46%;
    height: 46%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
}

/**********************************************************
*****                    Loading                      *****
**********************************************************/

.loading-container {
  @include full-size-absolute;

  display: none;
  background-color: rgba($black, 0.5);
  align-items: center;
  justify-content: center;

  &.global {
    z-index: 10000001;
    position: fixed;
  }

  &.loading {
    display: flex;
    animation: 1s fade-in;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**********************************************************
*****                     Toast                       *****
**********************************************************/

$toast-width: 800px;

.toast-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}

.cg-toast {
  animation: #{$toast-animation-duration}ms slide-in;
  background: rgba($primary, 0.9);

  &.closing {
    animation: #{$toast-animation-duration}ms slide-out;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

/**********************************************************
*****                 Select lists                    *****
**********************************************************/

.img {
  background-color: $light;
  color: $white;
  @extend .align-item-center;
}

.list-group-header {
  padding: 2 * $spacer;
  padding-bottom: $spacer;
  background-color: $white;

  .super-title {
    font-size: $font-size-xs;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  .title {
    text-transform: uppercase;
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 2.2px;
    padding-bottom: $spacer;
    border-bottom: 1px solid $secondary;
  }

  + .list-group-item {
    border-top: none;
  }
}

.roomList {
  .list-group .list-group-item-image {
    align-self: flex-start;
  }

  .timeSlot {
    font-size: 1.6rem;
    border-bottom: 2px solid $primary;
  }
}

.isPrivateIcon {
  right: 0;
}

.bookingDetail {
  .bookingTitle {
    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.71));
    }

    div {
      z-index: 2;
    }

    .action-btn {
      z-index: 10;
    }

    .roomPicture {
      object-fit: cover;
      object-position: center;
      z-index: 0;
    }
  }

  .recurringBookingInfo {
    min-height: auto;
    border-bottom: 0;
  }
}

.title-list-expertises {
  font-weight: 500;
  color: $slate-grey;
}

.expanded-list {
  max-height: 100vh;
  overflow: auto;
}

.list-group-item {
  border-top: ($spacer / 4) solid transparent;
  border-bottom: ($spacer / 4) solid transparent;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  position: relative;
  padding: $spacer ($spacer * 2);
  min-height: $spacer * 10;

  * {
    /* Useful to support hovers */
    z-index: 0;
  }

  &:not(.list-settings):not(.list-more):first-child {
    border-top: 0;
  }

  &:not(.list-item-subscription):not(.list-settings):not(.list-more):last-child {
    border-bottom: 0;
  }

  &.list-group-item-spaced {
    border-top-width: ($spacer / 2);
    border-bottom-width: ($spacer / 2);
  }

  &:not(.list-group-item-action) {
    &:disabled,
    &.disabled {
      opacity: 0.7;

      .list-group-item-status {
        display: none;
      }

      &.time-slot-item {
        opacity: 1;
        background-color: rgba($warm-grey, 0.2);

        .super-title {
          opacity: 0.5;
        }
      }
    }
  }

  &.list-group-item-action {
    width: auto;
  }

  .list-group-item-image {
    img,
    .img {
      border-radius: 50%;

      &:not([width]) {
        width: 3.375rem;
      }

      &:not([height]) {
        height: 3.375rem;
      }
    }

    .img {
      background-color: $light;
      color: $white;
      @extend .align-item-center;
    }

    img {
      object-fit: cover;
    }

    svg {
      display: block;

      &.pendingMemberIcon {
        width: 54px;
      }
    }
  }

  &.list-settings {
    &.list-group-item-action .list-group-item-image {
      img,
      .img {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  &.list-expertises {
    &.list-group-item-action .list-group-item-image {
      img,
      .img {
        filter: invert(67%) sepia(2%) saturate(34%) hue-rotate(353deg) brightness(98%)
          contrast(87%);
      }
    }

    &.granted {
      .list-group-item-image {
        img,
        .img {
          filter: invert(27%) sepia(8%) saturate(2609%) hue-rotate(150deg) brightness(92%)
            contrast(96%);
        }
      }
    }
  }

  &.conflict-list-item {
    flex-wrap: wrap;
    cursor: auto !important;

    .list-group-item-text {
      flex: 1 1 80%;
    }

    .action {
      flex: 1 1 20%;
    }

    .conflict-info {
      display: flex;
      flex: 1 1 100%;
      margin-top: 2px;

      .amenity-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .img {
        width: 2rem;
        height: 2rem;
      }

      .conflict-reason {
        flex-grow: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.625rem;
      }
    }
  }

  &.recurring_personal {
    > .list-group-item-status {
      align-self: flex-start;
      margin-top: $spacer * 2;
    }

    > .list-group-item-text {
      overflow: visible;

      &.activeItem {
        margin-top: $spacer * 2;

        .dropdownOccurrence {
          display: block;
        }
      }

      .dropdownOccurrence {
        display: none;
        width: $spacer * 20;

        select {
          display: block;
          height: auto;
          line-height: 2.5rem;
        }

        button {
          background-color: $gray-200;
          color: $warm-grey;
          box-shadow: none;
          padding-top: 0;
          padding-bottom: 0;
          border: 0;

          &:focus {
            border: 0;
            box-shadow: none;
          }
        }
      }
    }
  }

  .list-group-item-text {
    width: 0;
    flex-grow: 1;

    @extend .text-truncate;
    position: relative;
    z-index: 1;

    .title,
    .super-title,
    .sub-title {
      display: block;
    }

    .title {
      font-weight: bold;
      color: $gray-900;
      max-width: 100%;
      @extend .text-truncate;
    }

    .sub-title {
      font-weight: 500;
      font-size: 0.8125rem;
      letter-spacing: 0.4px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 2px;

      .sub-title-email {
        padding-top: $spacer/2;
      }
    }
  }

  .list-group-item-status {
    margin-left: auto;

    svg {
      vertical-align: middle;
    }
  }

  .list-group-item-link {
    min-height: 90px;
    min-width: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }
}

.profile-list-item {
  .list-scrollable {
    max-height: $spacer * 33.2;
    overflow: auto;
  }

  .list-group-item,
  .list-group-item-link {
    min-height: 50px;
    min-width: 50px;
    border-top: 0;
  }

  .linkProfile {
    .list-group-item {
      border-left: 2px solid $gray-200;
      border-bottom: 0;

      &:first-child {
        border-left: none;
      }
    }
  }
}

.list-group-item-hover {
  @include full-size-absolute;

  background-color: $black;
  opacity: 0.36;
  transition: opacity $animation-speed cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0;
}

.list-group-item-action {
  &.shaded {
    .list-group-item-hover {
      opacity: 0.73;
    }
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover .list-group-item-hover {
      opacity: 0.85;
    }
  }

  &.inactive {
    opacity: 0.7;

    &:hover {
      opacity: 0.9;
    }
  }

  &.disabled .list-group-item-hover {
    opacity: 0.9;
  }
}

.image-list-group-item {
  flex-direction: column;
  background-position: center;
  background-size: cover;
  align-items: flex-start;
  min-height: $spacer * 18;

  .list-group-item-text {
    flex-grow: 0;
    width: auto;
  }

  &:focus {
    color: darken($white, 10%);
  }

  &,
  &:hover {
    color: $white;
  }

  &:disabled,
  &.disabled {
    color: $warm-grey;
  }
}

.list-group.large {
  .list-group-item {
    min-height: $spacer * 29;

    &.bordered {
      min-height: $spacer * (29 + 2/3);
      border-bottom: solid $spacer * (2/3) $secondary;
    }
  }
}

.list-group.list-group-horizontal {
  flex-direction: row;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .list-group-item:not(.image-list-group-item) {
    min-height: initial;
  }

  .list-group-item {
    min-width: $spacer * 44;
    padding: $spacer * 2;
    margin: 0 $spacer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .list-group-item-image {
    img {
      width: 2em;
      height: 2em;
    }
  }
}

.dropdown-list {
  .multi-select-dropdown-items{
    width: inherit;
  }
}

.dropdown-grey-section{
  .list-group-item .list-group-item-text .title {
    color: $black;
  }
}

.multi-select-dropdown {
  &.campus {
    position: absolute;
    right: 0;
    top: $spacer;

    .multi-select-dropdown-items {
      width: 100%;
      left: 13%;
      transform: translateX(-13%);
    }
  }

  .form-control-like {
    line-height: 1.7em;
    padding: 0px 3px 3px 10px;
    height: 35px;
    max-width: 200px;
    border-radius: 35px;
  }

  .list-group-item.default {
    padding: ($spacer * 2);
    margin-bottom: ($spacer / 2);
  }
}

.list-group-compact {
  .list-group-item,
  .list-group-item.default {
    padding: $spacer;
    min-height: $spacer * 8;
  }

  &.email-invitation {
    overflow: auto;
    max-height: $spacer * 16;

    .list-group-item,
    .list-group-item.default {
      padding-left: $spacer * 2;
      padding-right: $spacer * 2;
      min-height: $spacer * 6;
    }
  }
}

.list-group-very-compact {
  .form-control {
    line-height: 1.8rem;
    height: calc(1.8em + 0.75rem + 2px);
  }

  .list-group-item,
  .list-group-item.default {
    padding: ($spacer / 2) $spacer;
    min-height: $spacer * 7.5;
  }

  .btn-birthday {
    padding-top: 0;
  }
}

.multi-select-dropdown-items {
  position: absolute;

  z-index: 3;
  background-color: $body-bg;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  min-width: $spacer * 29;
  max-height: 0;
  overflow: hidden;
  transition: max-height $animation-speed cubic-bezier(0.215, 0.61, 0.355, 1);

  &.open {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 30vh;
    animation: open $animation-speed cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

@keyframes open {
  0% {
    overflow: hidden;
  }

  50% {
    overflow: hidden;
  }

  100% {
    overflow: auto;
  }
}

.multi-select-dropdown-icon {
  &.open {
    transform: scaleY(-1);
  }
}

.dropdown-indicator {
  transform: rotate(0deg);
  transition: transform 200ms ease-in-out 0s;

  &.open {
    transform: rotate(180deg);
  }
}

/**********************************************************
*****              Wide modal with panes              *****
**********************************************************/

.two-panes {
  position: relative;
}

.action-pane {
  width: 100%;
}

.info-pane,
.info-pane + .action-pane {
  width: 50%;
}

.action-pane {
  background-color: $body-bg;
  display: flex;
  flex-direction: column;
  z-index: 2;

  &.bg-dark {
    color: $white;
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }

  .btn.bottom-action {
    flex-shrink: 0;
  }
}

.info-pane {
  @include cover-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $white;
  font-weight: 600;

  &::after {
    @include full-size-absolute;
    content: ' ';
    background: rgba($black, 0.73);
    z-index: 1;
  }

  .contents {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-weight: 600;
    letter-spacing: 15.3px;
    text-align: center;
  }

  .sub-title {
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1rem;

    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
  }

  .title {
    &.confirmation {
      font-size: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3.5rem;
    }
  }
}

.steps {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  a,
  span {
    color: $warm-grey;
    font-size: 0.5625rem;
    text-align: center;
    letter-spacing: 0.4px;

    &.completed {
      color: $white;
    }

    &.active {
      color: white;

      svg {
        color: $secondary;
      }
    }
  }
}

.step {
  display: inline-block;
  margin-left: 2.5 * $spacer;
  margin-right: 2.5 * $spacer;
}

.dot-steps {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    color: $warm-grey;

    &.completed {
      color: $soft-blue;
    }

    &.active {
      color: $white;
    }
  }

  .step-separator {
    width: 3 * $spacer;
    display: inline;
    border-top: 1px solid $warm-grey;

    &.completed {
      border-top-color: $soft-blue;
    }
  }

  span,
  .step-separator {
    margin-left: 0.5 * $spacer;
    margin-right: 0.5 * $spacer;
  }
}

/**********************************************************
*****             Rounded picture list              *****
**********************************************************/

.rounded-picture-list {
  list-style-type: none;
  padding-left: 0;

  .rounded-picture-list-item {
    display: inline-block;
    margin-left: ($spacer / 2);
    margin-right: ($spacer / 2);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .isPrivateIcon {
      right: -5px;
      top: -4px;
    }

    img,
    .img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }
  }

  .picture-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: $font-size-sm;
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(#000, 0.7);
    text-align: center;
    color: $white;
  }
}

/**********************************************************
*****               Notification badge                *****
**********************************************************/

.notification-badge {
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  z-index: 2;

  &.top-left {
    left: 0;
    top: 0;
  }

  &.top-right {
    right: 0;
    top: 0;
  }

  &.bottom-right {
    right: 0;
    bottom: 0;
  }

  &.bottom-left {
    left: 0;
    bottom: 0;
  }

  &.navigation-badge {
    left: -4px;
    top: -3px;
  }
}

.notification-badge-wrapper {
  position: relative;
  display: inline-block;
  line-height: 1;
}

/**********************************************************
*****               Action result page                *****
**********************************************************/

.action-result-page {
  position: relative;
  width: 100%;
  min-height: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-top: $spacer * 4;
  padding-bottom: $spacer * 4;
  display: flex;
  justify-content: center;
  align-items: center;

  .grey-section {
    background-color: $gray-900;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 32%;
    z-index: 1;
  }

  .blue-section {
    background-color: $primary;
    width: 100%;
    position: absolute;
    top: 68%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .content {
    width: 95%;
    max-width: 790px;
    position: relative;
    z-index: 2;

    &.component {
      max-width: 95vw;
    }
  }

  .result {
    background-color: $white;
    border-radius: 2px;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.25rem;
    }
  }
}

/**********************************************************
*****                 View specific                   *****
**********************************************************/
#payment-request-button {
  background-color: $dark;
}

#base {
  min-height: 100%;
  overflow: auto;

  @media (max-width: $min-width), (max-height: $min-height) {
    display: none;
  }

  &.anonymous {
    background-color: $dark;
    color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

#login {
  p {
    font-weight: 500;
  }

  .forgot-password {
    font-size: $font-size-xs;
  }
}

.member-invitation {
  background-color: $body-bg;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;

  .form-control:not(.btn) {
    background-color: $white;
  }

  .input-group-text {
    background-color: $white;
    border-radius: 0;
  }

  .input-group-prepend .input-group-text {
    border-right: none;
  }

  .input-group-prepend ~ .form-control {
    border: 1px solid white;
    border-left: none;
  }

  .member-list {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    height: 0;
  }

  .addEmail {
    span {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    input {
      border-top-right-radius: 25px;
      padding-left: 0;
      border-bottom-right-radius: 25px;
      line-height: 1.5;
      flex: none;
      max-width: 90%;
    }
  }
}

.invitation-container {
  @include full-size-absolute;

  display: flex;
  flex-direction: column;

  .member-invitation {
    height: 0;
    flex-grow: 1;
  }
}

.explanations-step {
  p {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.6px;
  }
}

.consent {
  display: flex;
  padding: $spacer $btn-padding-x;
  cursor: pointer;
  font-size: 0.8125rem;
  align-items: center;

  .consent-check {
    flex-shrink: 0;
    border-radius: 50%;
    color: $warm-grey;
    border: 2px solid $warm-grey;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
  }

  &.checked.checked .consent-check {
    background-color: $primary;
    color: $white;
    border-color: $primary;
    opacity: 1;
  }

  .consent-text {
    padding-left: $btn-padding-x;
  }
}

.modal-flow-title {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.btn-link {
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: darken($link-hover-color, 2.5%);
  }
}

.step-header {
  display: flex;
  justify-content: center;
  position: relative;

  .close {
    left: ($spacer * 3);

    &:not(.modal-flow) {
      top: ($spacer * 3);
    }
  }

  p {
    flex-grow: 1;
  }
}

.subscription-statuses {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: $spacer * 2;
}

.simple-modal-icon {
  img {
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 50%;
  }
}

.list-item-spacer {
  margin: 0;
  border-top: 1px $body-bg solid;
}

.no-match {
  @include full-size-absolute;
  z-index: 10000002;

  h1 {
    font-size: 2rem;
  }

  ._404 {
    font-size: 10.5vh;
    font-weight: 600;
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 2.5rem;
    }

    ._404 {
      font-size: 10.5vw;
    }
  }
}

.mobile-blocker {
  display: none !important;
  @include full-size-absolute;
  z-index: 10000003;

  .intro-message {
    line-height: 2;
  }
}

@media (max-width: $min-width), (max-height: $min-height) {
  .mobile-blocker {
    display: flex !important;
  }
}

#message-list {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#infinite-list {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.message-body {
  min-height: 220px;
  font-size: 0.8125rem;
  font-weight: 500;

  h5,
  h6 {
    font-weight: bold;
  }
}

.message-actions {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.list-group-item-action {
  &:hover,
  &:focus,
  &.active-item {
    background-color: rgba($primary, 0.15);
    box-shadow: inset 0 0 5px -5px rgba(#000, 0.75);
  }
}

.subscription-router {
  height: 600px;
  position: relative;
}

.settings-menu {
  border-right: 1px solid $warm-grey;
}

.profile-link {
  text-decoration: none;
  color: $white;

  &:hover {
    text-decoration: none;
  }

  .btn {
    padding: 0;
  }

  .dropdown-menu.show {
    margin-top: $spacer;
    margin-left: $spacer;
    width: $spacer * 29;

    .dropdown-item {
      margin: $spacer 0;
      min-height: 48px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: $dark;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    color: $white;
    width: $spacer * 31;

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .dropdown-toggle::after {
    vertical-align: middle;
    margin-right: $spacer * 2;
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-left: 0.5em solid transparent;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }
}

.header-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.segment-header {
  min-height: 175px;
  @include align-content-center;
}

.segment-body {
  min-height: 296px;
}

.segment-top-action {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.break-words {
  hyphens: auto;
  word-wrap: break-word;
}

.time-slot-info {
  background-color: $white;
  box-shadow: 0 2px 10px 0 rgba(68, 68, 68, 0.6);
  position: relative;
  z-index: 2;

  hr {
    margin-top: ($spacer / 2);
    margin-bottom: 0;
  }
}

.time-slot-date-selection {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.time-slot-date {
  min-height: 100px;
  min-width: 60px;
  cursor: pointer;
  color: $warm-grey;
  background-color: $dark;
  padding: 0;
  border: none;
  @include align-content-center;
  text-transform: uppercase;

  .week-day,
  .month {
    font-size: $font-size-xs;
    font-weight: 500;
  }

  .day {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &:hover,
  &:focus {
    background-color: lighten($dark, 10%);
    color: $white;
  }

  &.selected {
    background-color: $primary;
    color: $white;
  }
}

.filter-button {
  min-width: 90px;
  color: $white;
  font-weight: normal;
  border-radius: 50px;
  line-height: 1.55;
  margin-right: $spacer;

  &:last-child {
    margin-right: 0;
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;

  .v-separator {
    border-right: 2px solid $gray-300;
    height: 35px;
  }
}

.time-slot-filters {
  box-shadow: 0 -2px 10px 0 rgba(68, 68, 68, 0.6);
  position: relative;
  z-index: 2;

  .title {
    font-size: 0.8125rem;
  }
}

.time-slots-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .back {
    position: absolute;
    padding-left: ($spacer / 2);
    padding-top: 3 * $spacer;
    top: 0;
    left: 0;

    @include media-breakpoint-up(xl) {
      padding-left: 3 * $spacer;
    }
  }
}

.time-slots {
  flex-grow: 1;
  overflow: auto;
  padding-top: ($spacer / 2);
  padding-bottom: ($spacer / 2);
}

.modal-create-booking,
.modal-edit-booking {
  position: relative;

  > .close {
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-flow-title {
    position: absolute;
    left: 92px !important;
    margin-bottom: 0 !important;
  }

  & + .modal-body {
    .content:not(.booking-confirmation-summary) {
      margin: 20px !important;
    }
  }
}

.modal-company-floor-plan {
  .modal-header {
    .close {
      left: inherit;
      right: 2.25rem;
      top: 2rem;
    }

    .back {
      left: 2.25rem;
      right:inherit;
    }
  }

  .location-info {
    span {
      font-size:12px;
      line-height: 19px;
      display: block;
    }
    h6 {
      text-transform: uppercase;
      line-height: 20px;
    }
  }

  .modal-body {
    padding:30px;
  }

  .modal-footer {
    .btn {
      border-radius: 0;
      font-weight: 600;
      width:100%;
      min-height: 64px;
    }
  }
}

.modal-membership-members {
  max-width: 1340px;
  .modal-header {
    .close {
      left: inherit;
      right: 2.25rem;
      top: 2rem;
    }

    .back {
      left: 2.25rem;
      right:inherit;
    }
  }
  .modal-body {
    padding:30px;
    .list-group-item {
      min-height: 60px;
      .list-group-item-link {
        min-height: 60px;
      }
    }
  }
}

.FlexibleBooking {
  position: relative;
  height: calc(100% - 48px);

  &__Calendar {
    display: flex;
    align-items: flex-start;
    border: 1px solid rgba(#101820, 0.05);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        position: inherit;
      }
    }
  }

  .left-col {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: $white;
    width: 120px;
  }

  .zoom {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    height: 72px;
    padding: 8px;
    background-color: $white;
    width: 120px;

    button {
      border: 1px solid transparent;
    }

    button:not(:disabled) {
      border-color: #ededed;
      box-shadow: 0px 0px 6px rgba(#000, 0.05);
      background-color: $white;
    }

    button:disabled {
      background-color: #ededed;
    }
  }

  .zoom button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    margin: 0 0.3rem;

    &:disabled {
      cursor: auto;

      path {
        fill: #a7b7bd;
      }
    }
  }

  .time-cell {
    display: flex;
    align-items: center;
    height: 40px;

    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 100%;
    }
  }

  .time-cell:nth-child(odd) {
    background-color: $white;
  }

  .time-cell:nth-child(even) {
    background-color: $gray-200;
  }

  &__Resources {
    display: flex;
    flex-direction: column;
    width: 100%;

    .resources-header-row,
    .resources-content-row {
      display: flex;

      > div {
        min-width: 175px;
        width: 100%;
      }
    }
  }

  .resources-header-row {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .resources-arrows-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    z-index: 3;
  }

  .resources-arrows {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1px;
    width: 16px;
    height: 72px;
    background-color: rgba($primary, 0.8);
  }

  .arrow-left {
    left: 122px;
  }

  .arrow-right {
    right: 1px;
  }

  .resource-head {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 8px;
    box-sizing: border-box;
    background-color: $white;
    overflow: hidden;
    z-index: 1;
  }

  .resource-head-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    margin-right: 1rem;
    position: relative;

    > .rounded-circle,
    > .FlexibleBooking__Amenity_Image {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .resource-head-seats {
    text-align: center;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    z-index: 1;
    text-shadow: 0px 2px 4px rgba(#000000, 0.7);

    .people {
      font-size: 12px;
    }
  }

  .resource-private {
    display: inherit;
    position: absolute;
    top: -4px;
    right: 0;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 50%;
  }

  .resource-title {
    font-size: 13px;
  }

  .Cutoff,
  .OutsideOpeningHours,
  .MinBookingDuration,
  .MaxContiguousPeriodReached,
  .MaxPeriodPerDayReached {
    .period-background {
      background-color: rgba($gray-900, 0.4);
    }
  }

  .period-background {
    pointer-events: none;
  }

  .Booked:not(.CurrentBookingPeriod) {
    background-color: $primary;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 100%;
      background-color: $white;
      z-index: 1;
    }
  }

  .resource-content {
    border-left: 1px solid $gray-300;

    .CurrentBooking-details {
      transition: all 0.2s ease-in-out;
    }

    .CurrentBooking {
      background-color: rgba($secondary, 0.6);

      &__Error {
        font-size: 11px;
        line-height: 13px;
      }

      &.minimumDuration,
      &.maximumDuration,
      &.BookingConflict {
        background-color: rgba($red, 0.6);
        border: 1px solid $red;
        color: $white;

        .react-resizable-handle-s,
        .resize-handle {
          border-color: rgba($red, 0.6);
        }
      }

      .react-resizable {
        width: 100%;
        height: 100%;
      }

      .react-resizable-handle {
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        margin-left: 0;
        border-radius: 50%;
        background: $white;
        border: 1px solid $secondary;
      }

      .react-resizable-handle-n {
        top: -3px;
      }

      .react-resizable-handle-s {
        bottom: -3px;
      }
    }

    .BookingConflict {
      font-size: 0.6875rem;
      color: $white;
      overflow: hidden;
    }
  }

  .period-thumbnail {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Available,
  .CurrentBookingPeriod,
  .Cutoff,
  .OutsideOpeningHours,
  .MinBookingDuration,
  .MaxContiguousPeriodReached,
  .MaxPeriodPerDayReached {
    .quarterCells {
      &.odd {
        background-color: $white;
      }

      &.even {
        background-color: $gray-200;
      }
    }
  }

  .CurrentBookingPeriod {
    .booking-information {
      display: none !important;
    }
  }

  .CurrentBooking__Information {
    font-size: 11px;
    line-height: 13px;
  }

  .zoom-0 .quarterCells {
    height: 10px;
  }

  .zoom-1 .quarterCells {
    height: 20px;
  }

  .zoom-2 .quarterCells {
    height: 40px;
  }

  .booking-information {
    padding: 4px;

    > div {
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }

    > p {
      font-size: 10px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.FlexibleBooking__Amenity_Image {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.FlexibleBooking__Amenity_Information_Cost {
  padding-left: 0.4em;
  padding-right: 0.4em;
}

.FlexibleBooking__actions {
  .room_filter_icon_filter_count {
    width: 12px;
    height: 12px;
    background-color: $secondary;
    border-radius: 50%;
    font-size: 9px;
    bottom: 4px;
    right: -1px;
    padding-left: 2px;
  }

  .FlexibleBooking__ActionsBar_DateControls {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  .FlexibleBooking__ActionsBar_DateControls_DatePicker {
    min-width: 300px;
    margin: 0 0.6rem;
    text-align: center;
  }

  .FlexibleBooking__ActionsBar_DateControls_CurrentDate {
    cursor: pointer;
  }

  .btn-change-date {
    line-height: normal;
    padding: 0 0.2rem;
    cursor: pointer;
  }
}

.FlexibleBooking__actions_subtitle {
  font-weight: 500;
}

.MultiCheckbox_checkbox {
  &:hover {
    background-color: inherit !important;
  }
}

.time-slot-item {
  font-weight: 500;
  min-height: 69px;

  &.small {
    min-height: 30px;

    .list-group-item-text {
      text-overflow: unset;
      white-space: normal;
    }
  }

  &.selected {
    background-color: $primary;

    .rounded-picture-list-item {
      color: $white;
    }
  }

  .list-group-item-status {
    font-size: 0.8125rem;
  }

  .sub-title {
    margin-top: ($spacer / 4);
  }
}

.flexible-details {
  .FlexibleBooking__Calendar {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        position: absolute !important;
      }
    }
  }
}

.floorplan-container {
  .no-results {
    min-height: calc(100vh - 200px);
    border: 1px solid grey;
    background-color: rgba(grey, 0.1);
  }

  &.floorplan-modal {
    .floorplan-svg-container {
      width:100%;
      > div {
        min-height: calc(100vh - 400px);
        border:none;
      }
    }
  }

  .floorplan-svg-container {
    width: calc(100% - 305px);

    > div {
      min-height: calc(100vh - 200px);
      cursor: grab;
      border: 1px solid grey;
      background-color: rgba(grey, 0.1);
    }

    .floorplan-unit {
      overflow: visible;
      transition: fill .2s ease-in-out;

      &.selected {
        stroke-width: 2px;
      }

      text,
      .icon {
        font-size: 10.5px;
        fill: rgb(0, 0, 0) !important;
        dominant-baseline: middle;
        text-anchor: middle;
        stroke-width: 0px;
      }

      &.CommunalArea {
        fill: rgba($secondary, 0.8);

        &.selected {
          fill: #c6d4e7;
          stroke: $secondary;
        }

        &.unselected {
          fill: rgba($secondary, 0.4);
        }
      }

      &.Enterprise {
        fill: rgba($primary, 0.8);

        &.selected {
          fill: rgba($primary, 0.9);
          stroke: $primary;
        }

        &.unselected {
          fill: #a7b7bd;
        }
      }
    }
  }
}

.tooltip-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 0px 12px;

  p {
    font-size: 16px;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 125px;
    overflow: hidden;
  }
}

.floorplan-dropdowns {
  width: 305px;
  margin: -4px -10px;

  .floorplan-dropdown {
    margin: 4px 10px;

    .floorplan-dropdown-button {
      min-width: 100px;
      height: 44px;
      border-radius: 100px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon-container {
        width: 24px;
        height: 24px;
        background-color: rgba($white, 0.3);
        margin-left: 10px;
      }

      .icon {
        display: inline-block;
        vertical-align: middle;
        border-top: 0.5em solid;
        border-right: 0.5em solid transparent;
        border-left: 0.5em solid transparent;
        border-bottom: 0;
        transition: transform 0.2s ease-in-out;
      }

      &.toggled {
        .icon {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.floorplan-search {

  input.form-control {
    border: none;
  }

  .multi-select-dropdown-items.open {
    max-height: 35vh;
  }

  .floorplan-search-dropdown {
    .multi-select-dropdown-items {
      min-width: 17.813rem;
      .floorplan-dropdown-button {
        height: 32px;
        background-color: $warm-grey;
      }

      .list-group-item {
        padding: 9px;
        min-height: 4.5rem;
      }
    }
  }
}

.floorplan-searchexpertise {
  .list-expertises {
    min-height: inherit;
    margin-bottom: 10px;

    .list-group-item-image {
      margin-left: 10px;
    }
  }

  .btn.bottom-action {
    width: 500px;
    line-height: 3.6;
  }
}
.CommunalAreaModal {
  .modal-header {
    height: 96px;

    h3 {
      font-size: 39px;
    }

    .close {
      left: inherit;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.creditsInfo > div:first-child {
  border-right: 1px solid $warm-grey;
}

.booking-confirmation-summary {
  .privacy-section {
    .list-group-item {
      min-height: 62px;
    }
  }

  .summary-section {
    letter-spacing: normal;

    .time-info {
      font-size: 0.9375rem;
    }
  }
}

.animated-fill {
  g,
  path {
    //Note: the easing function was taken from material ui's theme.transitions.easing.sharp and the duration from theme.transitions.duration.shortest
    transition: fill 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
}

.time-zone {
  font-size: 0.625rem;
  color: $warm-grey;
  font-weight: 500;
}

.recurring-booking-duration-list {
  .sub-title {
    white-space: pre-wrap;
  }
}

.eventList {
  margin-bottom: 4px;

  .eventDay {
    width: 62px;
  }

  .eventType {
    font-size: 0.6rem;
    padding: 3px;
  }

  .badge {
    margin-top: 3px;
  }
}

#list-page {
  min-height: 950px;

  #infinite-list {
    height: 100%;
    width: calc(100% - 18px);
    overflow-y: auto;
  }
}

/**********************************************************
*****                  Tour                   *****
**********************************************************/
#tourDetail {
  .whereTour {
    min-height: $spacer * (29 + 2/3);
    background-size: cover;
    background-position: center;

    * {
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      background: rgba($black, 0.65);
      z-index: 1;
      border-radius: 0;
    }
  }
}

/**********************************************************
*****                  New Concepts                   *****
**********************************************************/

.btn-link {
  @each $color, $value in $theme-colors {
    &.btn-link-#{$color} {
      color: $value;

      @include hover {
        color: darken($value, 7.5%);
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active {
        color: darken($value, 10%);
      }
    }
  }
}

.row.grid:not(.no-gutters) {
  margin-top: ($grid-gutter-width / -2);
  margin-bottom: ($grid-gutter-width / -2);

  & > [class*='col-'],
  & > .col {
    padding-top: ($grid-gutter-width / 2);
    padding-bottom: ($grid-gutter-width / 2);
  }

  &.padded {
    margin: $grid-gutter-width * -1;

    [class*='col-'] {
      padding: $grid-gutter-width;
    }
  }
}

.row:not(.no-gutters) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $size, $length in $spacers {
        &.m#{$infix}-#{$size} {
          margin: $length ($length - $grid-gutter-width / 2) !important;
        }

        &.grid.m#{$infix}-#{$size} {
          margin: ($length - $grid-gutter-width / 2) !important;
        }

        &.grid.mt#{$infix}-#{$size},
        &.grid.my#{$infix}-#{$size} {
          margin-top: ($length - $grid-gutter-width / 2) !important;
        }

        &.mr#{$infix}-#{$size},
        &.mx#{$infix}-#{$size} {
          margin-right: ($length - $grid-gutter-width / 2) !important;
        }

        &.grid.mb#{$infix}-#{$size},
        &.grid.my#{$infix}-#{$size} {
          margin-bottom: ($length - $grid-gutter-width / 2) !important;
        }

        &.ml#{$infix}-#{$size},
        &.mx#{$infix}-#{$size} {
          margin-left: ($length - $grid-gutter-width / 2) !important;
        }
      }
    }
  }
}

.campusHeader,
.bookingDetail {
  min-height: 250px;

  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    background-color: rgba(52, 55, 5, 0.5);

    &.active {
      background-color: $black;
    }
  }

  .carousel-inner {
    height: 100%;

    .carousel-item {
      height: 100%;

      img {
        opacity: 0.5;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.bookingDetail {
  .carousel-inner .carousel-item img {
    opacity: 1;
  }
}

.campusDashboardList {
  &.list-group.large {
    .list-group-item.bordered {
      width: 48%;
      min-height: $spacer * 18;
      margin: 1% !important;

      &.subscribe {
        .list-group-item-hover {
          background-color: $white;
          opacity: 0.5;

          &:hover {
            opacity: 0.75;
          }
        }

        .list-group-item-text {
          color: $black;

          svg {
            color: $black !important;
          }
        }
      }

      .list-group-item-text {
        min-height: $spacer * 6;

        svg {
          margin-top: 0 !important;
        }

        h1 {
          font-size: 1.5em;
        }
      }
    }
  }
}

/**********************************************************
*****              Day pass date picker               *****
**********************************************************/

@import 'InlineDatePicker';
