$primary: #244c5a;
$secondary: #b8c9e1;
$black: #343741;
$slate-grey: #616167;
$gray-200: #ededed;
$gray-300: #e1e1e1;
$gray-400: #ced4da;
$gray-600: $slate-grey;
$gray-900: #43434c;
$warm-grey: #a2a2a2;
$warmer-grey: #969696;
$orange: #ff6900;
$green: #55c443;
$red: #d60808;
$white: #fff;
$light: $gray-300;
$slate-blue: #244c5a;
$soft-blue: #b8c9e1;
$toast-animation-duration: 350;

// Export variables so that they can be used in JavaScript
:export {
  primary: $primary;
  secondary: $secondary;
  black: $black;
  light: $light;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray900: $gray-900;
  warmGrey: $warm-grey;
  orange: $orange;
  green: $green;
  red: $red;
  white: $white;
  slateGrey: $slate-grey;
  slateBlue: $slate-blue;
  softBlue: $soft-blue;
  toastAnimationDuration: $toast-animation-duration;
}
:root {
  --primary: #244c5a;
  --secondary: #b8c9e1;
  --black: #343741;
  --slate-grey: #616167;
  --gray-200: #ededed;
  --gray-300: #e1e1e1;
  --gray-400: #ced4da;
  --gray-600: #616167;
  --gray-900: #43434c;
  --warm-grey: #a2a2a2;
  --warmer-grey: #969696;
  --orange: #ff6900;
  --green: #55c443;
  --red: #d60808;
  --white: #fff;
  --light: #e1e1e1;
  --slate-blue: #244c5a;
  --soft-blue: #b8c9e1;
}
